import React, { Fragment, useEffect, useState, useRef } from "react";
import cx from "classnames";

import { connect, useSelector } from "react-redux";

import ReactCSSTransitionGroup from "react-addons-css-transition-group";

import HeaderLogo from "../AppLogo";
import UserBox from "./Components/UserBox";
import { Roles } from "../../utils/Roles";
// import HeaderRightDrawer from "./Components/HeaderRightDrawer";
import { useHistory, useLocation } from "react-router";
import GetStudentService from "../../services/SchoolIT/GetStudentService";

import HeaderDots from "./Components/HeaderDots";
import CustomLoader from "../../components/customLoader/CustomLoader";;

const Header = () => {
  let {headerBackgroundColor, enableMobileMenuSmall, enableHeaderShadow, closedSmallerSidebar} = useSelector(state => state.ThemeOptions);
  const [user, setUser] = useState(useSelector(state => state.UserReducer.userObj));
  let location = useLocation();
  let history = useHistory();
  const [openSchoolOption, setOpenSchoolOption] = useState(false);
  const [schoolName, setSchoolName] = useState(JSON.parse(localStorage.getItem("user"))?.college_name);
  const [selectedSchool, setSelectedSchool] = useState();
  const [userOnRoute, setUserOnRoute] = useState(location?.pathname.split('/').pop())



  const [modifyData, setModifyData] = useState({});
  const [schoolId, setSchoolId] = useState();
  const [schoolName1, setSchoolName1] = useState(JSON.parse(localStorage.getItem("user"))?.college_name);
  // const [schoolName, setSchoolName] = useState();
  const [showSchoolNameContainer, setShowSchoolNameContainer] = useState(false);
  const [schoolNameList, setSchoolNameList] = useState();
  const schoolNameRef = useRef();
  const timeoutRef = useRef();
  const [schoolNameLoading, setSchoolNameLoading] = useState(false);
  const [openUploadSchool, setOpenUploadSchool] = useState(false);
  const [openNewSchool, setOpenNewSchool] = useState(false);
  const [openModifySchool, setOpenModifySchool] = useState(false);
  const [enableState, setEnableState] = useState(false);
  const [selectedValue, setSelectedValue] = useState('');

  const openSchoolOptionHandler = () => {
    setOpenSchoolOption(!openSchoolOption);
  };

  const selectSchoolOptionHandler = (value) => {
    //console.log(value);
    setSchoolName1(value.name);
    setSelectedSchool(value);
    setOpenSchoolOption(false);
  };

  
  useEffect(() => {
    // if(user.role === "counsellor")
    if (selectedSchool) {
      //console.log("selected College", selectedSchool);
      let obj = {
        ...user,
        college_id: selectedSchool?.id,
        college_name: selectedSchool?.name,
      };
      localStorage.setItem("user", JSON.stringify(obj));
      if (
        user?.role === "counselor" &&
        user?.is_super_counselor === true && userOnRoute !== "all-students"
      ) {
        //console.log("1");
        history.push("/super_admin/all-students");
      } else if (
        user?.role === "counselor"
      ) {
        //console.log("2");
        history.push("/counselor/all-students");
      }else if(userOnRoute === "all-students" && user?.role === "counselor" && user?.is_super_counselor === true){
        //console.log("3");
        window.location.reload();
      }else if(userOnRoute === "all-students" && user?.role === "counselor"){
        //console.log("4");
         window.location.reload();
      }
    }
  }, [selectedSchool]);


  const getSchoolsList = (value) => {
    setSchoolNameLoading(true);
    let obj = {
       q : value
    }
    GetStudentService.getSchoolsList(obj)
      .then((data) => {
        //console.log("data from the service", data);
        setSchoolNameList(data?.data?.school);

        setSchoolNameLoading(false);
        setShowSchoolNameContainer(true);
      })
      .catch((error) => {
        //console.log("error from the service", error);
        setSchoolNameLoading(false);
      });
  };

  const handleSchoolName = () => {
    clearTimeout(timeoutRef.current);
    setSchoolName1(schoolNameRef.current.value);
    if (!schoolNameRef.current.value) {
      setShowSchoolNameContainer(false);
      setSchoolNameList([]);
    } else {
      timeoutRef.current = setTimeout(() => {
        getSchoolsList(schoolNameRef.current.value);
      }, 1000);
    }
  };

  // useEffect(() => {
  //   if (selectedSchool) {
  //     //console.log("selected School", selectedSchool);
  //     let obj = {
  //       ...user,
  //       school_id: selectedSchool?.id,
  //       school_name: selectedSchool?.name,
  //     };
  //     localStorage.setItem("user", JSON.stringify(obj));
  //   window.location.reload();
  // }}, [selectedSchool]);


  const handleSelectedSchoolName = (item) => {
    setSchoolNameList([item]);
    setShowSchoolNameContainer(false);
    setSchoolName1(item?.name);
    // setOpenModifySchool(true);
    setEnableState(true);
    setModifyData(item);
    setSchoolId(item?.id);
    // setSchoolName('');
    // else if (selectedSchool && user.role === "school_it"){
      let obj = {
        ...user,
        college_id: item?.id,
        college_name: item?.name,
        district:item?.id,
        district_name: item?.name,
        counselor_id: item?.counselor_id,
      };
      localStorage.setItem("user", JSON.stringify(obj));
      window.location.reload();
  };

  return (
    <>
        <ReactCSSTransitionGroup
          component="div"
          // className={cx("app-header", headerBackgroundColor, {
          //   "header-shadow": enableHeaderShadow,
          // })}
          className={cx("app-header", headerBackgroundColor)}
          transitionName="HeaderAnimation"
          transitionAppear={true}
          transitionAppearTimeout={1500}
          transitionEnter={false}
          transitionLeave={false}
        >
          <HeaderLogo />

          <div
            className={cx("app-header__content", {
              "header-mobile-open": enableMobileMenuSmall,
            })}
          >
            <div className="app-header-right">
              <div></div>
              {user.role == Roles.STUDENT_NAME ? <HeaderDots /> : "" }
              {user.role === "counselor" && (
              <div
                style={{
                  //marginRight:"30%",
                  justifyContent:"center",
                  position: "relative",
                  marginTop: "10px",
                }}
              >
                <p
                  onClick={openSchoolOptionHandler}
                  className="no-margin-bottom cursor"
                  style={{ textDecoration: "underline", fontSize: "22px" }}
                >
                  {schoolName}
                </p>
                {openSchoolOption && (
                  <div
                    style={{
                      position: "absolute",
                      background: "white",
                      padding: "15px",
                      zIndex: "10",
                    }}
                  >
                    {user?.colleges_name?.map((item, index) => {
                      return (
                        <div
                          key={index}
                          onClick={() => selectSchoolOptionHandler(item)}
                          className="school-option"
                        >
                          <p className="no-margin-bottom">{item.name}</p>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            )}

{user.role === "school_it" && user.school_student_progress_enable === true && (
              <div
                style={{
                  //marginRight:"30%",
                  justifyContent:"center",
                  position: "relative",
                  marginTop: "10px",
                }}
              >
                <div>
                <input
                  ref={schoolNameRef}
                  className="search-student-input2"
                  type="text"
                  style={{
                    width: "350px",
                    height: "35px",
                    padding: "5px 10px",
                    marginBottom: "10px",
                  }}
                  placeholder="School Name"
                  value={schoolName1}
                  onChange={handleSchoolName}
                  autoComplete="off"
                />
                {showSchoolNameContainer && (
                  <div style={{position:"absolute"}}>
                    <ul
                      className="maintain-group-student-dropdown-menu"
                      style={{
                        width: "550px",
                        maxHeight: "600px",
                        overflowY: "scroll",
                        borderRadius: "5px",
                        padding: "10px",
                      }}
                    >
                      {schoolNameLoading && <CustomLoader />}
                      {schoolNameList?.length > 0 &&
                        schoolNameList.map((item, index) => (
                          <li
                            onClick={() => handleSelectedSchoolName(item)}
                            key={index}
                            className="flex cursor"
                            style={{padding:"5px 0px"}}
                          >
                            <span>{item.name}</span>
                          </li>
                        ))}
                    </ul>
                  </div>
                )}
              </div>


              </div>
                )}
              
              <UserBox />
            </div>
          </div>
        </ReactCSSTransitionGroup>
      </>
  )
}

export default Header;
