import React, { useState, useEffect, Fragment } from "react";
import { useHistory } from "react-router";
import QueryService from "../../services/QueryService";
import DeleteModal from "../deleteModal/DeleteModal";
import Pagination from "@mui/material/Pagination";
import CustomLoader from "../customLoader/CustomLoader";
const IMG_URL = process.env.REACT_APP_IMAGE_ASSET_URL;

const SavedQuery = () => {
  let authuser = localStorage.getItem("user");
  let parsedUser = JSON.parse(authuser);
  let history = useHistory();
  const [savedQueryData, setSavedQueryData] = useState();
  const [page, setPage] = useState(1);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteValue, setDeleteValue] = useState();
  const [duplicateQueryData, setDuplicateQueryData] = useState();
  const [paginationCount, setPaginationCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(parsedUser);
  const [resultDisplayData, setResultDisplayData] = useState();
  const navigateToQuery = () => {
    history.push("/recruiter/search-query");
  };

  // useEffect(()=>{
  //     history.push('/recruiter/query-result', {
  //             state: resultDisplayData,
  //             fromSavedQueriedResult : true,
  //         });
  //         return ()=>{
  //             setResultDisplayData()
  //         }
  // },[resultDisplayData])
  const getSavedQueryPage = () => {
    setLoading(true);
    let obj = {
      page: page,
      recruiter_id: user && user.id,
      role: user && user.role.title,
    };
    QueryService.getSavedQueryResultPage(obj)
      .then((data) => {
        setLoading(false);
        //console.log("data from the service", data);
        setSavedQueryData(data.data.result);
        setPaginationCount(data.data.max);
      })
      .catch((error) => {
        setLoading(false);
        //console.error("error from the service", error);
      });
  };

  const getResultDisplayForDuplicate = (value, query = "") => {
    //console.log("value from the result display", value);
    setLoading(true);
    let obj = {
      header: value.header,
      recruiter_id: user && user.id,
      students: value.students,
    };
    QueryService.getResultDisplay(obj)
      .then((data) => {
        setLoading(false);
        //console.log("data from the result display", data.data);
        history.push({
          pathname: "/recruiter/query-result",
          state: data.data,
          openSaveModal: true,
          fromSavedQueriedResult: true,
          duplicateQueryData: query,
        });
      })
      .catch((error) => {
        setLoading(false);
        //console.error("error from the service", error);
      });
  };

  const getResultDisplayForView = (value, query = "") => {
    //console.log("value from the result display", value);
    setLoading(true);
    let obj = {
      header: value.header,
      recruiter_id: user && user.id,
      students: value.students,
    };
    QueryService.getResultDisplay(obj)
      .then((data) => {
        setLoading(false);
        //console.log("data from the result display", data.data.export);
        setResultDisplayData(data.data);
        history.push({ 
          pathname: "/recruiter/query-result",
          state: data.data,
          fromSavedQueriedResult: true,
        });
      })
      .catch((error) => {
        setLoading(false);
        //console.error("error from the service", error);
      });
  };

  useEffect(() => {
    getSavedQueryPage();
  }, [page]);

  const selectTab = (value) => {
    if (value === "customQuery") {
      localStorage.removeItem("query");
      history.push("/recruiter/search-query");
    } else if (value === "schoolQuery") {
      localStorage.removeItem("query");
      history.push("/recruiter/school-query");
    } else if (value === "savedQuery") {
      localStorage.removeItem("query");
      history.push("/recruiter/saved-query");
    }
  };

  const deleteQuery = async (item) => {
    //console.log("delete clicked", item);
    await setDeleteValue(item);
    setOpenDeleteModal(true);
  };

  const duplicateQuery = async (item) => {
    let obj = {
      college: user && user.college_id,
      creator: user && user.id,
      result_id: item.id,
    };
    QueryService.duplicateQueryForSaved(obj)
      .then(async (data) => {
        //console.log("data from the service", data);
        await setDuplicateQueryData(data.data.new_id);
        getResultDisplayForDuplicate(item, data.data.new_id);
      })
      .catch((error) => {
        //console.error("error from the service", error);
      });
  };

  return (
    <Fragment>
      <div
        style={{
          padding: "20px",
          background: "var(--background-color)",
          height: "100%",
          overflowY: "scroll",
        }}
      >
        <div className="name_message_header">
          <p>Hello {user ? `${user?.first_name} ${user?.last_name}!!` : ""}</p>
        </div>
        <div style={{ marginTop: "20px" }} className="tab-grid">
          <div
            onClick={() => selectTab("customQuery")}
            style={{ width: "100%" }}
            className="tab-button inactive-tab cursor"
          >
            <p className="no-margin-bottom">Create Custom Query</p>
          </div>
          <div
            onClick={() => selectTab("schoolQuery")}
            style={{ width: "100%" }}
            className="tab-button inactive-tab cursor"
          >
            <p className="no-margin-bottom">Saved Queries</p>
          </div>
          {/* <div onClick={()=>selectTab('savedQuery')} style={{ width: '100%' }} className="tab-button active-tab cursor"><p className="no-margin-bottom">Saved Queried Results</p></div>
                    <div onClick={()=>{}} style={{ width: '100%' }} className="tab-button inactive-tab cursor"><p className="no-margin-bottom">Query Columns</p></div> */}
        </div>
        {loading ? (
          <CustomLoader />
        ) : (
          <div className="school-queries-container">
            <div
              style={{
                padding: "20px",
                justifyContent: "space-between",
                borderBottom: "2px solid #333333",
              }}
              className="flex"
            >
              <div className="flex">
                <img
                  style={{ width: "40px", height: "60px" }}
                  src={IMG_URL+"SchoolQuery.svg"}
                  alt="School Query"
                />
                <div style={{ padding: "0px 15px" }}>
                  <p
                    style={{ fontSize: "24px" }}
                    className="no-margin-bottom color"
                  >
                    {paginationCount && savedQueryData
                      ? paginationCount * savedQueryData.length
                      : 0}
                  </p>
                  <p
                    style={{ fontSize: "14px", fontWeight: "bold" }}
                    className="no-margin-bottom color"
                  >
                    School Queries
                  </p>
                </div>
              </div>
              <div className="cursor" onClick={() => navigateToQuery()}>
                <p
                  className="font color"
                  style={{ textDecoration: "underline" }}
                >
                  Create Custom Query
                </p>
              </div>
            </div>
            <div style={{ margin: "30px 0px" }}>
              <p className="bold color">ALL SCHOOL QUERIES</p>
            </div>
            <div>
              {savedQueryData &&
                savedQueryData.map((item, index) => (
                  <div
                    key={index}
                    style={{
                      padding: "25px",
                      borderLeft: "1px solid #ece",
                      borderRight: "1px solid #ece",
                      borderTop: "1px solid #ece",
                      borderBottom:
                        "3px solid var(--selected-primary-button-color)",
                      margin: "10px 0px",
                    }}
                  >
                    <div className="col-md-12 row">
                      <div className="col-md-4">
                        <p
                          style={{
                            fontSize: "18px",
                            color: "#351724",
                            fontWeight: "bold",
                          }}
                        >
                          {item.table_name}
                        </p>
                      </div>
                      <div className="col-md-8 row">
                        <div className="col-md-4">
                          <button
                            onClick={() => getResultDisplayForView(item)}
                            className="query-button-active"
                          >
                            VIEW
                          </button>
                        </div>
                        <div className="col-md-4">
                          <button
                            onClick={() => duplicateQuery(item)}
                            className="query-button-inactive"
                          >
                            DUPLICATE
                          </button>
                        </div>
                        <div className="col-md-4">
                          <button
                            onClick={() => deleteQuery(item)}
                            className="query-button-inactive"
                          >
                            DELETE
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
            <Pagination
              onChange={(event, value) => setPage(value)}
              count={paginationCount}
              color="primary"
            />
          </div>
        )}
      </div>
      {openDeleteModal && (
        <DeleteModal
          openDeleteModal={openDeleteModal}
          setOpenDeleteModal={setOpenDeleteModal}
          deleteValue={deleteValue}
          setDeleteValue={setDeleteValue}
          getSavedQueryPage={getSavedQueryPage}
          from="saved-query"
        />
      )}
    </Fragment>
  );
};
export default SavedQuery;
