import {
  faGlobeAmericas,
  faPlus,
  faSave,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect, useRef, Fragment } from "react";
import { useHistory } from "react-router";
import GetStudentService from "../../../../services/SchoolIT/GetStudentService";
import AppHeader from "../../../../Layout/AppHeader";
import AppSidebar from "../../../../Layout/AppSidebar";
import CustomLoader from "../../../customLoader/CustomLoader";

const MapGoal = () => {
  const [user, setUser] = useState(
    localStorage.getItem("user") && JSON.parse(localStorage.getItem("user"))
  );
  const [deletedGoal, setDeletedGoal] = useState([]);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [criteria, setCriteria] = useState([]);
  const [goalFieldTypes, setGoalFieldTypes] = useState([]);
  const [dataSourceType, setDataSourceType] = useState([]);
  const [dataSourceColumnOption1, setDataSourceColumnOption1] = useState([]);
  const [dataSourceColumnOption2, setDataSourceColumnOption2] = useState([]);
  const [goalFieldName, setGoalFieldName] = useState([]);
  const [fieldTypes, setFieldTypes] = useState([]);
  const [arithemeticOperator, setArithemeticOperator] = useState([]);
  const [disableGoalElement, setDisableGoalElement] = useState(1);
  const goalNameRef = useRef();
  // const literalGoalFieldRef = useRef();
  const dataSourceRef = useRef();
  const arithemeticOperatorRef = useRef();

  const getGoalFieldTypes = () => {
    setLoading(true);
    GetStudentService.getGoalFieldTypes()
      .then((data) => {
        //console.log("setGoalFieldTypes", data?.data);
        setLoading(false);
        setGoalFieldTypes(data.data.objects);
      })
      .catch((error) => {
        //console.log("error from the service", error);
        setLoading(false);
      });
  };

  const getArithemeticOperators = () => {
    setLoading(true);
    GetStudentService.getArithemeticOperator()
      .then((data) => {
        //console.log("setArithemeticOperator", data?.data);
        setLoading(false);
        setArithemeticOperator(data?.data?.objects);
      })
      .catch((error) => {
        //console.log("error from the service", error);
        setLoading(false);
      });
  };

  const getDataSource = () => {
    setLoading(true);
    GetStudentService.getDataSrc(user?.school_id)
      .then((data) => {
        //console.log("setDataSourceType", data?.data);
        setLoading(false);
        let arr = data?.data?.objects;
        arr.map((item) => getDataSourceCol(item?.id));
        setDataSourceType(data?.data?.objects);
      })
      .catch((error) => {
        //console.log("error from the seervice", error);
        setLoading(false);
      });
  };

  const getDataSourceCol = (id) => {
    setLoading(true);
    GetStudentService.getDataSrcCol(id)
      .then((data) => {
        //console.log("setDataSourceColumnOption", data?.data);
        setLoading(false);
        if (id === 18) setDataSourceColumnOption1(data?.data?.objects);
        if (id === 19) setDataSourceColumnOption2(data?.data?.objects);
        // setDataSourceColumnOption(data?.data?.objects);
      })
      .catch((error) => {
        //console.log("error fom the service", error);
        setLoading(false);
      });
  };

  const getFieldTypes = () => {
    setLoading(true);
    GetStudentService.getFieldTypes()
      .then((data) => {
        //console.log("setFieldTypes", data?.data);
        setFieldTypes(data?.data?.objects);
        setLoading(false);
      })
      .catch((error) => {
        //console.log("error from the service", error);
        setLoading(false);
      });
  };

  const getGoalFieldNames = () => {
    setLoading(true);
    GetStudentService.goalFieldForCreateGoal(user?.school_id)
      .then((data) => {
        //console.log("setGoalFieldName", data?.data);
        setGoalFieldName(data?.data?.objects);
        setLoading(false);
      })
      .catch((error) => {
        //console.log(error);
        setLoading(false);
      });
  };

  const getMapList = () => {
    setLoading(true);
    GetStudentService.mapList({ school: user?.school_id })
      .then((data) => {
        //console.log("data from the mapList service", data?.data);
        let array = [];
        let dataObject = data?.data;
        if (Object.keys(dataObject).length > 0) {
          for (let key in dataObject) {
            let obj = {
              data: dataObject[key]?.goalfieldmap?.field_data?.goal_value,
              datasource: dataObject[key]?.goalfieldmap?.datasource_id,
              datacolumn: dataObject[key]?.goalfieldmap?.mapping_column_id,
              goal_type: dataObject[key]?.goalfieldmap?.goalfield_type_id,
              goal1: dataObject[key]?.goalfieldmap?.field_data?.goal_data1_id,
              goal2: dataObject[key]?.goalfieldmap?.field_data?.goal_data2_id,
              operator: dataObject[key]?.goalfieldmap?.field_data?.operator_id,
              goalname: dataObject[key].field_name,
              goalid: dataObject[key].id,
              is_global: dataObject[key]?.is_global,
              datatype: dataObject[key]?.goalfieldmap?.datatype_id,
              mapid: dataObject[key]?.goalfieldmap.id,
            };
            if (dataObject[key]?.goalfieldmap?.field_data) {
              obj.datatype =
                dataObject[key]?.goalfieldmap?.field_data?.goal_datatype_id;
            }
            array.push(obj);
          }
        }
        //console.log(array);
        setDisableGoalElement(array.length);
        setCriteria(array);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        //console.log("error from the service", error);
      });
  };

  useEffect(() => {
    getMapList();
    getGoalFieldTypes();
    getGoalFieldNames();
    if (user?.school_id) {
      getDataSource();
    }
    getArithemeticOperators();
    getFieldTypes();
  }, []);

  const saveGoalMap = () => {
    setLoading(true);
    let arr = criteria;
    if (deletedGoal.length > 0) {
      arr = [...arr, ...deletedGoal];
    }
    let obj = {
      map_values: [null, ...arr],
      school_id: user?.school_id,
    };
    //console.log(obj);
    GetStudentService.saveGoalMap(obj)
      .then((data) => {
        //console.log("data from the service", data);
        setLoading(false);
        history.push("/school_it/ccplus");
      })
      .catch((error) => {
        //console.error("error vrom the service", error);
        setLoading(false);
      });
  };

  const removeCriteria = (index, item) => {
    if (index < disableGoalElement) {
      let obj = {
        map_values: item.goalid,
        school_id: user?.school_id,
      };
      setLoading(true);
      GetStudentService.removeGoalMap(obj)
        .then((data) => {
          //console.log(data);
          setDisableGoalElement(disableGoalElement - 1);
          criteria.splice(index, 1);
          setCriteria([...criteria]);
          item.goalid = `${obj.map_values}-D`;
          setDeletedGoal([...deletedGoal, item]);
          setLoading(false);
        })
        .catch((error) => {
          //console.error("error vrom the service", error);
          setLoading(false);
        });
    } else {
      criteria.splice(index, 1);
      setCriteria([...criteria]);
    }
  };

  const AddNewCriteria = () => {
    let array = [];
    // let itemNo = criteria.length + 1;
    let data = {
      datasource: "",
      datacolumn: "",
      goal_type: "",
      goal1: "",
      goal2: "",
      operator: "",
      data: "",
      goalname: "",
      goalid: "",
      datatype: "",
      mapid: "",
    };
    array.push(data);
    setCriteria([...criteria, ...array]);
  };

  const handleGoalNameChange = (index) => {
    //console.log("handleGoalNameChange");
    criteria[index].goalname = goalNameRef.current.value;
    //console.log("handle Goal Change from hthe onchange", criteria);
    setCriteria([...criteria]);
  };

  const handleGoalFieldRef = (e, index) => {
    //console.log("handleGoalFieldRef");
    criteria[index].goal_type = parseInt(e.target.value);
    setCriteria([...criteria]);
  };

  const handleDataSourceChange = (index) => {
    //console.log("handleDataSourceChange");
    criteria[index].datasource = dataSourceRef.current.value;
    criteria[index].datacolumn = "";
    setCriteria([...criteria]);
    // getDataSourceCol(dataSourceRef.current.value);
  };

  const handleDataColumn = (e, index) => {
    criteria[index].datacolumn = e.target.value;
    setCriteria([...criteria]);
  };

  const handleGoal1Change = (e, index) => {
    //console.log("handleGoal1Change");
    criteria[index].goal1 = e.targer.value;
    setCriteria([...criteria]);
  };

  const handleGoal2Change = (e, index) => {
    //console.log("handleGoal2Change");
    criteria[index].goal2 = e.target.value;
    setCriteria([...criteria]);
  };

  const handleLiteralGoalFieldDataChange = (e, index) => {
    // //console.log(index, literalGoalFieldRef.current);
    criteria[index].data = e.target.value;
    setCriteria([...criteria]);
  };

  const handleArithemeticOperatorChange = (index) => {
    criteria[index].operator = arithemeticOperatorRef.current.value;
    setCriteria([...criteria]);
  };

  const handleFieldTypeChange = (e, index) => {
    criteria[index].datatype = e.target.value;
    setCriteria([...criteria]);
  };

  return (
    <Fragment>
      <AppHeader />
      <div className="app-main">
        <AppSidebar />
        <div className="app-main__outer">
          {loading ? (
            <CustomLoader />
          ) : (
            <div style={{ padding: "15px" }}>
              <div className="flex" style={{ justifyContent: "space-between" }}>
                <p className="no-margin-bottom" style={{ fontSize: "24px" }}>
                  Map School Goal Data
                </p>
                <button
                  className="create-maintain-group-button"
                  onClick={() => history.push("/school_it/ccplus")}
                  style={{ padding: "5px 15px" }}
                >
                  Back
                </button>
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "130px",
                  fontSize: "20px",
                  margin: "20px 0 0 100px",
                }}
              >
                <p style={{ margin: 0 }}>Goal Elements</p>
                <p style={{ margin: 0 }}>Type</p>
              </div>
              {criteria &&
                criteria.length > 0 &&
                criteria.map((item, index) => (
                  <div
                    key={index}
                    className="flex"
                    style={{
                      margin: "10px 0 30px  0",
                      justifyContent: "flex-start",
                    }}
                  >
                    {item?.is_global ? (
                      <div style={{ margin: "0 15px" }}>
                        <FontAwesomeIcon
                          icon={faGlobeAmericas}
                          size="2x"
                          color="#057ebb"
                        />
                      </div>
                    ) : (
                      <button
                        onClick={() => removeCriteria(index, item)}
                        style={{
                          margin: "0 15px",
                          background: "#d9534f",
                          color: "white",
                          padding: "5px 12px",
                          borderRadius: "5px",
                          border: "none",
                          outline: "none",
                        }}
                      >
                        <FontAwesomeIcon icon={faTimes} />
                      </button>
                    )}

                    <input
                      style={{ width: "190px", marginRight: "5px" }}
                      ref={goalNameRef}
                      type="text"
                      placeholder="Enter New Goal Element"
                      value={item.goalname}
                      onChange={() => handleGoalNameChange(index)}
                      disabled={item.is_global || index < disableGoalElement}
                    />

                    <select
                      style={{
                        padding: "4px",
                        width: "190px",
                        margin: "0 5px",
                      }}
                      onChange={(e) => handleGoalFieldRef(e, index)}
                      value={item.goal_type}
                    >
                      <option>--Select Type--</option>
                      {goalFieldTypes?.map((item1, index) => (
                        <option value={parseInt(item1?.id)}>
                          {item1?.goalfield_name}
                        </option>
                      ))}
                    </select>

                    {item.goal_type === 1 && (
                      <>
                        <select
                          style={{
                            padding: "4px",
                            width: "190px",
                            margin: "0 5px",
                          }}
                          ref={dataSourceRef}
                          value={item.datasource}
                          onChange={() => handleDataSourceChange(index)}
                        >
                          {dataSourceType?.map((item1, index) => (
                            <option value={parseInt(item1?.id)}>
                              {item1?.name}
                            </option>
                          ))}
                        </select>

                        <select
                          style={{
                            padding: "4px",
                            width: "190px",
                            margin: "0 5px",
                          }}
                          onChange={(e) => handleDataColumn(e, index)}
                          value={item.datacolumn}
                        >
                          <option>--Select Data Column--</option>
                          {item.datasource == 18 &&
                            dataSourceColumnOption1?.map((item1, index) => (
                              <option value={parseInt(item1?.id)}>
                                {item1?.column_name}
                              </option>
                            ))}
                          {item.datasource == 19 &&
                            dataSourceColumnOption2?.map((item1, index) => (
                              <option value={parseInt(item1?.id)}>
                                {item1?.column_name}
                              </option>
                            ))}
                        </select>

                        <select
                          style={{
                            padding: "4px",
                            width: "190px",
                            margin: "0 5px",
                          }}
                          value={item.datatype}
                          onChange={(e) => handleFieldTypeChange(e, index)}
                        >
                          <option>--Select Data Format--</option>
                          {fieldTypes?.map((item1, index) => (
                            <option value={parseInt(item1?.id)}>
                              {item1?.field_type}
                            </option>
                          ))}
                        </select>
                      </>
                    )}

                    {item.goal_type === 2 && (
                      <>
                        <select
                          style={{
                            padding: "4px",
                            width: "190px",
                            margin: "0 5px",
                          }}
                          value={item.goal1}
                          onChange={(e) => handleGoal1Change(e, index)}
                        >
                          <option>--Select Goal Field--</option>
                          {goalFieldName?.map((item1, index1) => {
                            if (index1 !== index)
                              return (
                                <option value={parseInt(item1?.id)}>
                                  {item1?.field_name}
                                </option>
                              );
                          })}
                        </select>

                        <select
                          style={{
                            padding: "4px",
                            width: "190px",
                            margin: "0 5px",
                          }}
                          onChange={() =>
                            handleArithemeticOperatorChange(index)
                          }
                          value={item.operator}
                          ref={arithemeticOperatorRef}
                        >
                          <option>--Select Operator--</option>
                          {arithemeticOperator?.map((item1, index) => (
                            <option value={parseInt(item1?.id)}>
                              {item1?.operator}
                            </option>
                          ))}
                        </select>

                        <select
                          style={{
                            padding: "4px",
                            width: "190px",
                            margin: "0 5px",
                          }}
                          value={item.goal2}
                          onChange={(e) => handleGoal2Change(e, index)}
                        >
                          <option>--Select Goal Field--</option>
                          {goalFieldName?.map((item1, index) => (
                            <option value={parseInt(item1?.id)}>
                              {item1?.field_name}
                            </option>
                          ))}
                        </select>
                      </>
                    )}

                    {item.goal_type === 3 && (
                      <>
                        <input
                          style={{ width: "190px", margin: "0 5px" }}
                          type="text"
                          placeholder="--Enter Data--"
                          onChange={(e) =>
                            handleLiteralGoalFieldDataChange(e, index)
                          }
                          value={item.data}
                          // ref={literalGoalFieldRef}
                        />

                        <select
                          style={{
                            padding: "4px",
                            width: "190px",
                            margin: "0 15px",
                          }}
                          value={item.datatype}
                          onChange={(e) => handleFieldTypeChange(e, index)}
                        >
                          <option>--Select Data Format--</option>
                          {fieldTypes?.map((item1, index) => (
                            <option value={parseInt(item1?.id)}>
                              {item1?.field_type}
                            </option>
                          ))}
                        </select>
                      </>
                    )}
                  </div>
                ))}

              <div className="flex">
                <button
                  className="add-goal-map-criteria"
                  onClick={AddNewCriteria}
                >
                  <FontAwesomeIcon icon={faPlus} />
                </button>
                <p className="no-margin-bottom">Add New Goal Field</p>
              </div>

              <div style={{ margin: "15px" }}>
                <button className="save-modal-button" onClick={saveGoalMap}>
                  <FontAwesomeIcon icon={faSave} style={{ margin: "0 3px" }} />
                  Save
                </button>
                <button
                  onClick={() => history.push("/school_it/ccplus")}
                  className="cancel-modal-button2"
                >
                  <FontAwesomeIcon icon={faTimes} style={{ margin: "0 3px" }} />
                  Cancel
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
};
export default MapGoal;