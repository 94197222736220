import React, { Fragment, useState, useEffect, useRef } from "react";
import GetStudentService from "../../../services/SchoolIT/GetStudentService";
import CustomPagination from "../../pagination/Pagination";
import { useHistory } from "react-router-dom";
import { Modal, ModalBody } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import CustomLoader from "../../customLoader/CustomLoader";
import swal from "sweetalert";
import Loader from "react-loader-spinner";

const DeleteCourseModal = ({ isOpen, setIsOpen, deleteCourse }) => {
  return (
    <Fragment>
      <Modal isOpen={isOpen} centered={true}>
        <ModalBody>
          <div
            style={{ textAlign: "center", margin: "10px", fontSize: "20px" }}
          >
            <p>Are you sure you want to delete?</p>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                paddingTop: "10px",
              }}
            >
              <button
                onClick={deleteCourse}
                style={{
                  backgroundColor: "#009900",
                  color: "white",
                  padding: "5px",
                  border: "none",
                  outline: "none",
                  marginRight: "20px",
                  borderRadius: "5px",
                }}
              >
                <FontAwesomeIcon
                  style={{ margin: "0 5px" }}
                  color="white"
                  icon={faCheck}
                />
                Ok
              </button>
              <button
                onClick={() => setIsOpen(false)}
                style={{
                  backgroundColor: "#efefef",
                  color: "black",
                  padding: "5px",
                  border: "none",
                  outline: "none",
                  marginLeft: "20px",
                  borderRadius: "5px",
                }}
              >
                <FontAwesomeIcon style={{ margin: "0 5px" }} icon={faTimes} />
                Cancel
              </button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

const RequiredCourseSet = () => {
  let history = useHistory();
  const [requiredCourseData, setRequiredCourseData] = useState();
  const [coursePageData, setCoursePageData] = useState();
  const [page, setPage] = useState(1);
  const [user, setUser] = useState(
    localStorage.getItem("user") && JSON.parse(localStorage.getItem("user"))
  );
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deletedCourseId, setDeletedCourseId] = useState();
  const [loading, setLoading] = useState(false);
  const [publishedButtonIndex, setPublishedButtonIndex] = useState(-1);
  const [publishedLoading, setPublishedLoading] = useState(false);

  const getCourseData = () => {
    setLoading(true);
    let obj = {
      page: page,
      role: "school_it",
      school: user?.school_id,
    };
    GetStudentService.coursePage(obj)
      .then((data) => {
        //console.log("data from the service get Course data", data);
        setCoursePageData(data?.data);
        setRequiredCourseData(data?.data);
        let convertedData = DataMapping(data?.data?.result);
        if (convertedData?.length > 0) {
          setConvertedDataToCoursepageData(convertedData);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        //console.log("error from the service", error);
      });
  };

  const DataMapping = (value) => {
    for (var i = 0; i < value.length; i++) {
      for (var j = 0; j < value[i].publish_data.length; j++) {
        if (value[i].publish_data[j].school_id === user.school_id) {
          value[i].published = value[i].publish_data[j].published;
          value[i].data = value[i].publish_data[j].data_mapped_percentage;
        }
      }
    }
    return value;
  };

  const setConvertedDataToCoursepageData = (data) => {
    if (data) {
      setCoursePageData({ ...coursePageData, result: data });
    }
  };

  //console.log("converteddata", coursePageData);
  const viewSingleCourse = (value) => {
    //console.log("value from the view single course", value);
    history.push(`/school_it/course/${value?.id}`);
  };

  const mapCourseDataHandler = (value) => {
    history.push(`/school_it/mapcourse/${value?.id}`);
  };

  const deleteCourseRequirementhandler = (item) => {
    setDeletedCourseId(item?.id);
    let obj = {
      course_id: item?.id,
    };
    GetStudentService.deleteCourseRequirement(obj)
      .then((data) => {
        //console.log("data from the service", data);
        setOpenDeleteModal(true);
      })
      .catch((error) => {
        //console.log("error from the service", error);
      });
  };

  const deleteCourseAfterRequirement = () => {
    let obj = {
      deleted: true,
    };
    GetStudentService.deleteCourse(obj)
      .then((data) => {
        //console.log("data from the delete course requiremnt", data);
        courseDeleteConfirmation(deletedCourseId);
      })
      .catch((error) => {
        //console.log("error from the service", error);
      });
  };

  const deleteUndoCourse = () => {
    let obj = {
      deleted: true,
    };
    GetStudentService.deleteCourse(obj)
      .then((data) => {
        //console.log("data from the delete course requiremnt", data);
      })
      .catch((error) => {
        //console.log("error from the service", error);
      });
  };

  const courseDeleteConfirmation = (value) => {
    let obj = {
      delete_course_id: value,
    };
    GetStudentService.deleteCourseConfirmation(obj)
      .then((data) => {
        //console.log("data from the course delete confirmation", data);
        setOpenDeleteModal(false);
        setDeletedCourseId();
        getCourseData();
      })
      .catch((error) => {
        //console.log("error from the service", error);
      });
  };

  const undoDelete = (value) => {
    let obj = {
      course_id: value?.id,
      deleted: "True",
    };
    GetStudentService.undoCourseDelete(obj)
      .then((data) => {
        //console.log("data from the undo delete", data);
        getCourseData();
      })
      .catch((error) => {
        //console.log("error from the service", error);
      });
  };

  useEffect(() => {
    deleteUndoCourse();
    getCourseData();
  }, [page]);

  //console.log("coursePageData", coursePageData);

  const publishOrUnpublishCourse = (item, index, publishValue) => {
    setPublishedLoading(true);
    setPublishedButtonIndex(index);
    //console.log("item from the publishOrUnpublishCourse", item);
    let obj = {
      course_id: item?.id,
      published: publishValue,
      school: user?.school_id,
    };
    GetStudentService.goalPublish(obj)
      .then((data) => {
        calculateGoalPublish(obj);
      })
      .catch((error) => {
        setPublishedLoading(false);
        //console.log("error from the service", error);
      });
  };

  const handleDuplicateCourse = (item) => {
    let obj = {
      clone_id: item?.id,
      school: user?.school_id,
    };
    GetStudentService.duplicateCourse(obj)
      .then((data) => {
        //console.log("data from the duplicate course", data);
        history.push(`/school_it/course/${data?.data?.new_id}`);
      })
      .catch((error) => {
        //console.log("error from the servie", error);
      });
  };

  const handleCourseEdit = (item) => {
    history.push(`/school_it/course/${item?.id}`);
  };

  const calculateGoalPublish = (value) => {
    GetStudentService.calculateGoalPublish(value)
      .then((data) => {
        //console.log("data from the service", data);
        getCourseData();
        swal({
          text: "This Goal/Course has been used by a counselor. Cannot be unpublished.",
          icon: "warning",
          buttons: {
            confirm: true,
          },
          closeOnClickOutside: false,
        });
        setPublishedLoading(false);
      })
      .catch((error) => {
        setPublishedLoading(false);
        //console.log("error from the service", error);
      });
  };

  //console.log("coursePageData", coursePageData);

  return (
    <Fragment>
      <div style={{ marginBottom: "15px" }}>
        <p style={{ fontSize: "24px", marginTop: "20px" }}>
          Required Course Set
        </p>
        <button
          onClick={() => history.push("/school_it/course/")}
          className="create-maintain-group-button"
        >
          New Required Course Set
        </button>
      </div>
      {loading ? (
        <CustomLoader />
      ) : (
        coursePageData?.result.map((item, index) => (
          <div
            style={{
              padding: "5px",
              border: "1px solid #ccc",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>
              <p style={{ fontSize: "14px" }} className="bold no-margin-bottom">
                {item?.name}
              </p>
              <p
                style={{ fontSize: "14px" }}
                className="no-margin-bottom"
              >{`Data Mapping : ${item?.data === "" ? "0" : item?.data} %`}</p>
            </div>
            <div>
              {item?.deleted === true && (
                <button
                  onClick={() => undoDelete(item)}
                  className="danger-button"
                >
                  Undo
                </button>
              )}
              {item?.deleted === false &&
                (item?.published === "" || item.published === false) && (
                  <button
                    onClick={() =>
                      publishOrUnpublishCourse(item, index, "True")
                    }
                    disabled={item?.data < 100 || item?.data == null}
                    className="create-maintain-group-button"
                    style={{
                      opacity:
                        item?.data < 100 || item?.data == null ? "0.6" : "1",
                    }}
                  >
                    Publish
                    {publishedButtonIndex === index && publishedLoading && (
                      <span>
                        <Loader
                          type="TailSpin"
                          color="white"
                          height={20}
                          width={20}
                        />
                      </span>
                    )}
                  </button>
                )}
              {item?.deleted === false && item.published === true && (
                <button
                  onClick={() => publishOrUnpublishCourse(item, index, "False")}
                  className="create-maintain-group-button"
                  style={{
                    opacity:
                      item?.data < 100 || item?.data == null ? "0.6" : "1",
                  }}
                >
                  UnPublish
                  {publishedButtonIndex === index && publishedLoading && (
                    <span>
                      <Loader
                        type="TailSpin"
                        color="white"
                        height={20}
                        width={20}
                      />
                    </span>
                  )}
                </button>
              )}
              {item?.deleted === false && (
                <button
                  className="create-maintain-group-button"
                  onClick={() => handleDuplicateCourse(item)}
                >
                  Duplicate
                </button>
              )}
              {item?.deleted === false && (
                <button
                  onClick={() => mapCourseDataHandler(item)}
                  className="create-maintain-group-button"
                >
                  Map Data
                </button>
              )}
              {item?.is_global && item?.deleted === false && (
                <button
                  onClick={() => viewSingleCourse(item)}
                  className="create-maintain-group-button"
                >
                  View
                </button>
              )}
              {item?.is_global === false && item?.deleted === false && (
                <button
                  onClick={() => handleCourseEdit(item)}
                  className="create-maintain-group-button"
                >
                  Edit
                </button>
              )}
              {item?.is_global === false && item?.deleted === false && (
                <button
                  onClick={() => deleteCourseRequirementhandler(item)}
                  className="create-maintain-group-button"
                >
                  Delete
                </button>
              )}
            </div>
          </div>
        ))
      )}
      <CustomPagination
        count={requiredCourseData}
        pageValue={page}
        setPage={setPage}
      />
      {openDeleteModal && (
        <DeleteCourseModal
          isOpen={openDeleteModal}
          setIsOpen={setOpenDeleteModal}
          deleteCourse={deleteCourseAfterRequirement}
        />
      )}
    </Fragment>
  );
};
export default RequiredCourseSet;
