import { faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { setColumnsState } from "@mui/x-data-grid/hooks/features/columns/gridColumnsUtils";
import React, { useState, useEffect, useRef, Fragment } from "react";
import GetStudentService from "../../../services/SchoolIT/GetStudentService";
import CustomPagination from "../../pagination/Pagination";
import AppHeader from "../../../Layout/AppHeader";
import AppSidebar from "../../../Layout/AppSidebar";
import CreateStudent from "./CreateStudent";
import CustomLoader from "../../customLoader/CustomLoader";

const Student = () => {
  const studentInputRef = useRef();
  const timeout = useRef();
  const [user, setUser] = useState(
    localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : []
  );
  const [searchInput, setSearchInput] = useState();
  const [page, setPage] = useState(1);
  const [studentListData, setStudentListData] = useState([]);
  const [optionValue, setOptionValue] = useState();
  const [modifyData, setModifyData] = useState({});
  const [modifyStudentIndex, setModifyStudentIndex] = useState();
  const [openCreateMessage, setOpenCreateMessage] = useState(false);
  const [openModifyStudent, setOpenModifyStudent] = useState(false);
  const [loading, setLoading] = useState(false);

  // const getStudentwithId = (value) => {
  //   setLoading(true);
  //   setSearchInput("");
  //   setOptionValue(value);
  //   let obj = {
  //     page: page,
  //     role: "student",
  //     school: user?.school_id,
  //     student_option: value,
  //   };
  //   GetStudentService.getStudent(obj)
  //     .then((data) => {
  //       //console.log("student from the service", data);
  //       setStudentListData(data.data);
  //       setLoading(false);
  //     })
  //     .catch((error) => {
  //       //console.log("error from the service", error);
  //       setLoading(false);
  //     });
  // };

  const getStudentwithId = (value, id = "", newpage=0) => {
    setLoading(true);
    setSearchInput("");
    let obj = {
      page: page,
      role: "student",
      student: value,
    };
    if(newpage===1){
      obj.page=1 ;
      setPage(1)
    }
    setOptionValue(value);

    if (id) obj.school = id;
    else obj.school = user?.school_id;

    //console.log(obj);
    GetStudentService.getStudent(obj)
      .then((data) => {
        //console.log("student from the service", data);
        setStudentListData(data.data);
        setLoading(false);
      })
      .catch((error) => {
        //console.log("error from the service", error);
        setLoading(false);
      });
  };

  const modifyStudent = (item, index) => {
    setModifyStudentIndex(index);
    GetStudentService.modifyStudent(item.id)
      .then((data) => {
        //console.log("modify student data from the service", data);
        if (!data.data.objects) {
          setModifyData({
            student: {
              first_name: item?.first_name, 
              last_name: item?.last_name,
              mobile: item?.mobile,
              email: item?.email,
              id: item?.id
            },
          });
        } else setModifyData(data.data.objects);
        setLoading(false);
      })
      .catch((error) => {
        //console.log("error from the service", error);
        setLoading(false);
      });
    setOpenModifyStudent(true);
  };

  const getStudentfromSearchHandler = (value) => {
    let obj = {
      page: page,
      role: "student",
      school: user?.school_id,
      student_option: "",
      q: value,
    };
    GetStudentService.getStudent(obj)
      .then((data) => {
        //console.log("student from the service", data);
        setStudentListData(data.data);
      })
      .catch((error) => {
        //console.log("error from the service", error);
      });
  };

  useEffect(() => {
    if (user?.school_id){
      getStudentwithId("all");
    }
    else{
    getStudentwithId(optionValue);
    }
  }, [page]);

  const StudentsHandler = () => {
    clearTimeout(timeout.current);
    setSearchInput(studentInputRef.current.value);
    timeout.current = setTimeout(() => {
      getStudentfromSearchHandler(studentInputRef.current.value);
    }, 500);
  };

  return (
    <Fragment>
      <AppHeader />
      <div className="app-main">
      <AppSidebar />
      <div className="app-main__outer">
      <div className="schoolit-student-container">
        <div className="student-heading-container">
          <div>
            <p>Students</p>
          </div>
          <button
            onClick={() => setOpenCreateMessage(true)}
            className="new-students-button"
          >
            <span>
              <FontAwesomeIcon style={{ marginRight: "5px" }} icon={faUsers} />
              New Students
            </span>
          </button>
        </div>
        <div className="school-name-container">
          <p className="school-name-container-heading">School</p>
          <div className="school-name">
            <p className="no-margin-bottom">{user?.school_name}</p>
          </div>
        </div>
        <div className="schoolit-student-list-container">
          <input
            type="text"
            ref={studentInputRef}
            className="search-student-input"
            placeholder="Search Student"
            onChange={StudentsHandler}
            value={searchInput}
          />
          <p style={{ paddingTop: "10px", fontSize: "18px" }}>Students</p>
          <div style={{ display: "flex" }}>
            <span style={{ marginRight: "5px" }}>
              <strong>show:</strong>
            </span>
            <div>
              <button
                style={{
                  borderTopLeftRadius: "5px",
                  borderBottomLeftRadius: "5px",
                }}
                onClick={() => getStudentwithId("all")}
                className="student-id-button"
              >
                All
              </button>
              <button
                onClick={() => getStudentwithId("with_id")}
                className="student-id-button"
              >
                with ID
              </button>
              <button
                onClick={() => getStudentwithId("without_id")}
                style={{
                  borderTopRightRadius: "5px",
                  borderBottomRightRadius: "5px",
                }}
                className="student-id-button"
              >
                without ID
              </button>
            </div>
          </div>
        </div>
        {loading ? (
          <CustomLoader />
        ) : (
          studentListData &&
          studentListData?.result?.map((item, index) => {
            return (
              <div
                className={`student-list ${
                  index === modifyStudentIndex && "student-list-active"
                }`}
                onClick={() => modifyStudent(item, index)}
              >
                <p
                  style={{ marginBottom: "5px" }}
                >{`${item.last_name}, ${item.first_name}`}</p>
                <p style={{ marginBottom: "5px" }}>{`<${item.email}>`}</p>
              </div>
            );
          })
        )}
        {studentListData && (
          <CustomPagination
            count={studentListData && studentListData}
            pageValue={page}
            setPage={setPage}
          />
        )}
        {openCreateMessage && (
          <CreateStudent
            setOpenCreateMessage={setOpenCreateMessage}
            modify={false}
            getStudentwithId={getStudentwithId}
            setOpenCreateStudent={setOpenModifyStudent}
          />
        )}
        {openModifyStudent && (
          <CreateStudent
            setOpenCreateMessage={setOpenModifyStudent}
            modify={modifyData}
            getStudentwithId={getStudentwithId}
            setOpenCreateStudent={setOpenModifyStudent}
          />
        )}
      </div>
      </div>
      </div>
    </Fragment>
  );
};
export default Student;
