import React, { Fragment, useState, useEffect, useRef } from "react";
import CustomLoader from "../../customLoader/CustomLoader";
import GetAdminService from "../../../services/admin/GetAdminService";
import GetStudentService from "../../../services/SchoolIT/GetStudentService";
import AppHeader from "../../../Layout/AppHeader";
import AppSidebar from "../../../Layout/AppSidebar";

import { faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomPagination from "../../pagination/Pagination";
import CreateStudent from "./CreateStudent";
import "./Student.css";
import { useLocation } from "react-router";
import swal from "sweetalert";

const Students = () => {
  const [schoolName, setSchoolName] = useState();
  const [showSchoolNameContainer, setShowSchoolNameContainer] = useState(false);
  const [schoolNameList, setSchoolNameList] = useState();
  const schoolNameRef = useRef();
  const timeoutRef = useRef();
  const [schoolNameLoading, setSchoolNameLoading] = useState(false);
  const [schoolId, setSchoolId] = useState();

  const studentInputRef = useRef();
  const timeout = useRef();
  const [searchInput, setSearchInput] = useState();
  const [page, setPage] = useState(1);
  const [studentListData, setStudentListData] = useState([]);
  const [optionValue, setOptionValue] = useState();
  const [modifyData, setModifyData] = useState({});
  const [modifyStudentIndex, setModifyStudentIndex] = useState();
  const [openCreateStudent, setOpenCreateStudent] = useState(false);
  const [openModifyStudent, setOpenModifyStudent] = useState(false);
  const [loading, setLoading] = useState(false);
  let location = useLocation();
  const [withoutCounselor, setWithoutCounselor] = useState(false);

  const getSchoolsList = (value) => {
    setSchoolNameList([]);
    setShowSchoolNameContainer(true);
    setSchoolNameLoading(true);
    GetAdminService.getSchoolsList(value)
      .then((data) => {
        //console.log("data from the service", data);
        setSchoolNameList(data?.data?.school);
        setSchoolNameLoading(false);
      })
      .catch((error) => {
        //console.log("error from the service", error);
        setSchoolNameLoading(false);
      });
  };

  const getStudentwithId = (value, id = "", newpage=0) => {
    setLoading(true);
    setSearchInput("");
    let obj = {
      page: page,
      role: "student",
      student: value,
    };
    if(newpage===1){
      obj.page=1 ;
      setPage(1)
    }
    setOptionValue(value);

    if (id) obj.school = id;
    else obj.school = schoolId;

    //console.log(obj);
    GetStudentService.getStudent(obj)
      .then((data) => {
        //console.log("student from the service", data);
        setStudentListData(data.data);
        setLoading(false);
      })
      .catch((error) => {
        //console.log("error from the service", error);
        setLoading(false);
      });
  };
  //console.log(schoolId);

  const modifyStudent = (item, index) => {
    //console.log(schoolId);
    setModifyStudentIndex(index);
    setLoading(true);
    GetStudentService.modifyStudent(item.id)
      .then((data) => {
        //console.log("modify student data from the service", data);
        if (!data.data.objects) {
          setModifyData({
            student: {
              first_name: item?.first_name,
              last_name: item?.last_name,
              mobile: item?.mobile,
              email: item?.email,
              id: item?.id,
            },
          });
        } 
        else setModifyData(data.data.objects);
        setLoading(false);
        setOpenModifyStudent(true);
      })
      .catch((error) => {
        //console.log("error from the service", error);
        setLoading(false);
        swal({
          text: error?.response?.data?.error,
          icon: "warning",
        dangerMode: true,
        buttons: {
          cancel: true,
          confirm: true,
        },
        closeOnClickOutside: true,
        })
      });
  };

  const getStudentfromSearchHandler = (value) => {
    let obj = {
      page: page,
      role: "student",
      school: schoolId,
      student_option: "",
      q: value,
    };
    //console.log(obj);
    GetStudentService.getStudent(obj)
      .then((data) => {
        //console.log("student from the service", data);
        setStudentListData(data.data);
      })
      .catch((error) => {
        //console.log("error from the service", error);
      });
  };

  useEffect(() => {
    if (schoolId) {
      if (withoutCounselor) students_without_counselor();
      else getStudentwithId(optionValue);
    }
  }, [page]);

  useEffect(() => {
    if (location?.pathname?.split("/").pop() === "students_without_counselor") {
      setWithoutCounselor(true);
    }
  }, []);

  const students_without_counselor = (id) => {
    let obj = {
      page: page,
      // role: "student",
      school: id,
    };
    // if (id) obj.school = id;
    //console.log(obj);
    GetAdminService.withoutCounselor(obj?.page, obj?.school)
      .then((data) => {
        //console.log("student from the service", data);
        let obj = data?.data;
        setStudentListData(obj);
      })
      .catch((error) => {
        //console.log("error from the service", error);
      });
  };

  const StudentsHandler = () => {
    clearTimeout(timeout.current);
    setSearchInput(studentInputRef.current.value);
    timeout.current = setTimeout(() => {
      getStudentfromSearchHandler(studentInputRef.current.value);
    }, 500);
  };

  const handleSchoolName = () => {
    clearTimeout(timeoutRef.current);
    setSchoolName(schoolNameRef.current.value);
    if (!schoolNameRef.current.value) {
      setShowSchoolNameContainer(false);
      setSchoolNameList([]);
      setStudentListData([]);
    } else {
      timeoutRef.current = setTimeout(() => {
        getSchoolsList(schoolNameRef.current.value);
      }, 1000);
    }
  };

  const handleSelectedSchoolName = (item) => {
    setSchoolNameList([item]);
    setShowSchoolNameContainer(false);
    setSchoolName(item.name);
    setSchoolId(item?.id);
    if (withoutCounselor) students_without_counselor(item?.id);
    else getStudentwithId("all", item?.id, 1);
  };

  //console.log("studentLuist", studentListData);
  return (
    <Fragment>
      <AppHeader />
      <div className="app-main">
        <AppSidebar />
        <div className="app-main__outer">
          <div className="csv-import-container">
            <div className="flex canned-message-heading">
              {!withoutCounselor ? (
                <p>
                  <span style={{ fontSize: "25px", fontWeight: "400" }}>
                    Students
                  </span>
                </p>
              ) : (
                <p>
                  <span style={{ fontSize: "25px", fontWeight: "400" }}>
                    Students without Counselors
                  </span>
                </p>
              )}
              {schoolId && !withoutCounselor && (
                <button
                  onClick={() => setOpenCreateStudent(true)}
                  className="new-students-button"
                >
                  <span>
                    <FontAwesomeIcon
                      style={{ marginRight: "5px" }}
                      icon={faUsers}
                    />
                    New Students
                  </span>
                </button>
              )}
            </div>

            <div className="school-name-container">
              <p>Select School</p>
              <div>
                <input
                  ref={schoolNameRef}
                  className="search-admin-school-input"
                  type="text"
                  placeholder="School Name"
                  value={schoolName}
                  onChange={handleSchoolName}
                  autoComplete="off"
                />
                {showSchoolNameContainer && (
                  <div>
                    <ul className="admin-school-name-list">
                      {schoolNameLoading && <CustomLoader />}
                      {schoolNameList?.length > 0 &&
                        schoolNameList.map((item, index) => (
                          <li
                            onClick={() => handleSelectedSchoolName(item)}
                            key={index}
                            className="flex admin-school-list cursor"
                          >
                            <span>{item.name}</span>
                          </li>
                        ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>

            {schoolId && (
              <div className="admin-student-list-container">
                {!withoutCounselor && (
                  <div style={{ padding: "0 15px 15px 15px" }}>
                    <input
                      type="text"
                      ref={studentInputRef}
                      className="search-student-input"
                      placeholder="Search Student"
                      onChange={StudentsHandler}
                      value={searchInput}
                    />
                    <p style={{ marginTop: "16px", fontSize: "18px" }}>
                      Students
                    </p>
                    <div style={{ display: "flex", marginTop: "16px" }}>
                      <span style={{ marginRight: "5px" }}>
                        <strong>show:</strong>
                      </span>
                      <div>
                        <button
                          style={{
                            borderTopLeftRadius: "5px",
                            borderBottomLeftRadius: "5px",
                          }}
                          onClick={() => getStudentwithId("all")}
                          className="student-id-button"
                        >
                          All
                        </button>
                        <button
                          onClick={() => getStudentwithId("with_id")}
                          className="student-id-button"
                        >
                          with ID
                        </button>
                        <button
                          onClick={() => getStudentwithId("without_id")}
                          style={{
                            borderTopRightRadius: "5px",
                            borderBottomRightRadius: "5px",
                          }}
                          className="student-id-button"
                        >
                          without ID
                        </button>
                      </div>
                    </div>
                  </div>
                )}


                {/* {loading ? (
                  <CustomLoader />
                ) : (
                  studentListData &&
                  studentListData?.result?.map((item, index) => {
                    return (
                      <div
                        className={`student-list ${index ===
                          modifyStudentIndex && "student-list-active"}`}
                        onClick={() => modifyStudent(item, index)}
                      >
                        <p style={{ marginBottom: "5px" }}>{`${
                          item.last_name
                        }, ${item.first_name}`}</p>
                        <p style={{ marginBottom: "5px" }}>{`<${
                          item.email
                        }>`}</p>
                      </div>
                    );
                  })
                )} */}

                {/* {loading ? (
                  <CustomLoader />
                ) : studentListData &&
                  studentListData.result &&
                  studentListData.result.length > 0 ? (
                  studentListData.result.map((item, index) => (
                    <div
                      className={`student-list ${index === modifyStudentIndex &&
                        "student-list-active"}`}
                      onClick={() => modifyStudent(item, index)}
                      key={index}
                    >
                      <p style={{ marginBottom: "5px" }}>{`${item.last_name}, ${
                        item.first_name
                      }`}</p>
                      <p style={{ marginBottom: "5px" }}>{`<${item.email}>`}</p>
                    </div>
                  ))
                ) : (
                  <p style={{paddingLeft:"15px"}}>No records found.</p>
                )} */}

                {loading ? (
                  <CustomLoader />
                ) : studentListData && studentListData.result ? (
                  studentListData.result.length > 0 ? (
                    studentListData.result.map((item, index) => (
                      <div
                        className={`student-list ${index ===
                          modifyStudentIndex && "student-list-active"}`}
                        onClick={() => modifyStudent(item, index)}
                        key={index}
                      >
                        <p style={{ marginBottom: "5px" }}>{`${
                          item.last_name
                        }, ${item.first_name}`}</p>
                        <p style={{ marginBottom: "5px" }}>{`<${
                          item.email
                        }>`}</p>
                      </div>
                    ))
                  ) : (
                    <p style={{ paddingLeft: "15px" }}>No records found</p>
                  )
                ) : (
                  <p style={{ paddingLeft: "15px" }}>{studentListData?.status}</p>
                )}

                {studentListData && studentListData?.page_list?.length > 1 && (
                  <div style={{ margin: "20px" }}>
                    <CustomPagination
                      count={studentListData && studentListData}
                      pageValue={page}
                      setPage={setPage}
                    />
                  </div>
                )}
                {openCreateStudent && (
                  <CreateStudent
                    setOpenCreateStudent={setOpenCreateStudent}
                    modify={false}
                    school_id={schoolId}
                    getStudentwithId={getStudentwithId}
                  />
                )}
                {openModifyStudent &&
                  (withoutCounselor ? (
                    <CreateStudent
                      setOpenCreateStudent={setOpenModifyStudent}
                      modify={modifyData}
                      school_id={schoolId}
                      getStudentwithId={students_without_counselor}
                      getCounselor={withoutCounselor}
                    />
                  ) : (
                    <CreateStudent
                      setOpenCreateStudent={setOpenModifyStudent}
                      modify={modifyData}
                      school_id={schoolId}
                      getStudentwithId={getStudentwithId}
                    />
                  ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Students;
