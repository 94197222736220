import React, { useState, useEffect, Fragment } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import CloseIcon from '@mui/icons-material/Close';
import QueryService from '../../services/QueryService';
import { useHistory } from 'react-router';
import ConfirmationModal from '../confirmationModalForSiembra/ConfirmationModalForSiembra';
import swal from 'sweetalert';


const SaveModal = ({   openSaveModal, 
                       setOpenSaveModal, 
                       saveQueryData, 
                       duplicateQueryData, 
                       from , 
                       saveForCollegeQuery, 
                    //    saveForSiembra,
                       inputValueForSave,
                       setInputValueForSave,
                       openErrorModal,
                       setOpenErrorModal,
                       payloadForSaveResult,
                       saveFromSavedResult
                    }) => {
    let history = useHistory();
    let authuser = localStorage.getItem('user');
    let parsedUser =  JSON.parse(authuser);
    const [inputValue, setInputValue] = useState();
    const [saveDatafromResult, setSaveDataFromResult] = useState(saveQueryData)
    const [duplicateQuery, setDuplicateQuery] = useState(duplicateQueryData)
    const [isOpen, setIsOpen] = useState(false);
    const [user , setUser] = useState(parsedUser);

    //console.log("datareceived",saveForCollegeQuery)

    const saveForCollege = () => {
    // let newSaveCollegeQuery = saveForCollegeQuery.map((obj) => {
    //   if (obj.config.name === "Subject Matter Proficiency") {
    //     let newData = [];
    //     obj.multi_value.forEach((rec) => {
    //       rec["name"] = rec["stem_name"];

    //       newData.push(rec);
    //     });
    //     //console.log("newData", newData);
    //     return { ...obj, multi_value: newData };
    //   } else {
    //     return obj;
    //   }
    // });
    // let queryData = JSON.parse(localStorage.getItem("query"))
    let obj = {
      college_id: user && user.college,
      creator_id: user && user.id,
    //   id: "",
      is_global: false,
      query_name: inputValueForSave && inputValueForSave,
      where_clause: saveForCollegeQuery,
      query_type: "recruiter.student",
    };
    QueryService.saveQueryForCollege(obj)
      .then((data) => {
        //console.log("data from the service", data);
        if(data.status == 200 && data?.data?.Status){
            swal({
              text: data?.data?.Status,
              icon: "success",
              buttons: {
                OK: true,
              },
              closeOnClickOutside: false,
            }).then((value) => {
              if (value === "OK") {
                history.push('/recruiter/school-query')
              }
            })}
            else{
              swal({
                text: `Something Went Wrong.`,
                icon: "error",
                buttons: {
                  OK: true,
                },
                closeOnClickOutside: false,
              })
            }
        setOpenSaveModal(false);
        history.push("/recruiter/search-query")
      })
      .catch((error) => {
        //console.error("error from the service", error);
        if(error?.response?.status == 400 && error?.response?.data?.error){
          swal({
            text: error?.response?.data?.error,
            icon: "warning",
            buttons: {
              OK: true,
            },
            closeOnClickOutside: false,
          })
        }
        setOpenSaveModal(false);
        setOpenErrorModal(true);
      });
  };

  const saveForSiembra = () => {
    // let newSaveCollegeQuery = saveForCollegeQuery.map((obj) => {
    //   if (obj.config.name === "Subject Matter Proficiency") {
    //     let newData = [];
    //     obj.multi_value.forEach((rec) => {
    //       rec["name"] = rec["stem_name"];

    //       newData.push(rec);
    //     });
    //     //console.log("newData", newData);
    //     return { ...obj, multi_value: newData };
    //   } else {
    //     return obj;
    //   }
    // });
    // let queryData = JSON.parse(localStorage.getItem("query"))
    let obj = {
      college_id: user && user.college,
      creator_id: user && user.id,
    //   id: "",
      is_global: true,
      query_name: inputValueForSave && inputValueForSave,
      where_clause: saveForCollegeQuery,
      query_type: "recruiter.student",
    };
    QueryService.saveQueryForCollege(obj)
      .then((data) => {
        //console.log("data from the service", data);
        if(data.status==200){
            swal({
              text: data?.data?.Status,
              icon: "success",
              buttons: {
                OK: true,
              },
              closeOnClickOutside: false,
            }).then((value) => {
              if (value === "OK") {
                history.push('/recruiter/school-query')
              }
            })}
            else{
              swal({
                text: `Something Went Wrong.`,
                icon: "error",
                buttons: {
                  OK: true,
                },
                closeOnClickOutside: false,
              })
            }
        setOpenSaveModal(false);
        history.push("/recruiter/search-query");
      })
      .catch((error) => {
        //console.error("error from the service", error);
        setOpenSaveModal(false);
        setOpenErrorModal(true);
      });
  };


    // const saveQueryForSavedResult = () => {
    //     let obj = {
    //         college_id: user && user.college_id,
    //         creator_id: user && user.id,
    //         id: duplicateQuery,
    //         query_id:'',
    //         student_id: payloadForSaveResult ? payloadForSaveResult : {},
    //         table_name: inputValueForSave && inputValueForSave,
    //     }
    //     //console.log("data from saveQuery", obj)
    //     QueryService.saveResultFromSaved(obj)
    //         .then(data => {
    //             //console.log("data from the deleteService", data);
    //             setDuplicateQuery()
    //             setSaveDataFromResult()
    //             setInputValue()
    //             setOpenSaveModal(false)
    //             history.push('/recruiter/search-query')
    //         })
    //         .catch(error => {
    //             if(error && error.response && error.response.status === 409){
    //                 setOpenSaveModal(false);
    //                 setOpenErrorModal(true);
    //             }
    //         })
    // }


    const saveQuery = () => {
        if(!inputValueForSave){
            return
        }
        let query = JSON.parse(localStorage.getItem('query'));
        let obj = {
            college_id: user && user.college,
            creator_id: user && user.id,
            page:1,
            table_name: inputValueForSave && inputValueForSave,
        }
        let finalObj={...query, ...obj}
        //console.log("final obj from the save Query", finalObj)
        QueryService.saveQuery(finalObj)
            .then(data => {
                //console.log("data from the deleteService", data);
                setInputValue()
                setOpenSaveModal(false)
                history.push('/recruiter/search-query')
            })
            .catch(error => {
                if(error && error.response && error.response.status === 409){
                    setOpenSaveModal(false);
                    setOpenErrorModal(true);
                }
            })
    }

    const closeSaveModal=()=>{
        setInputValue();
        setInputValueForSave('') ;
        setOpenSaveModal(false);      
    }
    
    return (
        <Fragment>
            <Modal
                className="save-modal"
                wrapClassName="modal-dialog"
                contentClassName="modal-dialog"
                isOpen={openSaveModal}
                toggle={() => closeSaveModal()}
            >
                <ModalHeader className="modal-header">
                    <div style={{ justifyContent: 'space-between' }} className="flex">
                        <div style={{ fontSize: '16px', fontWeight: 'bold', wordBreak: 'break-all' }} className="no-margin-bottom color">
                            <p className="no-margin-bottom">{from === "query-page" ? `Save New Query` : `Save Result`}</p>
                        </div>
                        <div onClick={() => closeSaveModal()} className="cursor">
                            <CloseIcon />
                        </div>
                    </div>
                </ModalHeader>
                <ModalBody>
                    <div style={{ padding: '30px' }}>
                        <p style={{ fontSize: '16px', fontWeight: 'bold', wordBreak: 'break-word' }} className="no-margin-bottom color">
                            {from === "query-page" ? `Please name your query` : `Please name your results table`}
                        </p>
                        <input className="save-modal-input" type="text" onChange={(e) => setInputValueForSave(e.target.value)} placeholder={from === "query-page" ? "Query Name" : "Enter Table Name"} value={inputValueForSave} />

                    </div>
                    <div className="flex col-md-12" style={{ justifyContent: 'space-evenly', padding: '0 0px 30px' }}>
                        {<button
                            style={{ width: from === 'query-page' ? "150px" : "240px" }}
                            className={"modal-button-active"}
                            onClick={from === "query-page" ? saveForCollege : saveQuery}
                            disabled = {inputValueForSave.trim() === ''}
                        >
                            {from === 'query-page' ? "Save for College" : "Save"}
                        </button>}
                        {/* {
                            saveFromSavedResult === true && <button
                            style={{ width: from === 'query-page' ? "150px" : "240px" }}
                            className={"modal-button-active"}
                            onClick={saveQueryForSavedResult}
                        >
                            {"Save"}
                        </button>
                        } */}
                        {from === "query-page" && <button
                            style={{ width: '150px' }}
                            className="modal-button-inactive"
                            onClick={saveForSiembra}
                            disabled = {inputValueForSave.trim() === ''}
                        >
                            {`Save for Siembra`}
                        </button>}
                        <button style={{ width: from === 'query-page' ? "150px" : "240px" }} className={"modal-button-inactive"} onClick={() => closeSaveModal()}>
                            Cancel
                        </button>
                    </div>
                </ModalBody>
            </Modal>
        </Fragment>
    )
}
export default SaveModal