import {
  faTimes,
  faAsterisk,
  faPlus,
  faCheck,
  faEnvelope,
  faQuestion,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment, useState, useEffect, useRef } from "react";
import CounselorMsgService from "../../../services/Counselor/CounselorMsgService";
import GetStudentService from "../../../services/SchoolIT/GetStudentService";
import MsgDataService from "../../../services/MsgService";

const CreateMessage = ({
  role,
  close,
  openCannedModal,
  setOpenedCannedModal,
  message,
  setMessage,
  getMessageDetails,
  selectedMessageFromScheduled,
  allMessageValue,
}) => {
  const inputTypeRef = useRef();
  const timeoutRef = useRef();
  const [studentName, setStudentName] = useState();
  const [showStudentContainer, setShowStudentContainer] = useState(false);
  const [studentList, setStudentList] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState([]);
  const [selectedStudentName, setSelectedStudentName] = useState([]);
  const [ackOrFeedback, setAckOrFeedback] = useState();
  const [showFeedBackOrAckOption, setShowFeedBackOrAckOption] = useState(false);
  const [ackParentValue, setAckParentValue] = useState(false);
  const [subjectValue, setSubjectValue] = useState("");
  const [selectedFile, setSelectedFile] = useState();
  const [user, setUser] = useState(
    localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : []
  );
  const [priorityValue, setPriorityValue] = useState("normal");
  const [feedbackOrAckText, setFeedbackOrAckText] = useState();
  const [
    showFeedbackOrAckTextContainer,
    setShowFeedbackOrAckTextContainer,
  ] = useState(false);
  const inputFileRef = useRef();
  const [fileDataFromApi, setFileDataFromApi] = useState();

  const closeCreateMessage = () => {
    setMessage("");
    setStudentName("");
    setSubjectValue("");
    setSelectedFile();
    setFeedbackOrAckText();
    setAckOrFeedback();
    setSelectedStudent([]);
    setSelectedStudentName([]);
    setStudentList([]);
    setFileDataFromApi();
    setPriorityValue("normal");
    inputTypeRef.current.value = null;
    inputFileRef.current.value = null;
    close();
  };

  const getStudent = (value) => {
    let obj = {
      role: "student",
      q: value,
    };
    GetStudentService.getStudentListForMessage(obj)
      .then((data) => {
        setShowStudentContainer(true);
        setStudentList(data.data.objects);
      })
      .catch((error) => {
        //console.log("error from the service", error);
      });
  };

  const getGroup = (value) => {
    // let grades = "";
    // if (user?.school_middle === true) {
    //   grades = `grades=06&grades=07&grades=08`;
    // } else if (user?.school_middle === false) {
    //   grades = `grades=09&grades=10&grades=11&grades=12`;
    // }
    let obj = {
      counselor_id: user?.counselor_id,
      q: value,
      school_id: user?.school_id,
    };
    GetStudentService.getGroupForCreateMessage(obj)
      .then((data) => {
        setShowStudentContainer(true);
        setStudentList(data.data.groups);
      })
      .catch((error) => {
        //console.log("error from the service", error);
      });
  };

  const handleNameChange = () => {
    clearTimeout(timeoutRef.current);
    if (!studentName) {
      setShowStudentContainer(false);
      setStudentList([]);
    }
    setStudentName(inputTypeRef.current.value);
    timeoutRef.current = setTimeout(() => {
      if (role === "student") {
        getStudent(inputTypeRef.current.value);
      } else if (role === "group") {
        getGroup(inputTypeRef.current.value);
      }
    }, 1000);
    setShowStudentContainer(true);
  };

  const handleFileChange = async (e) => {
    await setSelectedFile(inputFileRef.current.files[0]);
  };

  const submitAttachment = async () => {
    if (selectedFile) {
      MsgDataService.attachment(selectedFile)
        .then((data) => {
          setFileDataFromApi(data.data.Status);
          inputFileRef.current.value = "";
        })
        .catch((err) => 
          console.log(err));
    }
  };

  useEffect(() => {
    submitAttachment();
  }, [selectedFile]);

  const selectedStudentHandler = (value) => {
    //console.log(value);
    setSelectedStudent([...selectedStudent, value]);
    setStudentName("");
    setSelectedStudentName([...selectedStudentName, value?.name]);
    setShowStudentContainer(false);
    setStudentList([]);
  };

  const removeSelectedName = (index) => {
    selectedStudent.splice(index, 1);
    setSelectedStudent([...selectedStudent]);
    selectedStudentName.splice(index, 1);
    setSelectedStudentName([...selectedStudentName]);
  };

  const sendGroupMessage = () => {
    let answer = {
      ack: parseInt(ackOrFeedback),
      ackError: false,
      ackText: ackOrFeedback === "0" ? "feedback" : "ack",
      text: feedbackOrAckText,
      textError: false,
    };
    let selectedStudentId = selectedStudent.map((item) => item.id);
    let groupObj = {
      answers: ackOrFeedback && feedbackOrAckText ? answer : [],
      body: message,
      groups: selectedStudentId,
      priority: { value: "normal", text: "Normal" },
      school_id: user?.school_id,
      need_ack_parents: ackParentValue,
      subject: subjectValue,
      counselor_id: user?.counselor_id,
      // event_type: "school_it_student"
    };
    if (fileDataFromApi) {
      groupObj["attachment_url"] = fileDataFromApi?.image_name;
    }
    CounselorMsgService.sendGroupMessage(groupObj)
      .then((data) => {
        setMessage("");
        setStudentName();
        setSelectedStudent();
        setShowFeedBackOrAckOption(false);
        setShowFeedbackOrAckTextContainer(false);
        setFeedbackOrAckText();
        setPriorityValue("normal");
        setSubjectValue("");
        setAckParentValue(false);
        setAckOrFeedback();
        inputTypeRef.current.value = null;
        getMessageDetails();
        closeCreateMessage();
      })
      .catch((error) => {
        //console.log("error from the service", error);
      });
  };
  const sendMessage = () => {
    let obj = {
      parent_ack: ackParentValue,
      // priority: priorityValue,
      // school: user?.school_id,
      event_type: "school_it_student",
      dst_id: [selectedStudent && selectedStudent[0]?.id],
    };
    if (fileDataFromApi) {
      obj["attachment_url"] = fileDataFromApi?.image_name;
    }
    if (!selectedMessageFromScheduled) {
      // let answer = {
      //   ack: parseInt(ackOrFeedback),
      //   ackError: false,
      //   ackText: ackOrFeedback === "0" ? "feedback" : "ack",
      //   text: feedbackOrAckText,
      //   textError: false,
      // };
      // obj["answers"] = ackOrFeedback && feedbackOrAckText ? [answer] : [];
      obj["message_body"] = message;
    } else if (selectedMessageFromScheduled) {
      // obj["canned_answer"] = allMessageValue.answers;
      obj["message_body"] = message;
    }
    GetStudentService.sendMessage(obj)
      .then((data) => {
        setMessage();
        setStudentName();
        setSelectedStudent();
        setShowFeedBackOrAckOption(false);
        setShowFeedbackOrAckTextContainer(false);
        setFeedbackOrAckText();
        // setPriorityValue("normal");
        setSubjectValue();
        setAckParentValue(false);
        setAckOrFeedback();
        inputTypeRef.current.value = null;
        getMessageDetails();
        closeCreateMessage();
      })
      .catch((error) => {
        //console.log("error from the service", error);
      });
  };

  return (
    <Fragment>
      <div className="new-student-message-container">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <p style={{ fontSize: "18px" }}>
            {role === "student"
              ? `New Student Message`
              : role === "group"
              ? "New Group Message"
              : ""}
          </p>
          <FontAwesomeIcon
            icon={faTimes}
            style={{ cursor: "pointer", fontSize: "20px" }}
            onClick={closeCreateMessage}
          />
        </div>
        <button
          className="select-schedule-button"
          onClick={() => setOpenedCannedModal(true)}
        >
          Select from Scheduled
        </button>
        <div className="flex" style={{ marginTop: "15px" }}>
          <p className="no-margin-bottom">
            <strong style={{ marginRight: "15px" }}>To:</strong>
          </p>
          <div style={{ position: "relative", width: "90%" }}>
            <input
              ref={inputTypeRef}
              type="text"
              style={{
                width: "100%",
                padding: "5px",
                border: "1px solid #ccc",
                borderRadius: "4px",
              }}
              placeholder={`Type a ${
                role === "student" ? "student" : "group"
              } name to add`}
              onChange={handleNameChange}
              value={studentName}
            />
            <FontAwesomeIcon
              style={{ position: "absolute", top: "10px", right: "10px" }}
              icon={faAsterisk}
              color={"red"}
            />
          </div>
        </div>
        {showStudentContainer && (
          <ul className="maintain-group-student-dropdown-menu">
            {showStudentContainer &&
              studentList?.length > 0 &&
              studentList.map((item, index) => (
                <li
                  key={index}
                  onClick={() => selectedStudentHandler(item)}
                  className="flex maintain-group-student-list cursor"
                >
                  {role === "student" && (
                    <span
                      style={{ marginRight: "15px" }}
                      className="dropdown-grade-background"
                    >{`${item.current_grade}th grade`}</span>
                  )}
                  <span>{item.name}</span>
                </li>
              ))}
          </ul>
        )}

        <div style={{ display: "flex", gap: "10px", margin: "10px" }}>
          {selectedStudentName.map((item, index) => (
            <div
              style={{
                background: "#090",
                border: "1px solid",
                borderRadius: "5px",
                padding: "3px 10px",
                color: "white",
                fontSize: "15px",
              }}
            >
              {item}
              <span
                style={{ padding: "5px", cursor: "pointer" }}
                onClick={() => removeSelectedName(index)}
              >
                <FontAwesomeIcon icon={faTimes} />
              </span>
            </div>
          ))}
        </div>
        {role === "group" && (
          <div style={{ position: "relative", marginTop: "15px" }}>
            <input
              style={{
                width: "99%",
                border: "1px solid #ccc",
                borderRadius: "4px",
                height: "30px",
              }}
              placeholder="Type the subject"
              value={subjectValue}
              onChange={(e) => setSubjectValue(e.target.value)}
            />
            <FontAwesomeIcon
              style={{ position: "absolute", top: "10px", right: "16px" }}
              icon={faAsterisk}
              color={"red"}
            />
          </div>
        )}

        <div style={{ position: "relative", marginTop: "15px" }}>
          <textarea
            style={{
              width: "99%",
              border: "1px solid #ccc",
              borderRadius: "4px",
            }}
            rows="5"
            cols="50"
            placeholder="Type the message content"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
          <FontAwesomeIcon
            style={{ position: "absolute", top: "10px", right: "16px" }}
            icon={faAsterisk}
            color={"red"}
          />
        </div>
        <div
          style={{
            background: "white",
            borderRadius: "3px",
            margin: "0px",
            padding: "10px",
          }}
        >
          {!fileDataFromApi && (
            <input type="file" ref={inputFileRef} onChange={handleFileChange} />
          )}
          {fileDataFromApi && (
            <span>
              <span
                style={{
                  color: "#009900",
                  marginBottom: "0px",
                  fontSize: "14px",
                  wordBreak: "break-all",
                }}
              >
                {fileDataFromApi?.filename}
              </span>
              <span>
                <FontAwesomeIcon
                  icon={faTimes}
                  color="#009900"
                  style={{ cursor: "pointer", marginLeft: "5px" }}
                  onClick={() => setFileDataFromApi()}
                />
              </span>
            </span>
          )}
        </div>
        {/* <div
          style={{
            padding: "10px",
            background: "white",
            margin: "5px 0",
            fontSize: "14px",
            cursor: "pointer",
          }}
          onClick={() => setShowFeedBackOrAckOption(true)}
        >
          <span className="clarifiction-text">
            <FontAwesomeIcon icon={faPlus} /> Add a feedback/clarification
            option
          </span>
        </div> */}
        {/* {showFeedBackOrAckOption && (
          <>
            <div
              className="flex"
              style={{
                padding: "10px",
                background: "white",
                margin: "5px 0",
                fontSize: "14px",
              }}
            >
              <select
                style={{
                  height: "35px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                }}
                onChange={(e) => setAckOrFeedback(e.target.value)}
                value={ackOrFeedback}
              >
                <option value="null" disabled selected></option>
                <option value="0">feedback</option>
                <option value="1">ack</option>
              </select>
              <div style={{ marginLeft: "10px" }}>
                <input
                  style={{
                    height: "35px",
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                  }}
                  type="text"
                  value={feedbackOrAckText}
                  onChange={(e) => setFeedbackOrAckText(e.target.value)}
                />
                <FontAwesomeIcon
                  onClick={() => setShowFeedbackOrAckTextContainer(true)}
                  icon={faCheck}
                  style={{ margin: "0 15px", cursor: "pointer" }}
                  color="#008000"
                />
                <FontAwesomeIcon
                  onClick={() => setShowFeedBackOrAckOption(false)}
                  color="#aa1513"
                  icon={faTimes}
                  style={{ cursor: "pointer" }}
                />
              </div>
            </div>
            {showFeedbackOrAckTextContainer && (
              <div>
                {ackOrFeedback === "0" && (
                  <div
                    style={{
                      justifyContent: "space-between",
                      background: "white",
                      padding: "5px",
                      margin: "5px 0px",
                    }}
                    className="flex"
                  >
                    <div>
                      <span>
                        <FontAwesomeIcon
                          style={{ marginRight: "5px" }}
                          icon={faQuestion}
                        />
                        {feedbackOrAckText}
                      </span>
                    </div>
                    <div>
                      <FontAwesomeIcon
                        icon={faTimes}
                        color="#aa1513"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setAckOrFeedback("null");
                          setFeedbackOrAckText("");
                        }}
                      />
                    </div>
                  </div>
                )}
                {ackOrFeedback === "1" && (
                  <div
                    style={{
                      justifyContent: "space-between",
                      background: "white",
                      padding: "5px",
                      margin: "5px 0px",
                    }}
                    className="flex"
                  >
                    <div>
                      <span>
                        <FontAwesomeIcon
                          style={{ marginRight: "5px" }}
                          icon={faCheck}
                        />
                        {feedbackOrAckText}
                      </span>
                    </div>
                    <div>
                      <FontAwesomeIcon
                        style={{ cursor: "pointer" }}
                        icon={faTimes}
                        color="#aa1513"
                        onClick={() => {
                          setAckOrFeedback("null");
                          setFeedbackOrAckText("");
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
            )}
          </>
        )} */}
        <div style={{ background: "white", padding: "10px" }} className="flex">
          {/* <select
            style={{
              height: "35px",
              border: "1px solid #ccc",
              borderRadius: "4px",
              width: "200px",
            }}
            onChange={(e) => setPriorityValue(e.target.value)}
            value={priorityValue}
          >
            <option value="critical">Critical</option>
            <option value="high">High</option>
            <option value="normal">Normal</option>
            <option value="low">Low</option>
          </select> */}
          <div
            style={{
              display: "flex",
              // marginLeft: "25px",
              alignItems: "center",
            }}
          >
            <input
              type="checkbox"
              onChange={() => setAckParentValue(!ackParentValue)}
              checked={ackParentValue}
              value={ackParentValue}
            />
            <span style={{ marginLeft: "5px" }}>
              <strong>Parent ACK</strong>
            </span>
          </div>
        </div>
        <div
          style={{
            marginTop: "50px",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <button
            onClick={role === "group" ? sendGroupMessage : sendMessage}
            disabled={
              role === "student"
                ? !(selectedStudent && selectedStudent.length > 0 && message)
                : !(
                    selectedStudent &&
                    selectedStudent.length > 0 &&
                    message &&
                    subjectValue
                  )
            }
            style={{
              color: "white",
              background:
                role === "student"
                  ? selectedStudent && selectedStudent.length > 0 && message
                    ? "#009900"
                    : "#337ab7"
                  : selectedStudent &&
                    selectedStudent.length > 0 &&
                    message &&
                    subjectValue
                  ? "#009900"
                  : "#337ab7",
              border: "1px solid #2e6da4",
              borderRadius: "5px",
              outline: "none",
              padding: "5px 20px",
              cursor: "pointer",
            }}
          >
            <FontAwesomeIcon
              style={{ marginRight: "10px" }}
              icon={faEnvelope}
            />
            Send Message
          </button>
        </div>
      </div>
    </Fragment>
  );
};
export default CreateMessage;
