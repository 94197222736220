import React, { Fragment, useState, useEffect, useRef } from "react";
import CustomLoader from "../../customLoader/CustomLoader";
import GetAdminService from "../../../services/admin/GetAdminService";
import AppHeader from "../../../Layout/AppHeader";
import AppSidebar from "../../../Layout/AppSidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAsterisk,
  faPlus,
  faTrash,
  faSave,
  faTimes,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import { useHistory, useLocation } from "react-router";
import { Modal, ModalBody } from "reactstrap";

const MessageModal = ({ isOpen, setIsOpen, data }) => {
  return (
    <Fragment>
      <Modal isOpen={isOpen} centered={true}>
        <ModalBody>
          <div style={{ textAlign: "center" }}>
            <p>{data.message}</p>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <button
                onClick={data.function1}
                style={{
                  backgroundColor: "#009900",
                  color: "white",
                  padding: "5px",
                  border: "none",
                  outline: "none",
                  marginRight: "20px",
                  borderRadius: "5px",
                }}
              >
                <FontAwesomeIcon
                  style={{ margin: "0 5px" }}
                  color="white"
                  icon={faCheck}
                />
                Ok
              </button>
              <button
                onClick={() => setIsOpen(false)}
                style={{
                  backgroundColor: "#efefef",
                  color: "black",
                  padding: "5px",
                  border: "none",
                  outline: "none",
                  marginLeft: "20px",
                  borderRadius: "5px",
                }}
              >
                <FontAwesomeIcon style={{ margin: "0 5px" }} icon={faTimes} />
                Cancel
              </button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

const CreateCannedMessages = () => {
  let history = useHistory();
  let location = useLocation();
  const messageId = location?.pathname?.split("/").pop();
  const [input, setInput] = useState({
    language: "en",
    schoolId: "",
    ack: "0",
    answerText: "",
    ackError: false,
  });
  const [body, setBody] = useState("");
  const [esBody, setEsBody] = useState("");
  const [subject, setSubject] = useState("");
  const [esSubject, setEsSubject] = useState("");
  const [messageData, setMessageData] = useState();
  const [answers, setAnswers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [newInURL, setNewInURL] = useState(false);
  const [showAckInput, setShowAckInput] = useState(false);
  const [openMessageModal, setOpenMessageModal] = useState(false);

  const getCannedMessage = (id) => {
    const searchParam = new URLSearchParams(location.search);
    setLoading(true);
    if (searchParam.get("new") === "1") {
      setNewInURL(true);
      GetAdminService.getCannedMessage(id)
        .then((data) => {
          //console.log("data from the service", data);
          setMessageData(data.data);
          setInput({
            ...input,
            schoolId: data?.data?.school,
          });
          setLoading(false);
        })
        .catch((error) => {
          //console.log("error from the service", error);
          setLoading(false);
        });
    } else {
      GetAdminService.getMessage(id)
        .then((data) => {
          //console.log("data from the service", data);
          setMessageData(data.data);
          setAnswers(Object.values(data.data.answers));
          let messages = data.data.messages;
          setBody(messages["en"]["body"]);
          setEsBody(messages?.es?.body);
          setSubject(messages["en"]["subject"]);
          setEsSubject(messages?.es?.subject);
          setInput({
            ...input,
            schoolId: data?.data?.school,
          });
          setLoading(false);
        })
        .catch((error) => {
          //console.log("error from the service", error);
          setLoading(false);
        });
    }
  };

  const translateText = async (lan) => {
    let obj = { to: lan };
    setInput({ ...input, language: lan });
    if (lan === "es") {
      if (body !== "" && esBody === "") {
        obj.text = body;
        translateAPI(obj, setEsBody);
      }
      if (subject !== "" && esSubject === "") {
        obj.text = subject;
        translateAPI(obj, setEsSubject);
      }
    } else {
      if (body === "" && esBody !== "") {
        obj.text = esBody;
        translateAPI(obj, setBody);
      }
      if (subject === "" && esSubject !== "") {
        obj.text = esSubject;
        translateAPI(obj, setSubject);
      }
    }
  };

  const translateAPI = (obj, setTranslate) => {
    setLoading(true);
    GetAdminService.translateText(obj)
      .then((data) => {
        //console.log("data from the service", data);
        setTranslate(data.data?.tr);
        setLoading(false);
      })
      .catch((error) => {
        //console.log("error from the service", error);
        setLoading(false);
      });
  };

  const cancelNewCannedMessage = () => {
    let newObj = {
      answers: answers,
      id: messageId,
      languages: ["en", "es"],
      messages: {
        en: {
          body: body,
          subject: subject,
        },
        es: {
          body: esBody,
          subject: esSubject,
        },
      },
      resource_uri: "",
      school: input.schoolId,
      originalElement: messageData,
    };
    //console.log(152, newObj);
    GetAdminService.cancelNewCannedMessage(newObj, messageId)
      .then((data) => {
        //console.log("data from the service", data);
      })
      .catch((error) => {
        //console.log("error from the service", error);
      });
  };

  const deleteCannedMessage = () => {
    GetAdminService.deleteCannedMessage(messageId)
      .then((data) => {
        //console.log("data from the service", data);
        history.push(`/admin/canned/${messageData?.school}`);
      })
      .catch((error) => {
        //console.log("error from the service", error);
      });
  };

  const saveCannedMessage = () => {
    let newObj = {};
    answers.map((item, index) => {
      newObj[index + 1] = item;
    });
    let obj1 = {
      messages: {
        en: {
          body: body,
          subject: subject,
        },
        es: {
          body: esBody,
          subject: esSubject,
        },
      },
      answers: newObj,
    };
    GetAdminService.saveCannedMessage(obj1, messageId)
      .then((data) => {
        //console.log("data from the service", data);
        history.push(`/admin/canned/${messageData?.school}`);
      })
      .catch((error) => {
        //console.log("error from the service", error);
      });
  };

  const addAnswer = () => {
    //console.log("first", input);
    if (input.answerText === "") {
      setInput({ ...input, ackError: true });
    } else {
      setShowAckInput(false);
      let newArrItem = {
        ack: input.ack,
        ackError: false,
        ackText: input.ack === "0" ? "feedback" : "ack",
        text: {
          en: input.answerText,
          es: "",
        },
        textError: false,
      };
      setAnswers([...answers, newArrItem]);
      setInput({ ...input, answerText: "", ack: "0" });
    }
  };

  useEffect(() => {
    getCannedMessage(messageId);
  }, []);

  return (
    <Fragment>
      <AppHeader />
      <div className="app-main">
        <AppSidebar />
        <div className="app-main__outer">
          {loading ? (
            <CustomLoader />
          ) : (
            <div className="Create-Canned">
              <div
                className="flex"
                style={{
                  justifyContent: "space-between",
                  marginBottom: "20px",
                }}
              >
                <p style={{ fontSize: "24px" }} className="no-margin-bottom">
                  Canned Messages
                </p>
              </div>
              <div className="requiredname-input-container">
                <div style={{ textAlign: "right", paddingTop: "7px" }}>
                  <p className="no-margin-bottom requirement-text">Language</p>
                </div>
                <div style={{ position: "relative", marginBottom: "10px" }}>
                  <button
                    onClick={() => translateText("en")}
                    className={
                      input.language === "en"
                        ? "create-message-button"
                        : "disabled-language-button"
                    }
                  >
                    en
                  </button>
                  &nbsp; &nbsp;
                  <button
                    onClick={() => translateText("es")}
                    className={
                      input.language === "es"
                        ? "create-message-button"
                        : "disabled-language-button"
                    }
                  >
                    es
                  </button>
                  <div style={{ color: "green" }}>
                    *Select Language To Translate Subject and Body In Other
                    Language.
                  </div>
                </div>
              </div>
              {input.language === "en" ? (
                <div>
                  <div className="requiredname-input-container">
                    <div style={{ textAlign: "right" }}>
                      <p className="no-margin-bottom requirement-text">
                        Subject(en)
                      </p>
                    </div>
                    <div style={{ position: "relative", marginRight: "20px" }}>
                      <input
                        value={subject}
                        onChange={(e) => setSubject(e.target.value)}
                        style={{
                          width: "100%",
                          padding: "5px 15px 5px 5px",
                          borderRadius: "5px",
                        }}
                        type="text"
                        placeholder="Type the message subject"
                        className="require-input-name"
                      />
                      <FontAwesomeIcon
                        style={{
                          position: "absolute",
                          top: "10px",
                          right: "10px",
                        }}
                        icon={faAsterisk}
                        color="red"
                      />
                    </div>
                  </div>
                  <div className="requiredname-input-container">
                    <div style={{ textAlign: "right" }}>
                      <p className="no-margin-bottom requirement-text">
                        Body(en)
                      </p>
                    </div>
                    <div style={{ position: "relative", marginRight: "20px" }}>
                      <textarea
                        value={body}
                        onChange={(e) => setBody(e.target.value)}
                        style={{
                          width: "100%",
                          padding: "5px 20px 5px 5px",
                          borderRadius: "5px",
                        }}
                        type="text"
                        className="require-input-name"
                        placeholder="Type the message content here"
                        rows="10"
                      />
                      <FontAwesomeIcon
                        style={{
                          position: "absolute",
                          top: "10px",
                          right: "10px",
                        }}
                        icon={faAsterisk}
                        color="red"
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <div className="requiredname-input-container">
                    <div style={{ textAlign: "right" }}>
                      <p className="no-margin-bottom requirement-text">
                        Subject(es)
                      </p>
                    </div>
                    <div style={{ position: "relative", marginRight: "20px" }}>
                      <input
                        value={esSubject}
                        onChange={(e) => setEsSubject(e.target.value)}
                        style={{
                          width: "100%",
                          padding: "5px 15px 5px 5px",
                          borderRadius: "5px",
                        }}
                        type="text"
                        placeholder="Type the message subject"
                        className="require-input-name"
                      />
                      <FontAwesomeIcon
                        style={{
                          position: "absolute",
                          top: "10px",
                          right: "10px",
                        }}
                        icon={faAsterisk}
                        color="red"
                      />
                    </div>
                  </div>
                  <div className="requiredname-input-container">
                    <div style={{ textAlign: "right" }}>
                      <p className="no-margin-bottom requirement-text">
                        Body(es)
                      </p>
                    </div>
                    <div style={{ position: "relative", marginRight: "20px" }}>
                      <textarea
                        value={esBody}
                        onChange={(e) => setInput(e.target.value)}
                        style={{
                          width: "100%",
                          padding: "5px 20px 5px 5px",
                          borderRadius: "5px",
                        }}
                        type="text"
                        className="require-input-name"
                        placeholder="Type the message content here"
                        rows="10"
                      />
                      <FontAwesomeIcon
                        style={{
                          position: "absolute",
                          top: "10px",
                          right: "10px",
                        }}
                        icon={faAsterisk}
                        color="red"
                      />
                    </div>
                  </div>
                </div>
              )}

              <div className="requiredname-input-container">
                <div />
                <div
                  style={{
                    position: "relative",
                    marginRight: "20px",
                    padding: "10px 20px",
                    border: "1px solid #ccc",
                    background: "white",
                  }}
                >
                  <div
                    style={{ cursor: "pointer", marginBottom: "10px" }}
                    onClick={() => setShowAckInput(true)}
                  >
                    <FontAwesomeIcon icon={faPlus} />
                    &nbsp;
                    <span style={{ textDecoration: "underline" }}>
                      Add a feedback/clarification option
                    </span>
                  </div>
                  <FontAwesomeIcon
                    style={{ position: "absolute", top: "10px", right: "10px" }}
                    icon={faAsterisk}
                    color="red"
                  />
                  {showAckInput && (
                    <div
                      style={{
                        borderTop: "1px solid rgba(0, 0, 0, 0.1)",
                        padding: "10px",
                      }}
                    >
                      <select
                        name="ack"
                        className="require-input-name"
                        style={{
                          padding: "5px",
                          borderRadius: "5px",
                          marginRight: "20px",
                        }}
                        value={input.ack}
                        onChange={(e) =>
                          setInput({ ...input, ack: e.target.value })
                        }
                      >
                        <option value="0">Feedback</option>
                        <option value="1">Ack</option>
                      </select>
                      <input
                        value={input.answerText}
                        onChange={(e) =>
                          setInput({
                            ...input,
                            answerText: e.target.value,
                            ackError: false,
                          })
                        }
                        style={{
                          width: "60%",
                          padding: "5px 15px 5px 5px",
                          borderRadius: "5px",
                        }}
                        type="text"
                        className={
                          input.ackError ? "input-error" : "require-input-name"
                        }
                      />
                      <span
                        style={{
                          width: "20px",
                          marginLeft: "10px",
                          padding: "0 5px",
                          cursor: "pointer",
                        }}
                        onClick={addAnswer}
                      >
                        <FontAwesomeIcon icon={faCheck} color="green" />
                      </span>
                      <span
                        style={{
                          width: "20px",
                          marginLeft: "10px",
                          padding: "0 5px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setShowAckInput(false);
                          setInput({ ...input, answerText: "", ack: "0" });
                        }}
                      >
                        <FontAwesomeIcon icon={faTimes} color="red" />
                      </span>
                    </div>
                  )}
                  {answers?.map((item, index) => (
                    <div
                      style={{
                        position: "relative",
                        borderTop: "1px solid rgba(0, 0, 0, 0.1)",
                        padding: "10px 0",
                      }}
                    >
                      <div style={{ width: "80%" }}>
                        {item.ack == "0" ? (
                          <span
                            style={{
                              margin: "0 20px",
                              color: "#8a6d3b",
                              fontSize: "20px",
                            }}
                          >
                            ?
                          </span>
                        ) : (
                          <span style={{ margin: "0 20px" }}>
                            <FontAwesomeIcon icon={faCheck} color="#31708f" />
                          </span>
                        )}

                        <span
                          style={{
                            position: "absolute",
                            top: "10px",
                            left: "120px",
                          }}
                        >
                          {item.text.en}
                        </span>
                      </div>
                      <span
                        style={{
                          position: "absolute",
                          top: "10px",
                          right: "10px",
                          width: "20px",
                          padding: "0 5px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          answers.splice(index, 1);
                          setAnswers([...answers]);
                        }}
                      >
                        <FontAwesomeIcon icon={faTimes} color="red" />
                      </span>
                    </div>
                  ))}
                </div>
              </div>

              <div className="save-bottom-row">
                <button
                  disabled={!subject || !body }
                  className={
                    !subject || !body 
                      ? "disabled-modal-button"
                      : "save-modal-button"
                  }
                  onClick={saveCannedMessage}
                >
                  <FontAwesomeIcon
                    icon={faSave}
                    style={{ margin: "0 5px", color: "white" }}
                  />
                  Save
                </button>
                {!newInURL && (
                  <button
                    className="cancel-modal-button"
                    onClick={() => setOpenMessageModal(true)}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                    &nbsp;Remove
                  </button>
                )}
                <button
                  onClick={() => {
                    if (newInURL) cancelNewCannedMessage();
                    history.push(`/admin/canned/${messageData?.school}`);
                  }}
                  className="cancel-modal-button2"
                >
                  <FontAwesomeIcon icon={faTimes} style={{ margin: "0 5px" }} />
                  Cancel
                </button>
              </div>
            </div>
          )}
          {openMessageModal && (
            <MessageModal
              setIsOpen={setOpenMessageModal}
              isOpen={openMessageModal}
              data={{
                function1: deleteCannedMessage,
                message: "Are you sure you want to delete?",
              }}
            />
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default CreateCannedMessages;
