import { faCheck, faTimes, faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment, useState, useEffect, useRef } from "react";
import { useLocation } from "react-router";
import swal from "sweetalert";
import AppHeader from "../../../Layout/AppHeader";
import AppSidebar from "../../../Layout/AppSidebar";
import GetStudentService from "../../../services/SchoolIT/GetStudentService";
import CustomLoader from "../../customLoader/CustomLoader";

const MaintainGroup = () => {
  let location = useLocation();
  const [maintainGroupData, setMaintainGroupData] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [
    selectedGroupDataFromService,
    setSelectedGroupDataFromService,
  ] = useState([]);
  const [selectedGroupIndex, setSelectedGroupIndex] = useState(null);
  const [openCreateGroup, setOpenCreateGroup] = useState(
    location?.state?.openGroupMessage
      ? location?.state?.openGroupMessage
      : false
  );
  const [page, setPage] = useState(1);
  const [groupName, setGroupName] = useState("");
  const [user, setUser] = useState(
    localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : []
  );
  const [
    showLoaderWhileAddingStudentIntoList,
    setShowLoaderWhileAddingStudentIntoList,
  ] = useState(false);
  const [createGroupData, setCreateGroupData] = useState(null);
  const [openAddStudentContainer, setOpenAddStudentContainer] = useState(false);
  const [studentName, setStudentName] = useState("");
  const [student, setStudent] = useState([]);
  const [showStudentContainer, setShowStudentContainer] = useState(false);
  const [showStudentList, setShowStudentList] = useState([]);
  const [showAddedStudentInGroup, setShowAddedStudentInGroup] = useState(false);
  const [
    studentListAfterAddedInGroup,
    setStudentListAfterAddedInGroup,
  ] = useState();
  const studentInputRef = useRef(null);
  const timeout = useRef();
  const [loading, setLoading] = useState(false);

  const getMaintainGroupData = () => {
    let obj = {
      school: user?.school_id,
      counselor: user?.counselor_id,
      page: page
    }
    GetStudentService.getMaintainGroupData(obj)
      .then((data) => {
        setMaintainGroupData(data.data);
      })
      .catch((error) => {
        //console.log("error from the service", error);
      }
    );
  };

  const showSelectedGroupData = (item, index) => {
    //console.log("itemfrom the show Selected Group Data", item);
    setLoading(true);
    if (item?.dynamic === false) {
      setOpenCreateGroup(true);
      setOpenAddStudentContainer(true);
      setCreateGroupData(item);
      setShowAddedStudentInGroup(true);
    } else if (item?.dynamic === true) {
      setOpenCreateGroup(false);
      setOpenAddStudentContainer(false);
    }
    setSelectedGroupDataFromService([]);
    //console.log("item", item);
    setSelectedGroup(item);
    setSelectedGroupIndex(index);
    setStudentName("");
    if (studentInputRef.current) {
      studentInputRef.current.value = "";
      setShowStudentContainer(false);
    }
  
    GetStudentService.getSelectedGroupData(item?.id)
      .then((data) => {
        setSelectedGroupDataFromService(data.data);
        if (item?.dynamic === false) {
          setStudentListAfterAddedInGroup(data.data);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        //console.log("error from the service", error);
      });
  };

  const openCreateGroupHandler = () => {
    setOpenCreateGroup(true);
    // setOpenCreateGroup(false);
    setOpenAddStudentContainer(false);
  };
  const closeCreateGroupHandler = () => {
    setOpenCreateGroup(false);
    setGroupName("");
  };
  const createGroupHandler = () => {
    let obj = {
      id: "null",
      counselor: user?.counselor_id,
      name: groupName,
      school_id: user?.school_id,
      // student:"",
    };
    GetStudentService.createGroup(obj)
      .then((data) => {
        setCreateGroupData(data.data.objects);
        setOpenAddStudentContainer(true);
        setGroupName("");
        getMaintainGroupData();
      })
      .catch((error) => {
        if(error?.response?.status == 400 && error?.response?.data?.error){
          swal({
            text: error?.response?.data?.error,
            icon: "warning",
            buttons: {
              OK: true,
            },
            closeOnClickOutside: false,
          }).then((value) => {
            if (value === "OK") {
              setGroupName("");
            }
          });
        }
        //console.log("error from the service", error);
      });
  };

  const deleteGroup = (value) => {
    swal({
      text: `Are you sure that you want to delete ${value?.name} group?`,
      icon: "warning",
      dangerMode: true,
      buttons: {
        No: true,
        Yes: true,
      },
      closeOnClickOutside: true,
    }).then((res) => {
      if (res === "Yes") {
        deleteGroupService(value);
      }
    });
  };

  const deleteStudentHandler = (student) => {
    swal({
      text: `Are you sure that you want to delete the student ${
        student?.name
      }?`,
      icon: "warning",
      dangerMode: true,
      buttons: {
        No: true,
        Yes: true,
      },
      closeOnClickOutside: true,
    }).then((value) => {
      // //console.log("Hi");
      if (value === "Yes") {
        deleteStudent(student);
      }
    });
  };

  const getStudentData = (value) => {
    let obj = {
      college_id: user?.college,
      page: 1,
      student_search: value
    };

    GetStudentService.getList(obj)
      .then((data) => {
        //console.log("data from the get Student service", data);
        setShowStudentList(data.data.results.students);
        setShowStudentContainer(true);
      })
      .catch((error) => {
        //console.log("error from the service", error);
      });
  };

  const handleStudentChange = (e) => {
    clearTimeout(timeout.current);
    const inputValue = studentInputRef.current.value;
    if (!inputValue) {
      setShowStudentContainer(false);
      setStudent([]);
      setStudentName("");
    } else {
      setStudentName(inputValue);
      timeout.current = setTimeout(() => {
        getStudentData(inputValue);
      }, 1000);
      setShowStudentContainer(true);
    }
  };
  

  const selectStudentFromListHandler = async (value) => {
    try {
      setShowLoaderWhileAddingStudentIntoList(true);
      let body = {
        group_id: createGroupData?.id,
        student_id: value?.id,
      };
  
      const addStudentResponse = await GetStudentService.addStudentInGroup(body);
      //console.log("data from the service", addStudentResponse);
      
      if (addStudentResponse.status === 200) {
        try {
          const groupDataResponse = await GetStudentService.getSelectedGroupData(createGroupData?.id);
          setShowStudentContainer(false);
          setShowStudentList([]);
          setShowAddedStudentInGroup(true);
          setStudentListAfterAddedInGroup(groupDataResponse.data);
          setShowLoaderWhileAddingStudentIntoList(false);
          studentInputRef.current.value = null;
          setStudentName("");
        } catch (error) {
          setShowLoaderWhileAddingStudentIntoList(false);
          if (error?.response?.status === 400 && error?.response?.data?.error) {
            swal({
              text: error?.response?.data?.error,
              icon: "warning",
              buttons: {
                OK: true,
              },
              closeOnClickOutside: false,
            });
          } else {
            //console.error("Error fetching group data:", error);
          }
        }
      } 
    } catch (error) {
      setShowLoaderWhileAddingStudentIntoList(false);
      //console.error("Error adding student to group:", error);
      if(error.response.status === 400){
        swal({
          text: "Student already exists in the group.",
          icon: "warning",
          buttons: {
            OK: true,
          },
          closeOnClickOutside: false,
        }).then((value) => {
          if (value === "OK") {
            setStudentName("");
            setShowStudentContainer(false);
          }
        });
      }
    }
  };
  

  const deleteGroupService = (value) => {
    let obj = {
      group_id:value?.id
    }
    GetStudentService.deleteGroup(obj)
      .then((data) => {
        getMaintainGroupData();
        setOpenAddStudentContainer(false);
        setOpenCreateGroup(false);
        setSelectedGroup({});
        setSelectedGroupIndex();
        setSelectedGroupDataFromService([]);
        setStudentListAfterAddedInGroup([]);
      })
      .catch((error) => 
        console.log("error from the service", error
      
      ));
  };

  const deleteStudent = (student) => {
    //console.log("alue from the delete student", student);
    studentInputRef.current.value = null;
    setStudentName("");
    let obj = {
      group_id:createGroupData?.id,
      student_id:student?.id
    }
    GetStudentService.deleteStudentFromGroup(obj).then((data) => {
      setShowLoaderWhileAddingStudentIntoList(true);

      GetStudentService.getSelectedGroupData(createGroupData?.id)
        .then((data) => {
          setShowStudentContainer(false);
          setShowStudentList([]);
          setShowAddedStudentInGroup(true);
          setStudentListAfterAddedInGroup(data.data);
          setShowLoaderWhileAddingStudentIntoList(false);
          studentInputRef.current.value = null;
          setStudentName("");
        })
        .catch((error) => {
          //console.log("error from the service", error);
          setShowLoaderWhileAddingStudentIntoList(false);
        })
        .catch((error) => 
          console.log("error from the service", error));
    });
  };
  useEffect(() => {
    getMaintainGroupData();
  }, []);

  useEffect(() => {
    let createdGroupIndex = maintainGroupData?.result?.findIndex(
      (item) => item.name === createGroupData?.name
    );
    setSelectedGroupIndex(createdGroupIndex);
  }, [createGroupData, maintainGroupData]);

  //console.log("studentListAfterAddedInGroup", studentListAfterAddedInGroup);
  const studentList = studentListAfterAddedInGroup?.student || {};
  return (
    <Fragment>
      <AppHeader />
      <div className="app-main">
        <AppSidebar />
        <div className="app-main__outer">
          <div style={{ padding: "15px" }}>
            <div className="flex" style={{ justifyContent: "space-between" }}>
              <p className="no-margin-bottom" style={{ fontSize: "24px" }}>
                Maintain Groups
              </p>
              <button
                className="create-maintain-group-button"
                onClick={openCreateGroupHandler}
              >
                <span style={{ marginRight: "5px" }}>
                  <FontAwesomeIcon icon={faUsers} />
                </span>
                Create Group
              </button>
            </div>
            <div className="maintain-group-container">
              <div>
                {maintainGroupData?.result?.map((item, index) => (
                  <div
                    onClick={() => showSelectedGroupData(item, index)}
                    className={`${
                      index === selectedGroupIndex
                        ? "active-group-class"
                        : "maintain-group-name"
                    }`}
                  >
                    <p
                      style={{ fontSize: "14px" }}
                      className="no-margin-bottom"
                    >
                      {item?.name}
                    </p>
                  </div>
                ))}
              </div>
              {!openCreateGroup && (
                <div
                  style={{
                    width: "100%",
                    alignItems: "flex-start",
                    flexDirection: "column",
                  }}
                  className="flex"
                >
                  <div
                    style={{
                      width: "100%",
                      justifyContent: "space-between",
                      display: "flex",
                    }}
                  >
                    <p style={{ marginBottom: "18px", fontSize: "18px" }}>
                      {selectedGroup?.name}
                    </p>
                    {selectedGroup &&
                      selectedGroupDataFromService &&
                      selectedGroupDataFromService?.dynamic === false && (
                        <button
                          onClick={() => deleteGroup(selectedGroup)}
                          style={{
                            background: "#d9534f",
                            color: "white",
                            outline: "none",
                            border: "none",
                            width: "30px",
                            height: "30px",
                          }}
                        >
                          <FontAwesomeIcon icon={faTimes} />
                        </button>
                      )}
                  </div>

                  {loading ? (
                    <CustomLoader />
                  ) : (
                    selectedGroupDataFromService?.student &&
                    Object.values(selectedGroupDataFromService.student).map(
                      (item) => {
                        return (
                          <div
                            style={{
                              justifyContent: "space-between",
                              width: "100%",
                              alignItems: "flex-start",
                            }}
                            className="maintain-group-name flex"
                          >
                            <p
                              style={{ fontSize: "14px", color: "#555555" }}
                              className="no-margin-bottom"
                            >
                              {item?.name}
                            </p>
                            {selectedGroupDataFromService?.dynamic ===
                              false && (
                              <button
                                onClick={() => deleteStudentHandler(item)}
                                style={{
                                  background: "#d9534f",
                                  color: "white",
                                  outline: "none",
                                  border: "none",
                                  width: "30px",
                                  height: "30px",
                                }}
                              >
                                <FontAwesomeIcon icon={faTimes} />
                              </button>
                            )}
                          </div>
                        );
                      }
                    )
                  )}
                </div>
              )}
              {openCreateGroup && !openAddStudentContainer && (
                <div className="flex" style={{ alignItems: "flex-start" }}>
                  <input
                    className="maintain-create-group-name"
                    type="text"
                    placeholder="Group Name"
                    onChange={(e) => setGroupName(e.target.value)}
                    value={groupName}
                  />
                  <div>
                    <button
                      onClick={createGroupHandler}
                      style={{
                        background: "var(--success-color)",
                        color: "white",
                        outline: "none",
                        border: "none",
                        width: "30px",
                        height: "30px",
                        margin: "0 10px",
                      }}
                    >
                      <FontAwesomeIcon icon={faCheck} />
                    </button>
                    <button
                      onClick={closeCreateGroupHandler}
                      style={{
                        background: "#d9534f",
                        color: "white",
                        outline: "none",
                        border: "none",
                        width: "30px",
                        height: "30px",
                      }}
                    >
                      <FontAwesomeIcon icon={faTimes} />
                    </button>
                  </div>
                </div>
              )}
              {openCreateGroup && openAddStudentContainer && (
                <div className="flex" style={{ alignItems: "flex-start" }}>
                  <div
                    className="flex"
                    style={{
                      flexDirection: "column",
                      width: "100%",
                      alignItems: "flex-start",
                    }}
                  >
                    <div
                      className="flex"
                      style={{ justifyContent: "space-between", width: "100%" }}
                    >
                      <p
                        className="no-margin-bottom"
                        style={{ fontSize: "16px" }}
                      >
                        Group Name : &nbsp;
                        {createGroupData?.name}
                      </p>
                      <button
                        onClick={() => deleteGroup(createGroupData)}
                        style={{
                          background: "#d9534f",
                          color: "white",
                          outline: "none",
                          border: "none",
                          width: "30px",
                          height: "30px",
                        }}
                      >
                        <FontAwesomeIcon icon={faTimes} />
                      </button>
                    </div>
                    <input
                      style={{
                        width: "inherit",
                        marginTop: "10px",
                        border: "1px solid black",
                        borderRadius: "3px",
                        padding: "7px",
                      }}
                      type="text"
                      onChange={handleStudentChange}
                      placeholder="Type a student name to add"
                      value={studentName}
                      ref={studentInputRef}
                    />
                    <ul className="maintain-group-student-dropdown-menu">
                      {showStudentContainer &&
                        showStudentList?.length > 0 &&
                        showStudentList.map((item, index) => (
                          <li
                            key={index}
                            onClick={() => selectStudentFromListHandler(item)}
                            className="flex maintain-group-student-list cursor"
                          >
                            <span
                              style={{ marginRight: "15px" }}
                              className="dropdown-grade-background"
                            >{`${item.current_grade}th grade`}</span>
                            <span>{item.name}</span>
                          </li>
                        ))}
                    </ul>
                    {showLoaderWhileAddingStudentIntoList ? (
                      <CustomLoader />
                    ) : showAddedStudentInGroup && studentList ? (
                      Object.values(studentList).map((item, index) => (
                        <div
                          className="flex"
                          style={{
                            border: "1px solid #ddd",
                            padding: "5px",
                            width: "100%",
                            justifyContent: "space-between",
                          }}
                        >
                          <p className="no-margin-bottom">{item?.name}</p>
                          <button
                            onClick={() => deleteStudentHandler(item)}
                            style={{
                              background: "#d9534f",
                              color: "white",
                              outline: "none",
                              border: "none",
                              width: "30px",
                              height: "30px",
                            }}
                          >
                            <FontAwesomeIcon icon={faTimes} />
                          </button>
                        </div>
                      ))
                    ) : null}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default MaintainGroup;
