import React, { Fragment, useState, useEffect, useRef } from "react";
import CustomLoader from "../../customLoader/CustomLoader";
import GetStudentService from "../../../services/SchoolIT/GetStudentService";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { TrendingUpRounded } from "@mui/icons-material";
import AppHeader from "../../../Layout/AppHeader";
import AppSidebar from "../../../Layout/AppSidebar";
import Loader from "react-loader-spinner";
import { useHistory } from "react-router";
import { faFile, faBuilding } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import NewSchool from "./NewSchool";
// import UploadSchool from "./UploadSchool";
import swal from "sweetalert";
import "./School.css";

const API_URL = process.env.REACT_APP_API_URL;

const OpenModal = ({ data, isOpen, closeHandler }) => {
  //console.log("data from the modal", data);
  return (
    <Fragment>
      <Modal isOpen={isOpen} toggle={closeHandler}>
        <ModalHeader>
            Course List File Uploaded Successfully!!!
        </ModalHeader>
        <ModalBody>
          <div style={{ padding: "10px" }}>
            {data && (
              <div style={{ padding: "10px", background: "#fcf8e3" }}>
                <h4>Course List File Upload Error</h4>
                {data?.error?.map((item) => (
                  <div style={{ color: "#607d8b", fontSize: "14px" }}>
                    {item}
                  </div>
                ))}
              </div>
            )}
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            style={{
              color: "white",
              background: "#009900",
              border: "none",
              outline: "none",
              padding: "5px",
              borderRadius: "3px",
            }}
            onClick={closeHandler}
          >
            Close
          </button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

const SchoolSetup = () => {
  let history = useHistory();
  const uploadCourseRef = useRef();
  const [uploadCourseFile, setUploadCourseFile] = useState();
  const [dataAfterFileUpload, setDataAfterFileUpload] = useState();
  const [loadingForCsvDownload, setLoadingForCsvDownload] = useState(false);

  const [openModalAfterUpload, setOpenModalAfterUpload] = useState(false);

  const [modifyData, setModifyData] = useState({});
  const [schoolId, setSchoolId] = useState();
  const [schoolName, setSchoolName] = useState();
  const [showSchoolNameContainer, setShowSchoolNameContainer] = useState(false);
  const [schoolNameList, setSchoolNameList] = useState();
  const schoolNameRef = useRef();
  const timeoutRef = useRef();
  // const user = useSelector((state) => state.UserReducer?.userObj);
  const [user, setUser] = useState(
    localStorage.getItem("user") && JSON.parse(localStorage.getItem("user"))
  );
  const [schoolNameLoading, setSchoolNameLoading] = useState(false);
  const [openUploadSchool, setOpenUploadSchool] = useState(false);
  const [openNewSchool, setOpenNewSchool] = useState(false);
  const [openModifySchool, setOpenModifySchool] = useState(false);
  //   const [enableState, setEnableState] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const [dataPrint, setDataPrint] = useState();

  const [districtName, setDistrictName] = useState();
  const [showDistrictNameContainer, setShowDistrictNameContainer] = useState(
    false
  );
  const [loadingForUCSetup, setLoadingForUCSetup] = useState(false);
  const [districtNameList, setDistrictNameList] = useState();
  const [districtID, setDistrictID] = useState();
  const districtNameRef = useRef();
  const [districtNameLoading, setDistrictNameLoading] = useState(false);

  // const getSchoolsList = (value) => {
  //   setSchoolNameLoading(true);
  //   let obj = {
  //     q: value,
  //   };
  //   GetStudentService.getSchoolsList(obj)
  //     .then((data) => {
  //       //console.log("data from the service", data);
  //       setSchoolNameList(data?.data?.objects);

  //       setSchoolNameLoading(false);
  //       setShowSchoolNameContainer(true);
  //     })
  //     .catch((error) => {
  //       //console.log("error from the service", error);
  //       setSchoolNameLoading(false);
  //     });
  // };

  // const handleSchoolName = () => {
  //   clearTimeout(timeoutRef.current);
  //   setSchoolName(schoolNameRef.current.value);
  //   if (!schoolNameRef.current.value) {
  //     setShowSchoolNameContainer(false);
  //     setSchoolNameList([]);
  //   } else {
  //     timeoutRef.current = setTimeout(() => {
  //       getSchoolsList(schoolNameRef.current.value);
  //     }, 1000);
  //   }
  // };

  // const handleSelectedSchoolName = (item) => {
  //   setSchoolNameList([item]);
  //   setShowSchoolNameContainer(false);
  //   setSchoolName(item?.name);
  //   // setOpenModifySchool(true);
  //   // setEnableState(true);
  //   setModifyData(item);
  //   setSchoolId(item?.id);
  //   // setSchoolName('');
  // };


  const downLoadCsvTemplate = () => {
    setLoadingForCsvDownload(TrendingUpRounded);
    let template = [];
    template.push([
      '"Datasource"',
      '"UC A (History/Social Science)"',
      '"UC B (English)"',
      '"UC C (Mathematics)"',
      '"UC D (Laboratory Science)"',
      '"UC E (Foreign Language)"',
      '"UC E (Foreign Language)"',
      '"UC F (Visual & Performing Arts)"',
      '"UC G (College Prep Elective)"'
    ]);
    let arr = template;
    let ret = [];
    for (let i = 0, len = arr.length; i < len; i++) {
      let line = [];
      for (let key in arr[i]) {
        if (arr[i].hasOwnProperty(key)) {
          line.push(arr[i][key]);
        }
      }
      ret.push(line.join(","));
      ret.push('"Course_History_Template"');
    }
    let result_csv = ret.join("\n");
    let saving = document.createElement("a");
    saving.href = "data:attachment/csv," + encodeURIComponent(result_csv);
    saving.download = "csv_course_history_template.csv";
    document.body.appendChild(saving);
    saving.click();
    setLoadingForCsvDownload(false);
  };

  const uploadCourseHandler = () => {
    let file = uploadCourseRef.current.files[0];
    //console.log("file from the upload course handler", file);
    setUploadCourseFile(file);
  };

  const uploadFileHandler = () => {
    let formData = new FormData();
    formData.append("csvfilename", undefined);
    formData.append("csv_file", uploadCourseFile);
    fetch(`${API_URL}/api/v2/course/upload-file`, {
      method: "POST",
      headers: {
        "x-authorization": `Bearer ${localStorage.getItem("token")}`,
      },
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        //console.log("data from the service", data);
        setDataAfterFileUpload(data);
      })
      .catch((error) => {
        //console.log("error from the service", error);
      });
  };

  useEffect(() => {
    if (uploadCourseFile) {
      uploadFileHandler();
    }
  }, [uploadCourseFile]);

  const uploadCourse = () => {
    let obj = {
      school: user?.school_id,
      upload: dataAfterFileUpload?.message,
    };
    GetStudentService.uploadAfter(obj)
      .then((data) => {
        //console.log("data from the upload course service", data);
        setDataAfterFileUpload(data.data);
        //console.log("data",data?.data?.error[0])
        setDataPrint(data?.data?.error[0])
        setOpenModalAfterUpload(true);
        uploadCourseRef.current.value = "";
        setUploadCourseFile(null);
      })
      .catch((error) => {
        //console.log("error from the service", error);
      });
  };

  function handleOptionChange(event) {
    setSelectedOption(event.target.value);
  }

  function handleDropdownChange(event) {
    setSelectedValue(event.target.value);
  }

  // const getDistrictsList = (value) => {
  //   setDistrictNameLoading(true);
  //   let obj = {
  //     q: value,
  //   };
  //   GetStudentService.getDistrictsList(obj)
  //     .then((data) => {
  //       //console.log("data from the service", data);
  //       setDistrictNameList(data?.data?.objects);
  //       setDistrictNameLoading(false);
  //       setShowDistrictNameContainer(true);
  //     })
  //     .catch((error) => {
  //       //console.log("error from the service", error);
  //       setDistrictNameLoading(false);
  //     });
  // };

  const getDataSwal = () => {
    setLoadingForUCSetup(true)
    GetStudentService.getDataSwal(user?.school_id)
      .then((data) => {
        //console.log("data from the service", data);
        swal({
          title: "Successfully",
          text: data?.data?.message,
          icon: "success",
          buttons: {
            OK: true,
          },
          closeOnClickOutside: true,
        });
        setLoadingForUCSetup(false);
      })
      .catch((error) => {
        setLoadingForUCSetup(false);
        //console.log("error from the service", error);
        swal({
          title: "Oops!!",
          text: "This school is not eligible for setup process.",
          icon: "warning",
          dangerMode: true,
          buttons: {
            cancel: true,
          },
          closeOnClickOutside: true
      })
      });
  }
  // const handleDistrictName = () => {
  //   clearTimeout(timeoutRef.current);
  //   setDistrictName(districtNameRef.current.value);
  //   if (!districtNameRef.current.value) {
  //     setShowDistrictNameContainer(false);
  //     setDistrictNameList([]);
  //   } else {
  //     timeoutRef.current = setTimeout(() => {
  //       getDistrictsList(districtNameRef.current.value);
  //     }, 1000);
  //   }
  // };

  // const handleSelectedDistrictName = (item) => {
  //   setDistrictID(item?.id);
  //   setDistrictNameList([item]);
  //   setShowDistrictNameContainer(false);
  //   setDistrictName(item.name);
  //   // getDistrictPage(item?.id);
  // };

  return (
    <Fragment>
      <AppHeader />
      <div className="app-main">
        <AppSidebar />
        <div className="app-main__outer">
          <div className="csv-import-container">
            <div className="flex canned-message-heading">
              <p>
                <span style={{ fontSize: "25px", fontWeight: "400" }}>
                  School Setup
                </span>
              </p>
              {selectedOption === "yes" && (
              <button 
              onClick={downLoadCsvTemplate} 
              className="import-csv-button">
                Download CSV Template File For UC/CSU
              {loadingForCsvDownload && (
              <span>
                <Loader type="TailSpin" color="white" height={20} width={20} />
              </span>
               )}
            </button>)}
              {/* <div>
                <button
                  onClick={() => setOpenUploadSchool(true)}
                  style={{ marginLeft: "auto", marginRight: "10px" }}
                  className="create-school-button"
                >
                  <span>
                    {" "}
                    <FontAwesomeIcon
                      icon={faFile}
                      style={{ marginRight: "10px" }}
                    />
                    Upload School
                  </span>
                </button>
                <button
                  onClick={() => setOpenNewSchool(true)}
                  className="create-school-button"
                >
                  <span>
                    <FontAwesomeIcon
                      icon={faBuilding}
                      style={{ marginRight: "10px" }}
                    />
                    New School
                  </span>
                </button>
              </div> */}
            </div>
            <div className="school-name-container">
              {/* <p>Select School</p>
              <div>
                <input
                  ref={schoolNameRef}
                  className="search-student-input"
                  type="text"
                  style={{
                    width: "500px",
                    height: "35px",
                    padding: "5px 10px",
                    marginBottom: "10px",
                  }}
                  placeholder="School Name"
                  value={schoolName}
                  onChange={handleSchoolName}
                  autoComplete="off"
                />
                {showSchoolNameContainer && (
                  <div>
                    <ul
                      className="maintain-group-student-dropdown-menu"
                      style={{
                        width: "700px",
                        maxHeight: "600px",
                        overflowY: "scroll",
                        borderRadius: "5px",
                        padding: "10px",
                      }}
                    >
                      {schoolNameLoading && <CustomLoader />}
                      {schoolNameList?.length > 0 &&
                        schoolNameList.map((item, index) => (
                          <li
                            onClick={() => handleSelectedSchoolName(item)}
                            key={index}
                            className="flex maintain-group-student-list cursor"
                          >
                            <span>{item.name}</span>
                          </li>
                        ))}
                    </ul>
                  </div>
                )}
              </div> */}
              {/* <div>
              <p style={{marginTop:"20px"}}>Select District</p>
                  <select 
                  value={selectedValue} onChange={handleDropdownChange}
                //   disabled={!enableState}
                  style={{
                    width: "500px",
                    height: "35px",
                    padding: "5px 10px",
                    borderRadius: "5px"

                  }}
                  >
                    <option value="">Select District</option>
                    <option value="california">California</option>
                    <option value="non-california">Non-California</option>
                  </select>
                  </div> */}
              <p>District</p>
              <div>
                <input
                  // ref={districtNameRef}
                  className="search-admin-school-input"
                  type="text"
                  // placeholder="District Name"
                  value={user?.district_name}
                  // onChange={handleDistrictName}
                  autoComplete="off"
                  style={{ width: "500px", backgroundColor:"green", border:"none", color:"white"}}
                  disabled
                />
                {/* {showDistrictNameContainer && (
                  <div>
                    <ul
                      className="maintain-group-student-dropdown-menu"
                      style={{
                        width: "700px",
                        maxHeight: "600px",
                        overflowY: "scroll",
                        borderRadius: "5px",
                        padding: "10px",
                      }}
                    >
                      {districtNameLoading && <CustomLoader />}
                      {districtNameList?.length > 0 &&
                        districtNameList.map((item, index) => (
                          <li
                            onClick={() => handleSelectedDistrictName(item)}
                            key={index}
                            className="flex admin-school-list cursor"
                          >
                            <span>{item.name}</span>
                          </li>
                        ))}
                    </ul>
                  </div>
                )} */}
              </div>
              <div>
                <p style={{ marginTop: "20px" }}>Select State</p>
                <select
                  value={selectedValue}
                  onChange={handleDropdownChange}
                  //   disabled={!enableState}
                  style={{
                    width: "500px",
                    height: "35px",
                    padding: "5px 10px",
                    borderRadius: "5px",
                  }}
                >
                  <option value="">Select State</option>
                  <option value="california">California</option>
                  <option value="non-california" disabled>
                    Non-California
                  </option>
                </select>
              </div>
              <br />
              {selectedValue === "california" && (
              <div>
                <p>Course List File Upload</p>
                <label>
                  <input
                    type="radio"
                    value="yes"
                    checked={selectedOption === "yes"}
                    onChange={handleOptionChange}
                  />
                  {" "}Yes
                </label>
                &emsp;
                <label>
                  <input
                    type="radio"
                    value="no"
                    checked={selectedOption === "no"}
                    onChange={handleOptionChange}
                  />
                  {" "}No
                </label>
              </div>)}

              {selectedOption === "yes" && (
                <div>
               <div
               className="flex-course"
               style={{marginTop:"20px"}}
               >
               <input
                 type="file"
                 ref={uploadCourseRef}
                 onChange={uploadCourseHandler}
                 accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
               />
               <button
                 //disabled={!uploadCourseRef?.current?.files[0]}
                 onClick={uploadCourse}
                 className="calButton"
               >
                 Upload
               </button>
             </div>
             <div>
              {dataPrint === "CSU/UC A to G Setup Enable" ?
             <button
               onClick={() => getDataSwal()}
               style={{ display: "flex" , background:"blue", marginTop:"20px" }}
               className="download-report-button btn-secondary"
             >
                {/* UC A To UC G Setup &nbsp; */}
                UC/CSU A To G Setup &nbsp;
               {loadingForUCSetup && ( 
                <span>
                 <Loader
                  type="TailSpin"
                  color="white"
                  height={15}
                  width={15}
                 />
                </span>
             )}
             </button>:""}
           </div>
           </div>
              )}
              <br/>
              {selectedOption === "no" && (
                 <div>
                 <button
                   onClick={() => getDataSwal()}
                   style={{ display: "flex" , background:"blue" }}
                   className="download-report-button btn-secondary"
                 >
                    {/* UC A To UC G Setup &nbsp; */}
                    UC/CSU A To G Setup &nbsp;
                   {loadingForUCSetup && ( 
                    <span>
                     <Loader
                      type="TailSpin"
                      color="white"
                      height={15}
                      width={15}
                     />
                    </span>
                 )}
                 </button>
               </div>
              )}
            </div>
          </div>
          {openModalAfterUpload && (
        <OpenModal
          data={dataAfterFileUpload}
          isOpen={openModalAfterUpload}
          closeHandler={() => {
            setOpenModalAfterUpload(false);
            history.push("/school_it/school_setup");
          }}
        />
      )}
        </div>
      </div>
    </Fragment>
  );
};
export default SchoolSetup;
