import React, { Fragment, useState, useEffect, useRef } from "react";
import CustomLoader from "../../customLoader/CustomLoader";
import GetAdminService from "../../../services/admin/GetAdminService";
import AppHeader from "../../../Layout/AppHeader";
import AppSidebar from "../../../Layout/AppSidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import CreateRecruiter from "./CreateRecruiter";
import CustomPagination from "../../pagination/Pagination";

const Recruiters = () => {
  const [collegeName, setCollegeName] = useState();
  const [showCollegeNameContainer, setShowCollegeNameContainer] = useState(
    false
  );
  const [openModifyRecruiter, setOpenModifyRecruiter] = useState(false);
  const [page, setPage] = useState(1);
  const [recruiterListData, setRecruiterListData] = useState([]);
  const [collegeNameList, setCollegeNameList] = useState();
  const collegeNameRef = useRef();
  const timeoutRef = useRef();
  const [collegeNameLoading, setCollegeNameLoading] = useState(false);
  const [showRecruiter, setShowRecruiter] = useState(false);
  const [openRecruiter, setOpenRecruiter] = useState(false);
  const [collegeId, setCollegeId] = useState();
  const [loading, setLoading] = useState(false);
  const [modifyData, setModifyData] = useState({});
  const [modifyRecruiterIndex, setRecruiterIndex] = useState();
  const [pageData, setPageData] = useState();

  const getCollegesList = (value) => {
    setCollegeNameLoading(true);
    GetAdminService.getCollegesList(value)
      .then((data) => {
        //console.log("data from the service", data);
        let recruiterData = data?.data?.college;
        let recruiterName = recruiterData?.map((item)=>item.college)
        setCollegeNameList(recruiterName);
        setCollegeNameLoading(false);
        setShowCollegeNameContainer(true);
      })
      .catch((error) => {
        //console.log("error from the service", error);
        setCollegeNameLoading(false);
      });
  };

  const handleCollegeName = () => {
    clearTimeout(timeoutRef.current);
    setCollegeName(collegeNameRef.current.value);
    if (!collegeNameRef.current.value) {
      setShowCollegeNameContainer(false);
      setCollegeNameList([]);
      setRecruiterListData([]);
    } else {
      timeoutRef.current = setTimeout(() => {
        getCollegesList(collegeNameRef.current.value);
      }, 1000);
    }
  };

  const handleSelectedCollegeName = (item) => {
    setCollegeNameList([item]);
    setShowCollegeNameContainer(false);
    setShowRecruiter(true);
    setCollegeId(item?.id);
    setCollegeName(item.name);
    getRecruiterwithId(item?.id);
  };

  const getRecruiterwithId = (id) => {
    setLoading(true);
    let obj = {
      college: id,
      page: page,
      role: "recruiter",
    };
    GetAdminService.getRecruiter(obj)
      .then((data) => {
        //console.log("student from the service", data);
        setRecruiterListData(data.data);
        setPageData(data?.data)
        setLoading(false);
      })
      .catch((error) => {
        //console.log("error from the service", error);
        setLoading(false);
      });
  };

  const handleSelectedRecruiter = (item) => {
    setModifyData(item);
    setOpenModifyRecruiter(true);
  };

  useEffect(() => {
  if(pageData && pageData?.page_list?.length > 1){
     getRecruiterwithId();}
  }, [page]);

  return (
    <Fragment>
      <AppHeader />
      <div className="app-main">
        <AppSidebar />
        <div className="app-main__outer">
          <div className="admin-canned-container">
            <div className="flex canned-message-heading">
              <p>
                <span style={{ fontSize: "25px", fontWeight: "400" }}>
                  Recruiters
                </span>
              </p>
              {showRecruiter ? (
                <div>
                  <button
                    onClick={() => setOpenRecruiter(true)}
                    style={{
                      marginLeft: "auto",
                      marginRight: "10px",
                      padding: "10px 10px",
                    }}
                    className="create-maintain-group-button"
                  >
                    <span>
                      {" "}
                      <FontAwesomeIcon
                        icon={faUser}
                        style={{ marginRight: "10px"}}
                      />
                      Add Recruiter
                    </span>
                  </button>
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="school-name-container">
              <p>Select College</p>
              <div>
                <input
                  ref={collegeNameRef}
                  className="search-student-input"
                  type="text"
                  style={{
                    width: "500px",
                    height: "35px",
                    padding: "5px 10px",
                    marginBottom: "10px",
                  }}
                  placeholder="College Name"
                  value={collegeName}
                  onChange={handleCollegeName}
                  autoComplete="off"
                />
                {showCollegeNameContainer && (
                  <div>
                    <ul
                      className="maintain-group-student-dropdown-menu"
                      style={{
                        width: "700px",
                        maxHeight: "600px",
                        overflowY: "scroll",
                        borderRadius: "5px",
                        padding: "10px",
                      }}
                    >
                      {collegeNameLoading && <CustomLoader />}
                      {collegeNameList?.length > 0 &&
                        collegeNameList?.map((item, index) => (
                          <li
                            onClick={() => handleSelectedCollegeName(item)}
                            key={index}
                            className="flex maintain-group-student-list cursor"
                          >
                            <span>{item.name}</span>
                          </li>
                        ))}
                    </ul>
                  </div>
                )}
              </div>
              <div className="admin-student-list-container">
                {loading ? (
                  <CustomLoader />
                ) : (
                  recruiterListData.length !== 0 && (
                    <div>
                      <p
                        style={{
                          padding: "8px 0px 8px 0px",
                          fontSize: "18px",
                        }}
                      >
                        Recruiters
                      </p>
                  {recruiterListData?.result?.map((item, index) => {
                    return (
                      <div
                        style={{ margin: "0" }}
                        className={`student-list ${index ===
                          modifyRecruiterIndex && "student-list-active"}`}
                        onClick={() => handleSelectedRecruiter(item)}
                      >
                        {/* <p style={{ marginBottom: "5px" }}>{`${
                          item.last_name
                        }, ${item.first_name}`}</p> */}
                        <p style={{ marginBottom: "5px" }}>{`<${
                          item.email
                        }>`}</p>
                      </div>
                    );
                  })}
                  </div>)
                )}
              </div>
            </div>
            {pageData && pageData?.page_list?.length > 1 && (
              <div style={{ margin: "0 20px" }}>
                <CustomPagination
                  count={pageData && pageData}
                  pageValue={page}
                  setPage={setPage}
                />
              </div>
            )}
            {openRecruiter && (
              <CreateRecruiter
                setOpenRecruiter={setOpenRecruiter}
                modify={false}
                college_id={collegeId}
                recruiterCall={getRecruiterwithId}
              />
            )}
            {openModifyRecruiter && (
              <CreateRecruiter
                setOpenRecruiter={setOpenModifyRecruiter}
                modify={modifyData}
                college_id={collegeId}
                recruiterCall={getRecruiterwithId}
              />
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Recruiters;
