import React, { Fragment, useState, useEffect, useRef } from "react";
import CustomLoader from "../../customLoader/CustomLoader";
import GetAdminService from "../../../services/admin/GetAdminService";
import AppHeader from "../../../Layout/AppHeader";
import AppSidebar from "../../../Layout/AppSidebar";
import { faFile, faBuilding } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NewSchool from "./NewSchool";
import UploadSchool from "./UploadSchool";
import "./School.css";

const School = () => {
  const [modifyData, setModifyData] = useState({});
  const [schoolId, setSchoolId] = useState();
  const [schoolName, setSchoolName] = useState();
  const [showSchoolNameContainer, setShowSchoolNameContainer] = useState(false);
  const [schoolNameList, setSchoolNameList] = useState();
  const schoolNameRef = useRef();
  const timeoutRef = useRef();
  const [schoolNameLoading, setSchoolNameLoading] = useState(false);
  const [openUploadSchool, setOpenUploadSchool] = useState(false);
  const [openNewSchool, setOpenNewSchool] = useState(false);
  const [openModifySchool, setOpenModifySchool] = useState(false);

  const getSchoolsList = (value) => {
    setSchoolNameLoading(true);
    GetAdminService.getAllSchoolsList(value)
      .then((data) => {
        //console.log("data from the service", data);
        setSchoolNameList(data?.data?.school);

        setSchoolNameLoading(false);
        setShowSchoolNameContainer(true);
      })
      .catch((error) => {
        //console.log("error from the service", error);
        setSchoolNameLoading(false);
      });
  };

  const handleSchoolName = () => {
    clearTimeout(timeoutRef.current);
    setSchoolName(schoolNameRef.current.value);
    if (!schoolNameRef.current.value) {
      setShowSchoolNameContainer(false);
      setSchoolNameList([]);
    } else {
      timeoutRef.current = setTimeout(() => {
        getSchoolsList(schoolNameRef.current.value);
      }, 1000);
    }
  };

  const handleSelectedSchoolName = (item) => {
    setSchoolNameList([item]);
    setShowSchoolNameContainer(false);
    setSchoolName(item.name);
    setOpenModifySchool(true);
    setModifyData(item);
    setSchoolId(item?.id);
    setSchoolName('');
  };

  return (
    <Fragment>
      <AppHeader />
      <div className="app-main">
        <AppSidebar />
        <div className="app-main__outer">
          <div className="csv-import-container">
          <div className="flex canned-message-heading">
              <p>
                <span style={{ fontSize: "25px", fontWeight: "400" }}>
                  Schools
                </span>
              </p>
              <div>
                {/* <button
                  onClick={() => setOpenUploadSchool(true)}
                  style={{ marginLeft: "auto", marginRight: "10px" }}
                  className="create-school-button"
                >
                  <span>
                    {" "}
                    <FontAwesomeIcon
                      icon={faFile}
                      style={{ marginRight: "10px" }}
                    />
                    Upload School
                  </span>
                </button> */}
                <button
                  onClick={() => setOpenNewSchool(true)}
                  className="create-school-button"
                >
                  <span>
                    <FontAwesomeIcon
                      icon={faBuilding}
                      style={{ marginRight: "10px" }}
                    />
                    New School
                  </span>
                </button>
              </div>
            </div>
            <div className="school-name-container">
              <p>Select School</p>
              <div>
                <input
                  ref={schoolNameRef}
                  className="search-student-input"
                  type="text"
                  style={{
                    width: "500px",
                    height: "35px",
                    padding: "5px 10px",
                    marginBottom: "10px",
                  }}
                  placeholder="School Name"
                  value={schoolName}
                  onChange={handleSchoolName}
                  autoComplete="off"
                />
                {showSchoolNameContainer && (
                  <div>
                    <ul
                      className="maintain-group-student-dropdown-menu"
                      style={{
                        width: "700px",
                        maxHeight: "600px",
                        overflowY: "scroll",
                        borderRadius: "5px",
                        padding: "10px",
                      }}
                    >
                      {schoolNameLoading && <CustomLoader />}
                      {schoolNameList?.length > 0 &&
                        schoolNameList.map((item, index) => (
                          <li
                            onClick={() => handleSelectedSchoolName(item)}
                            key={index}
                            className="flex maintain-group-student-list cursor"
                          >
                            <span>{item.name}</span>
                          </li>
                        ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>
          {openUploadSchool && (
            <UploadSchool setOpenUploadSchool={setOpenUploadSchool} />
          )}
          {openNewSchool && (
            <NewSchool
              setOpenNewSchool={setOpenNewSchool}
              modify={false}
              schoolId={schoolId}
            />
          )}
          {openModifySchool && (
            <NewSchool
              setOpenNewSchool={setOpenModifySchool}
              modify={modifyData}
              schoolId={schoolId}
            />
          )}
        </div>
      </div>
    </Fragment>
  );
};
export default School;
