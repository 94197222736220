import { request } from "../services/Request";
import { routes } from "../constants/constant.route";
import { urlConstants } from "../utils/urlConstants";

const { API_URL } = urlConstants;
const { GET_UNREAD_MESSAGE_COUNT, GET_MESSGAE_LIST_PER_USER, SEND_MESSAGE, READ_COUNT_DATE } = routes.MESSAGE;

export default class MsgDataService {
  static getMsgData(page) {
      return request("GET", `${API_URL}${routes.msg.GET_TOTAL_MESSAGE_LIST}?page=${page}`, null, null, null);
  }

  static getMsgRecruiterData(page,type) {
    return request("GET", `${API_URL}${routes.msg.GET_TOTAL_MESSAGE_LIST}?page=${page}&event_type=${type}`, null, null, null);
  }

  static getTotalMessageList(){
    return request('GET' , `${API_URL}${routes.msg.GET_TOTAL_MESSAGE_LIST}`, null, null, null)
  }

  static getCannedMsg(obj){
      return request('GET' , `${API_URL}${routes.getCannedMsg.GET_CANNED_MSG}?college_id=${obj.college_id}&page=${obj.page}`, null, null, null)
  }

  static getList(page, college_id , queryValue){
    // let query;
    //   if(queryValue.length > 0){
    //       if(isStudentOrCounselor === 'students'){
    //           query = 'q'
    //       }else{
    //           query = 'q'
    //       }
    //   }
      return request('GET' , `${API_URL}${routes.getListOfStudent.GET_LIST}?page=${page}&college_id=${college_id}&student_search=${queryValue}`, null, null, null)


  }
  static sendMessage(body){
      return request('POST', `${API_URL}${routes.sendMessage.SEND_MESSAGE}`, null , body , null)
  }

  static attachment(body){
      return request('POST', `${API_URL}${routes.attachment.ATTACHMENT}`, null , body , null , null, "formData-single-attachment","file")
  }

  static deleteattachment(body){
      return request('DELETE', `${API_URL}${routes.attachment.ATTACHMENT}`, null , body , null , null, null)
  }

  static deleteRecruiterMsg(body){
    return request('DELETE', `${API_URL}${routes.deleteMessage.DELETE_REC_MESSAGE}`, null , body , null , null, null)
  }

  static deleteMsg(body){
      return request('DELETE', `${API_URL}${routes.deleteMessage.DELETE_MESSAGE}`, null , body , null , null, null)
  }

  static deleteGroupMsg(body){
    return request('DELETE', `${API_URL}${routes.deleteMessage.DELETE_GROUP_MESSAGE}`, null , body, null , null, null)
}

  static getCannedMsgPage(id, page){
      return request('GET', `${API_URL}${routes.getMessageTemplate.getMessageTemplate}?college_id=${id}&page=${page}`, null , null , null , null, null)
  }
  static deleteCannedMessageTemplate(id){
      return request('DELETE', `${API_URL}${routes.deleteMessageTemplate.deleteMessageTemplate}?id=${id}`, null , null , null , null, null)
  }

  static getSingleMessagesForStudent=(id)=>{
      return request('GET', `${API_URL}${routes.getSingleMessageForStudent.getSingleMessageForStudent}/${id}`, null, null, null, null);
  }

  static getIdOfSelectedRole=(body)=>{
      return request('POST', `${API_URL}${routes.getSingleMessageForStudent.gettingIdForMessageList}`, null , body , null , null, null)
  }



  static query_result_message=(body)=>{
      return request('POST', `${API_URL}${routes.query_result_pagination.query_result_pagination}`, null , body , null , null, null)
  }

  //student
  static getUnreadCountForStudent = () => {
    return request(
      "GET",
      `${API_URL}${GET_UNREAD_MESSAGE_COUNT}`,
      null,
      null,
      null,
      null,
      null
    );
  };

  //student msg
  static getMessageListPerUser = (page, src_id) => {
    return request(
      "GET",
      `${API_URL}${GET_MESSGAE_LIST_PER_USER}?page=${page}&src_id=${src_id}`,
      null,
      null,
      null,
      null,
      null
    );
  };

  // send Message
  static reply = (body) => {
    return request('POST', `${API_URL}${SEND_MESSAGE}`, null, body, null, null, null)
  }

  static getSpecificMessageDetail=(id)=>{
      return request('GET', `${API_URL}${SEND_MESSAGE}/${id}`, null , null , null , null, null)
  }

  static readCountDate=(body)=>{
      return request('POST',`${API_URL}${READ_COUNT_DATE}`, null , body , null , null, null )
  }

}