import React, { Fragment, useState, useEffect, useLayoutEffect } from "react";
import SingleQuery from "./singlequery/SingleQuery";
import QueryService from "../../services/QueryService";
import { stateData, gender } from "../../constants/data";
import GPASlider from "./gpa_slider/GPA_SLIDER.js";
import { useHistory, useLocation } from "react-router";
import AdvanceQueryCriteria from "./advanceQuery/AdvanceQuery";
import CloseOutlined from "@mui/icons-material/CloseOutlined";
import CustomLoader from "../../components/customLoader/CustomLoader";
import SaveModal from "../../components/saveModal/SaveModal";
import ConfirmationDialog from "../../components/confirmationModalForSiembra/ConfirmationModalForSiembra";
import ErrorModal from "../../components/errorDisplayModal/ErrorDisplayModal";
import _ from "lodash";
import AppHeader from "../../Layout/AppHeader";
import AppSidebar from "../../Layout/AppSidebar";
import { useDispatch, useSelector } from "react-redux";
import { changeQueryLocation } from "../../reducers/locationReducer";
import { Opacity } from "@material-ui/icons";
import StudentGoalService from "../../services/StudentGoalService.js";

const sfgv = {
  name: "Student First Generation",
  value: "student first generation",
  Selected: false,
};

const ucGreen = {
  name: "UC Green Requirement",
  value: "uc_status",
  Selected: false,
};

const gradeData = [
  { value: "09", name: "Freshman" },
  { value: "10", name: "Sophomore" },
  { value: "11", name: "Junior" },
  { value: "12", name: "Senior" },
  { value: "alumni", name: "alumni" },
];
const QueryComponent = () => {
  const gradeFromData = _.cloneDeep(gradeData);
  //console.log("gradeFromData", gradeData, gradeFromData);
  const stateOfCountry = _.cloneDeep(stateData);
  const genderData = JSON.parse(JSON.stringify(gender));
  const cloneDataForUC = _.cloneDeep(ucGreen);
  const cloneDataForFirstGeneration = _.cloneDeep(sfgv);
  let history = useHistory();
  let location = useLocation();

  const dispatch = useDispatch();
  const queryData = useSelector((state) => state?.locationReducer?.query);
  //console.log("dataQuery11", queryData);
  const [inputValueForSave, setInputValueForSave] = useState("");
  const [selectedTab, setSelectedTab] = useState("customQuery");
  const [selectedQueries, setSelectedQueries] = useState([]);
  const [ethnicity, setEthnicityData] = useState();
  const [showEthData, setShowEthData] = useState();
  const [showStateData, setShowStateData] = useState(
    stateOfCountry.slice(0, 3)
  );
  const [valueWhichExpand, setValueWhichExpanded] = useState();
  const [grade, setGrade] = useState(gradeFromData.slice(0, 3));
  const [academicsData, setAcademicsData] = useState();
  const [showAcademicsData, setShowAcademicsData] = useState();
  const [activitiesData, setActivitiesData] = useState();
  const [showActivitiesData, setShowActivitiesData] = useState();
  const [athleticsData, setAthleticsData] = useState();
  const [showAthleticsData, setShowAthleticsData] = useState();
  const [interestData, setInterestData] = useState();
  const [showInterestData, setShowInterestData] = useState();
  const [stemData, setStemData] = useState();
  const [selectedStateQueries, setSelectedStateQueries] = useState([]);
  const [selectedEthnicityQueries, setSelectedEthnicityQueries] = useState([]);
  const [configObject, setConfigObject] = useState([]);
  const [selectedGradeQueries, setSelectedGradeQueries] = useState([]);
  const [selectedAcademicsQueries, setSelectedAcademicsQueries] = useState([]);
  const [selectedAthleticsQueries, setSelectedAthleticsQueries] = useState([]);
  const [selectedActivitiesQueries, setSelectedActivitiesQueries] = useState(
    []
  );
  const [selectedInterestsQueries, setSelectedInterestsQueries] = useState([]);
  const [selectedStemQueries, setSelectedStemQueries] = useState([]);
  const [genderValue, setGenderValue] = useState();
  const [minGPA, setMinGPA] = useState(0);
  const [maxGPA, setMaxGPA] = useState(4);
  const [openAdvanceQuerySection, setOpenAdvanceQuerySection] = useState(false);
  const [selectedThirdParameter, setSelectedThirdParameter] = useState([]);
  const [noOfAdvanceQuery, setNoOfAdvanceQuery] = useState([]);
  const [advanceQueryCount, setAdvanceQueryCount] = useState(0);
  const [queryResultData, setQueryResultData] = useState();
  const [openSaveQueryModal, setOpenSaveQueryModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openConfirmationForSiembra, setOpenConfirmationForSiembra] = useState(
    false
  );
  const [
    studentFirstGenerationValue,
    setStudentFirstGenerationValue,
  ] = useState(cloneDataForFirstGeneration);
  const [studentFirstGeneration, setStudentFirstGeneration] = useState(false);
  const [ucGreenRequirement, setUCGreenRequirement] = useState(false);
  const [ucGreenRequirementValue, setUCGreenRequirementValue] = useState(
    cloneDataForUC
  );
  const [queryTittle, setQueryTittle] = useState();
  const [viewQueryData, setViewQueryData] = useState([queryData && queryData]);
  const user = useSelector((state) => state?.UserReducer?.userObj);
  const [openExistingErrorModal, setOpenExistingErrorModal] = useState();
  const [schoolData, setSchoolData] = useState();
  // const [page, setPage] = useState(1);
  const [config, setConfig] = useState([]);
  //console.log("user", user);

  const getDetails = () => {
    let obj = {
      device_type: "web",
      mobile_os: "null",
      app_version: "null",
    };
    StudentGoalService.getStudentHistoryData(obj).then((data) => {
      //console.log("history data", data);
    });
  };

  useEffect(() => {
    getDetails();
  }, []);

  useEffect(() => {
    if (location.state?.someValue) {
      setQueryTittle(location.state?.someValue);
    }
  }, [location]);

  const checkViewQuery = () => {
    //console.log("viewQuery", viewQueryData);
    if (viewQueryData && viewQueryData.length > 0) {
      let array = [];
      viewQueryData[0] &&
        viewQueryData?.map((item, index) => {
          //console.log("sale", item?.sfg);

          //   for (const [key, value] of Object.entries(item)) {
          //     array.push(value);
          //   }
          //   return array;
          // });
          // array.map((item) => {
          if (item.gender === "Male") {
            let selectedGender =
              genderData && genderData.filter((item) => item.value === "male");
            handleGenderCheckbox(selectedGender[0]);
          } else if (item.gender === "Female") {
            let selectedGender =
              genderData &&
              genderData.filter((item) => item.value === "female");
            handleGenderCheckbox(selectedGender[0]);
          } else if (item.gender === "Non-Binary") {
            let selectedGender =
              genderData &&
              genderData.filter((item) => item.value === "unknown");
            handleGenderCheckbox(selectedGender[0]);
          }
          if (item?.state && item?.state?.length > 0) {
            item?.state?.map((v) =>
              handleCheckBox({ name: v, value: v }, "contains", "state")
            );
          }
          if (item?.ethnicity && item?.ethnicity?.length > 0) {
            item?.ethnicity?.map((v) =>
              handleCheckBox({ name: v, value: v }, "contains", "ethnicity")
            );
          }
          if (item?.sfg === true) {
            handleCheckBox(
              studentFirstGenerationValue,
              "contains",
              "student first generation"
            );
          }
          if (item?.status === true) {
            handleCheckBox(ucGreenRequirementValue, "contains", "uc_status");
          }
          if (item?.academics && item?.academics?.length > 0) {
            item.academics.map((v) =>
              handleCheckBox({ name: v, value: v }, "contains", "academics")
            );
          }
          if (item?.athletics && item?.athletics?.length > 0) {
            item.athletics.map((v) =>
              handleCheckBox({ name: v, value: v }, "contains", "athletics")
            );
          }
          if (item?.interests && item?.interests?.length > 0) {
            item.interests.map((v) =>
              handleCheckBox({ name: v, value: v }, "contains", "interests")
            );
          }
          if (item?.activities && item?.activities?.length > 0) {
            item.activities.map((v) =>
              handleCheckBox({ name: v, value: v }, "contains", "activities")
            );
          }
          if (item?.grade && item?.grade?.length > 0) {
            item.grade.map((v) => {
              handleCheckBox({ name: v, value: v }, "contains", "grade");
            });
          }
          if (item?.proficiency && item?.proficiency?.length > 0) {
            item.proficiency.map((v) => {
              handleCheckBox(
                { name: v, value: v },
                "contains",
                "subject matter proficiency"
              );
            });
          }

          if (item?.gpa) {
            setMinGPA(item?.gpa?.min);
            setMaxGPA(item?.gpa?.max);
          }
        });
    }
  };

  useLayoutEffect(() => {
    if (viewQueryData && viewQueryData.length > 0) {
      checkViewQuery();
    }
  }, []);

  const resetQueryCriteria = () => {
    window.location.reload();
    dispatch(changeQueryLocation({}));
  };

  const saveForSiembra = () => {
    setOpenConfirmationForSiembra(true);
    setOpenSaveQueryModal(false);
  };

  const onCloseAdvanceQuery = async (index) => {
    let v = noOfAdvanceQuery[index];
    let removeValue = noOfAdvanceQuery.filter((i) => i !== v);
    setNoOfAdvanceQuery(removeValue);
    configObject && configObject.splice(index, 1);
    setConfigObject(configObject);
  };

  const createConfig = (conf, operator = "contains", value) => {
    //console.log("value from the createConfig", value);
    if (
      configObject &&
      configObject.some(
        (element) => element && element.config.value === conf.value
      )
    ) {
      configObject.forEach(async (element) => {
        if (element.config.value === conf.value) {
          element.multi_value = value;
          // await setConfigObject(configObject)
        }
      });
    } else {
      if (conf && (value || value === "")) {
        let configObj = {
          fromServer: true,
          parentResource: null,
          reqParams: null,
          restangularCollection: false,
          route: "configure",
        };
        let array = [];
        let obj = {};
        obj.config = { ...conf, ...configObj };
        obj.multi_value =
          conf.value !== "overall gpa" ||
          conf.value !== "student first generation" ||
          conf.value !== "zip code" ||
          conf.value !== "uc_status"
            ? value
            : [];
        obj.level = 0;
        obj.margin = "margin";
        obj.operator =
          operator === "not" ? "not" : operator === null ? null : "contains";
        obj.query_exp_id = "";
        obj.range_value = conf.value === "overall gpa" ? [value] : [];
        obj.selected_item = [];
        obj.parent = [];
        obj.value =
          conf && conf.value === "student first generation"
            ? "student first generation"
            : conf?.value === "uc_status"
            ? "uc_status"
            : "";
        array.push(obj);
        setConfigObject((prevItems) => [...prevItems, ...array]);
      }
    }
  };

  const createConfigForGPAAdvance = (conf, operator = "contains", value) => {
    //console.log("conf from gpa", conf);
    //console.log("val", value);
    if (
      configObject &&
      configObject.some(
        (element) => element && element?.config?.value === conf?.value
      )
    ) {
      configObject.forEach(async (element) => {
        if (element.config.value === conf.value) {
          if (typeof value === "object") {
            element.range_value = value && [value];
            await setConfigObject(configObject);
          } else if (typeof value === "string") {
            element.value = value && value;
          }
        }
      });
    } else {
      if (conf && value) {
        let configObj = {
          fromServer: true,
          parentResource: null,
          reqParams: null,
          restangularCollection: false,
          route: "configure",
        };
        let array = [];
        let obj = {};
        obj.config = { ...configObj, ...conf };
        obj.multi_value = [];
        obj.level = 0;
        obj.margin = "margin";
        obj.operator = operator;
        obj.query_exp_id = "";
        obj.range_value = typeof value === "object" ? [value] : [];
        obj.selected_item = [];
        obj.parent = [];
        obj.value = typeof value === "string" ? value : [];
        array.push(obj);
        setConfigObject((prevItems) => [...prevItems, ...array]);
      }
    }
  };

  const clearAll = () => {
    setSelectedThirdParameter([]);
  };
  const createConfigForGender = (conf, operator, value, from) => {
    if (
      configObject &&
      configObject.some(
        (element) => element && element.config.value === conf.value
      ) &&
      from === "not-advance-query"
    ) {
      configObject.forEach(async (element) => {
        if (element.config.value === conf.value) {
          element.value =
            value && value.value === "male"
              ? "m"
              : value.value === "female"
              ? "f"
              : "unknown";
          await setConfigObject(configObject);
        }
      });
    } else {
      if (conf && value) {
        let configObj = {
          fromServer: true,
          parentResource: null,
          reqParams: null,
          restangularCollection: false,
          route: "configure",
        };
        let array = [];
        let obj = {};
        obj.config = { ...configObj, ...conf };
        obj.multi_value = [];
        obj.level = 0;
        obj.margin = "margin";
        obj.operator = operator;
        obj.parent = [];
        obj.query_exp_id = "";
        obj.range_value = [];
        obj.selected_item = [];
        obj.value =
          value && value.value === "male"
            ? "m"
            : value.value === "female"
            ? "f"
            : "unknown";
        array.push(obj);
        setConfigObject((prevItems) => [...prevItems, ...array]);
        localStorage.setItem("query", JSON.stringify(configObject));
      }
    }
  };

  const removeValue = async (value, config, selectedThirdParameter) => {
    if (value.hasOwnProperty("Selected") && value["Selected"] === true) {
      if (selectedQueries && selectedQueries.length > 0) {
        let removedValueFromFilter = selectedQueries.filter(
          (i) => i.query.name !== value.name
        );
        setSelectedQueries(removedValueFromFilter);
      }
    }
    value["Selected"] = false;
    if (selectedThirdParameter && selectedThirdParameter.length > 0) {
      selectedThirdParameter.forEach((element) => {
        if (element === value) {
          let index = selectedThirdParameter.indexOf(element);
          selectedThirdParameter.splice(index, 1);
          setSelectedThirdParameter(selectedThirdParameter);
        } else if (value.name === element.label && value.id === element.value) {
          let index = selectedThirdParameter.indexOf(element);
          selectedThirdParameter.splice(index, 1);
          setSelectedThirdParameter(selectedThirdParameter);
        }
      });
    }
    configObject &&
      configObject.map(async (element) => {
        if (element.config.value === "student first generation") {
          let index =
            configObject &&
            configObject.filter(
              (item) => item.config.value !== "student first generation"
            );
          ////console.log("index from the Student First Generation" , index);
          await setConfigObject(index);
        } else if (element.config.value === "uc_status") {
          let index =
            configObject &&
            configObject.filter((item) => item.config.value !== "uc_status");
          ////console.log("index from the Student First Generation" , index);
          await setConfigObject(index);
        } else if (element.config.value && config.value === "state") {
          let index = selectedStateQueries.indexOf(value);
          selectedStateQueries.splice(index, 1);
          setSelectedStateQueries(selectedStateQueries);
        } else if (element.config.value && config.value === "ethnicity") {
          let index = selectedEthnicityQueries.indexOf(value);
          selectedEthnicityQueries.splice(index, 1);
          setSelectedEthnicityQueries(selectedEthnicityQueries);
        } else if (element.config.value && config.value === "grade") {
          let index = selectedGradeQueries.findIndex(
            (item) => item.value === value.value
          );
          selectedGradeQueries.splice(index, 1);
          setSelectedGradeQueries(selectedGradeQueries);
        } else if (element.config.value && config.value === "athletics") {
          let index = selectedAthleticsQueries.findIndex(
            (item) => item.id === value.id
          );
          selectedAthleticsQueries.splice(index, 1);
          setSelectedAthleticsQueries(selectedAthleticsQueries);
        } else if (element.config.value && config.value === "academics") {
          let index = selectedAcademicsQueries.findIndex(
            (item) => item.id === value.id
          );
          selectedAcademicsQueries.splice(index, 1);
          setSelectedAcademicsQueries(selectedAcademicsQueries);
        } else if (element.config.value && config.value === "activities") {
          let index = selectedActivitiesQueries.findIndex(
            (item) => item.id === value.id
          );
          selectedActivitiesQueries.splice(index, 1);
          setSelectedActivitiesQueries(selectedActivitiesQueries);
        } else if (element.config.value && config.value === "interests") {
          let index = selectedInterestsQueries.findIndex(
            (item) => item.id === value.id
          );
          selectedInterestsQueries.splice(index, 1);
          setSelectedInterestsQueries(selectedInterestsQueries);
        }
        if (
          config.value !== "grade" &&
          config.value !== "state" &&
          config.value === element.config.value &&
          element.multi_value.length > 0
        ) {
          element.multi_value.splice(
            element.multi_value.findIndex(
              (subelement) => subelement.id === value.id
            ),
            1
          );
        }
        if (config.value === "grade") {
          if (
            config.value === element.config.value &&
            element.multi_value.length > 0
          ) {
            let index = element.multi_value.filter(
              (item) => item.value !== value.value
            );
            element.multi_value = index;
          }
        }
        if (config.value === "state") {
          if (
            config.value === element.config.value &&
            element.multi_value.length > 0
          ) {
            let index = element.multi_value.filter(
              (item) => item.code !== value.code && item.Selected === true
            );
            element.multi_value = index;
          }
        }

        if (
          element.config.value !== "gender" &&
          element.multi_value.length === 0
        ) {
          let index = configObject.indexOf(element);
          configObject.splice(index, 1);
        }
      });
    await setConfigObject(configObject);
  };

  let selected;
  const handleCheckBox = async (value, operator = "contains", type) => {
    //console.log("from the handle check box", value, operator, type);
    selected = value;
    if (type === "grade" && value.hasOwnProperty("label")) {
      let modifiedObjForGrade = {
        value: value?.value,
        name: value?.label,
      };
      value = modifiedObjForGrade;
    }
    let originalElement = {};
    let finalValue = {};
    let objectValue = {};
    let multiValue = [];
    let selectedQueryFromView = [];
    if (
      !selected.hasOwnProperty("Selected") ||
      selected["Selected"] === false
    ) {
      objectValue = {
        query: selected,
        type: type,
      };
      selectedQueryFromView.push(objectValue);
      await setSelectedQueries((prevState) => [
        ...prevState,
        ...selectedQueryFromView,
      ]);
    }
    if (selected.hasOwnProperty("Selected") && selected["Selected"] === true) {
      if (selectedQueries && selectedQueries.length > 0) {
        let removedValueFromFilter = selectedQueries.filter(
          (i) => i.query.name !== selected.name
        );
        setSelectedQueries(removedValueFromFilter);
      }
    }
    if (
      type === "interests" ||
      type === "academics" ||
      type === "activities" ||
      type === "athletics"
    ) {
      selected.id = selected.id || selected.value || selected.name;
      selected.name = selected.label || selected.name;
      if (selected.Selected === true) {
        selected.Selected = true;
      }
      originalElement = selected;
      finalValue = { ...selected, originalElement };
    }
    if (type === "state") {
      let conf = config && config.filter((item) => item.value === "state");
      if (
        !selected.hasOwnProperty("Selected") ||
        selected["Selected"] === false
      ) {
        selected["Selected"] = true;
        multiValue.push(value);
        await setSelectedStateQueries([...selectedStateQueries, ...multiValue]);
        createConfig(conf, operator, [...selectedStateQueries, ...multiValue]);
      } else {
        removeValue(value, conf, selectedStateQueries);
      }
    } else if (type === "ethnicity") {
      let conf = config && config.filter((item) => item.value === "ethnicity");
      if (
        !selected.hasOwnProperty("Selected") ||
        selected["Selected"] === false
      ) {
        selected["Selected"] = true;
        multiValue.push(selected);
        setSelectedEthnicityQueries([
          ...selectedEthnicityQueries,
          ...multiValue,
        ]);
        createConfig(conf && conf, operator, [
          ...selectedEthnicityQueries,
          ...multiValue,
        ]);
      } else {
        removeValue(selected, conf, selectedEthnicityQueries);
      }
    } else if (type === "grade") {
      let conf = config && config.filter((item) => item.value === "grade");
      if (
        !selected.hasOwnProperty("Selected") ||
        selected["Selected"] === false
      ) {
        selected["Selected"] = true;
        multiValue.push(selected);
        setSelectedGradeQueries([...selectedGradeQueries, ...multiValue]);
        createConfig(conf, operator, [...selectedGradeQueries, ...multiValue]);
      } else {
        removeValue(selected, conf, selectedGradeQueries);
      }
    } else if (type === "academics") {
      let conf = config && config.filter((item) => item.value === "academics");
      if (
        !selected.hasOwnProperty("Selected") ||
        selected["Selected"] === false
      ) {
        selected["Selected"] = true;
        multiValue.push(selected);
        setSelectedAcademicsQueries([
          ...selectedAcademicsQueries,
          ...multiValue,
        ]);
        createConfig(conf, operator, [
          ...selectedAcademicsQueries,
          ...multiValue,
        ]);
      } else {
        removeValue(selected, conf, selectedAcademicsQueries);
      }
    } else if (type === "activities") {
      let conf = config && config.filter((item) => item.value === "activities");
      if (
        !selected.hasOwnProperty("Selected") ||
        selected["Selected"] === false
      ) {
        selected["Selected"] = true;
        multiValue.push(selected);
        setSelectedActivitiesQueries([
          ...selectedActivitiesQueries,
          ...multiValue,
        ]);
        createConfig(conf, operator, [
          ...selectedActivitiesQueries,
          ...multiValue,
        ]);
      } else {
        removeValue(selected, conf, selectedActivitiesQueries);
      }
    } else if (type === "athletics") {
      let conf = config && config.filter((item) => item.value === "athletics");
      if (
        !selected.hasOwnProperty("Selected") ||
        selected["Selected"] === false
      ) {
        selected["Selected"] = true;
        multiValue.push(selected);
        setSelectedAthleticsQueries([
          ...selectedAthleticsQueries,
          ...multiValue,
        ]);
        createConfig(conf, operator, [
          ...selectedAthleticsQueries,
          ...multiValue,
        ]);
      } else {
        removeValue(selected, conf, selectedAthleticsQueries);
      }
    } else if (type === "interests") {
      let conf = config && config.filter((item) => item.value === "interests");
      if (
        !selected.hasOwnProperty("Selected") ||
        selected["Selected"] === false
      ) {
        selected["Selected"] = true;
        multiValue.push(selected);
        setSelectedInterestsQueries([
          ...selectedInterestsQueries,
          ...multiValue,
        ]);
        createConfig(conf, operator, [
          ...selectedInterestsQueries,
          ...multiValue,
        ]);
      } else {
        removeValue(selected, conf, selectedInterestsQueries);
      }
    } else if (type === "subject matter proficiency") {
      let conf =
        config &&
        config.filter((item) => item.value === "subject matter proficiency");
      if (
        !selected.hasOwnProperty("Selected") ||
        selected["Selected"] === false
      ) {
        //console.log("selected", selected);
        selected["Selected"] = true;
        // selected["name"] = selected?.stem_name;
        // selected["stem_name"] = selected?.stem_name;
        multiValue.push(selected);
        //console.log("selected", selected);
        //console.log("multiValue", multiValue);

        setSelectedStemQueries([...selectedStemQueries, ...multiValue]);
        createConfig(conf, operator, [...selectedStemQueries, ...multiValue]);
      } else {
        removeValue(selected, conf, selectedStemQueries);
      }
    } else if (type === "student first generation") {
      let conf =
        config &&
        config.filter((item) => item.value === "student first generation");
      if (
        !selected.hasOwnProperty("Selected") ||
        selected["Selected"] === false
      ) {
        selected["Selected"] = true;
        await setStudentFirstGeneration(true);
        createConfig(conf, operator, studentFirstGenerationValue);
      } else {
        setStudentFirstGeneration(false);
        removeValue(
          studentFirstGenerationValue,
          conf,
          studentFirstGenerationValue
        );
      }
      // createConfig(conf[0], operator, "student first generation")
    } else if (type === "uc_status") {
      let conf = config && config.filter((item) => item.value === "uc_status");
      if (
        !selected.hasOwnProperty("Selected") ||
        selected["Selected"] === false
      ) {
        selected["Selected"] = true;
        await setUCGreenRequirement(true);
        createConfig(conf, operator, ucGreenRequirementValue);
      } else {
        setUCGreenRequirement(false);
        removeValue(ucGreenRequirementValue, conf, ucGreenRequirementValue);
      }
      // createConfig(conf[0], operator, "student first generation")
    } else if (type === "high school" && Array.isArray(selected) === true) {
      if (selected.length > 0) {
        let conf =
          config && config.filter((item) => item.value === "high school");
        createConfig(conf, operator, selected);
        setSchoolData(selected);
      }
    } else if (type === "gender" && selected) {
      if (selected.Selected === true) {
        removeValueForCheckBox(selected);
        return;
      }
      handleGenderCheckbox(selected, operator, "advance-query");
    } else if (type === "zip code") {
      let conf = config && config.filter((item) => item.value === "zip code");
      createConfig(conf, operator, "");
    }
  };

  const onShowMore = async (value) => {
    if (value === "ethnicity") {
      if (value === "ethnicity" && showEthData.length === 3) {
        await setShowEthData(ethnicity);
      } else {
        await setShowEthData(showEthData.slice(0, 3));
      }
    }
    if (value === "State") {
      if (value === "State" && showStateData.length === 3) {
        setShowStateData(stateOfCountry);
      } else {
        setShowStateData(stateOfCountry.slice(0, 3));
      }
    }
    if (value === "grade") {
      if (value === "grade" && grade.length === 3) {
        setGrade(gradeFromData);
      } else {
        setGrade(gradeFromData.slice(0, 3));
      }
    }
    if (value === "academics") {
      if (value === "academics" && showAcademicsData.length === 3) {
        setShowAcademicsData(academicsData);
      } else {
        setShowAcademicsData(academicsData.slice(0, 3));
      }
    }
    if (value === "activities") {
      if (value === "activities" && showActivitiesData.length === 3) {
        setShowActivitiesData(activitiesData);
      } else {
        setShowActivitiesData(activitiesData.slice(0, 3));
      }
    }
    if (value === "athletics") {
      if (value === "athletics" && showAthleticsData.length === 3) {
        setShowAthleticsData(athleticsData);
      } else {
        setShowAthleticsData(athleticsData.slice(0, 3));
      }
    }
    if (value === "interests") {
      if (value === "interests" && showInterestData.length === 3) {
        setShowInterestData(interestData);
      } else {
        setShowInterestData(interestData.slice(0, 3));
      }
    }
  };

  // const getAcademics = () => {
  //   const body = {
  //     list_name: "academics",
  //   };
  //   setLoading(true);
  //   QueryService.getAcademics(body)
  //     .then((data) => {
  //       setLoading(false);

  //     })
  //     .catch((error) => {
  //       setLoading(false);
  //       //console.log("error frm the service", error);
  //     });
  // };

  // const getStem = () => {
  //   const body = {
  //     list_name: "stem",
  //   };
  //   setLoading(true);
  //   QueryService.getStemData(body)
  //     .then((data) => {
  //       setLoading(false);

  //     })
  //     .catch((error) => {
  //       setLoading(false);
  //       //console.log("error frm the service", error);
  //     });
  // };

  // const getActivities = () => {
  //   const body = {
  //     list_name: "activities",
  //   };
  //   setLoading(true);
  //   QueryService.getActivities(body)
  //     .then((data) => {
  //       setLoading(false);

  //     })
  //     .catch((error) => {
  //       setLoading(false);
  //       //console.log("error frm the service", error);
  //     });
  // };

  // const getAthletics = () => {
  //   const body = {
  //     list_name: "athletics",
  //   };
  //   setLoading(true);
  //   QueryService.getAthletics(body)
  //     .then((data) => {
  //       setLoading(false);

  //     })
  //     .catch((error) => {
  //       setLoading(false);
  //       //console.log("error frm the service", error);
  //     });
  // };

  // const getInterest = () => {
  //   const body = {
  //     list_name: "intrest",
  //   };
  //   setLoading(true);
  //   QueryService.getInterest(body)
  //     .then((data) => {
  //       setLoading(false);

  //     })
  //     .catch((error) => {
  //       setLoading(false);
  //       //console.log("error frm the service", error);
  //     });
  // };

  const getSchoolData = () => {
    setLoading(true);
    QueryService.getLatestConfig()
      .then((data) => {
        setLoading(false);
        let AllAcademics = data?.data?.object?.academics;
        let AllEthnicities = data?.data?.object?.ethnicity;
        let AllActivities = data?.data?.object?.activities;
        let AllAthletics = data?.data?.object?.athletics;
        let AllIntrest = data?.data?.object?.intrest;
        let AllProficiency = data?.data?.object?.steam;

        let AllCriteriamanual = data?.data?.objects?.query_criteriamanual;
        let AllCriteria = data?.data?.objects?.query_criteria;

        setEthnicityData(AllEthnicities);
        setShowEthData(AllEthnicities.slice(0, 3));
        setInterestData(AllIntrest);
        setShowInterestData(AllIntrest.slice(0, 3));
        setAthleticsData(AllAthletics);
        setShowAthleticsData(AllAthletics.slice(0, 3));
        setActivitiesData(AllActivities);
        setShowActivitiesData(AllActivities.slice(0, 3));
        setAcademicsData(AllAcademics);
        setShowAcademicsData(AllAcademics.slice(0, 3));
        setStemData(AllProficiency);

        setConfig(AllCriteriamanual);
      })
      .catch((error) => {
        setLoading(false);
        //console.log("error from the service", error);
      });
  };
  // //console.log(ethnicity);
  // //console.log("dataeth",showEthData);
  // const getConfig = () => {
  //   QueryService.getConfigData()
  //     .then((data) => {
  //       //console.log("data from the config service", data);

  //     })
  //     .catch((error) => {
  //       //console.log("error from the service", error);
  //     });
  // };

  useEffect(() => {
    getSchoolData();
    // getAcademics();
    // getActivities();
    // getAthletics();
    // getInterest();
    // getStem();
    // getConfig();
    // return () => {
    //   setViewQueryData([]);
    // };
  }, []);

  // //console.log("gg",stateOfCountry.map((state) => state.name));

  let stateValue;
  const stateVal = () => {
    stateValue = selectedStateQueries.map((item) => item.name);
  };
  if (selectedStateQueries.length > 0) {
    stateVal();
  }

  let ethnicityValue;
  const ethnicityVal = () => {
    ethnicityValue = selectedEthnicityQueries.map((item) => item.name);
  };
  if (selectedEthnicityQueries.length > 0) {
    ethnicityVal();
  }

  let gradeValue;
  const gradeVal = () => {
    gradeValue = selectedGradeQueries.map((item) => item.name);
  };
  if (selectedGradeQueries.length > 0) {
    gradeVal();
  }

  let academicsValue;
  const academicsVal = () => {
    academicsValue = selectedAcademicsQueries.map((item) => item.name);
  };
  if (selectedAcademicsQueries.length > 0) {
    academicsVal();
  }

  let activitesValue;
  const activitesVal = () => {
    activitesValue = selectedActivitiesQueries.map((item) => item.name);
  };
  if (selectedActivitiesQueries.length > 0) {
    activitesVal();
  }

  let athleticsValue;
  const athleticsVal = () => {
    athleticsValue = selectedAthleticsQueries.map((item) => item.name);
  };
  if (selectedAthleticsQueries.length > 0) {
    athleticsVal();
  }

  let interestsValue;
  const interestsVal = () => {
    interestsValue = selectedInterestsQueries.map((item) => item.name);
  };
  if (selectedInterestsQueries.length > 0) {
    interestsVal();
  }

  let smpValue;
  const smpVal = () => {
    smpValue = selectedStemQueries.map((item) => item.name);
  };
  if (selectedStemQueries.length > 0) {
    smpVal();
  }

  let gpaVal;
  if (minGPA !== 0 || maxGPA !== 4) {
    gpaVal = { min: minGPA, max: maxGPA };
  } else if (minGPA > 0 || maxGPA > 4) {
    gpaVal = { min: minGPA, max: maxGPA };
  }

  let overQuery = {
    gender: genderValue && genderValue?.send,
    // gender: [genderValue && genderValue?.send]),
    state: stateValue,
    ethnicity: ethnicityValue,
    gpa: gpaVal,
    grade: gradeValue,
    academics: academicsValue,
    activities: activitesValue,
    athletics: athleticsValue,
    interests: interestsValue,
    proficiency: smpValue,
    // school:[]
  };
  if (studentFirstGeneration) {
    overQuery.sfg = studentFirstGeneration;
  }
  if (ucGreenRequirement) {
    overQuery.status = ucGreenRequirement;
  }
  if (schoolData) {
    let schoolIds = schoolData?.map((item) => item.id);
    overQuery.school_id = schoolIds;
  }

  const getQueryResult = () => {
    setLoading(true);
    localStorage.setItem("query", JSON.stringify(overQuery));
    history.push("/recruiter/query-result");
  };

  useEffect(() => {
    async function getSelectedQueries() {
      if (genderValue) {
        let query = {};
        query["type"] = "gender";
        query["name"] = genderValue && genderValue.name;
        let obj = {};
        obj.type = "gender";
        obj.query = query;
        await setSelectedQueries([...selectedQueries, obj]);
      }
    }

    getSelectedQueries();
  }, [genderValue]);

  const removeValueForCheckBox = async (item) => {
    //console.log("item frim the remoce", item);
    setGenderValue();
    let filteredValue = selectedQueries && selectedQueries.indexOf(item);
    selectedQueries.splice(filteredValue, 1);
    setSelectedQueries(selectedQueries);
    configObject &&
      configObject.forEach((element) => {
        if (
          element.value === "m" ||
          element.value === "f" ||
          element.value === "u"
        ) {
          let index = configObject.indexOf(element);
          configObject.splice(index, 1);
        }
      });
    await setConfigObject(configObject);
  };

  const handleGenderCheckbox = async (
    value,
    operator = "contains",
    from = "not-advance-query"
  ) => {
    let conf = config && config.filter((item) => item.value === "gender");
    let filteredValue =
      selectedQueries.length > 0
        ? selectedQueries.filter((item) => {
            if (item.query.name === "Male") {
              let i = selectedQueries.indexOf(item);
              selectedQueries.splice(i, 1);
            } else if (item.query.name === "Female") {
              let i = selectedQueries.indexOf(item);
              selectedQueries.splice(i, 1);
            } else if (item.query.name === "Non-Binary") {
              let i = selectedQueries.indexOf(item);
              selectedQueries.splice(i, 1);
            }
          })
        : [];
    setSelectedQueries([...selectedQueries, ...filteredValue]);
    if (value.value === "male") {
      setGenderValue(value);
      createConfigForGender(conf, operator, value, from);
    } else if (value.value === "female") {
      await setGenderValue(value);
      createConfigForGender(conf, operator, value, from);
    } else {
      await setGenderValue(value);
      createConfigForGender(conf, operator, value, from);
    }
  };

  const handleGPASlider = async (value) => {
    let minValue = value[0];
    await setMinGPA(minValue);
    let maxValue = value[1];
    await setMaxGPA(maxValue);
  };

  useEffect(() => {
    if (minGPA !== 0 || maxGPA !== 4) {
      createConfigForGPA();
    }
  }, [minGPA, maxGPA]);

  const createConfigForGPA = (min = minGPA, max = maxGPA) => {
    //console.log("minGPA", min);
    //console.log("maxGPA", max);
    let configggg =
      config && config.filter((element) => element.value === "overall gpa");
    let obj = {
      minValue: min,
      maxValue: max,
    };
    //console.log("obj", obj);
    createConfigForGPAAdvance(configggg && configggg[0], "contains", obj);
  };

  const createConfigForGPAAdvanceQuery = (operator, value) => {
    let configggg =
      config && config.filter((element) => element.value === "overall gpa");
    createConfigForGPAAdvance(configggg, operator, value);
  };

  const openAdvanceQuery = () => {
    setOpenAdvanceQuerySection(!openAdvanceQuerySection);
  };

  const addMoreAdvanceQuery = () => {
    setAdvanceQueryCount(advanceQueryCount + 1);
    setNoOfAdvanceQuery((prevState) => [...prevState, advanceQueryCount]);
  };

  const selectTab = (value) => {
    if (value === "customQuery") {
      setSelectedTab(value);
      history.push("/recruiter/search-query");
    } else if (value === "schoolQuery") {
      localStorage.removeItem("query");
      setSelectedTab(value);
      history.push("/recruiter/school-query");
    } else if (value === "savedQuery") {
      localStorage.removeItem("query");
      setSelectedTab(value);
      history.push("/recruiter/saved-query");
    } else if (value === "collegeQuery") {
      localStorage.removeItem("query");
      setSelectedTab(value);
      history.push("/recruiter/college-query");
    }
  };

  const saveQuery = () => {
    setOpenSaveQueryModal(true);
  };

  const editQuery = () => {
    //console.log("print");
  };

  const clear = () => {
    setConfigObject([]);
    setSelectedQueries([]);
    setGenderValue();
  };

  const dis = viewQueryData?.some((item) => Object.keys(item).length > 0);

  return (
    <Fragment>
      <AppHeader />
      <div className="app-main">
        <AppSidebar />
        <div className="app-main__outer">
          <div>
            <div className="name_message_header">
              <p>
                Hello {user ? `${user?.first_name} ${user?.last_name}!!` : ""}
              </p>
            </div>
            <div className="query-component-container scrollBar_hidden">
              <div className="tab-grid">
                <div
                  onClick={() => {
                    dispatch(changeQueryLocation({}));
                    selectTab("customQuery");
                  }}
                  style={{ width: "100%" }}
                  className={"tab-button cursor active-tab"}
                >
                  <p className="no-margin-bottom">H/S Student Query</p>
                </div>
                <div
                  onClick={() => {
                    dispatch(changeQueryLocation({}));
                    selectTab("schoolQuery");
                  }}
                  style={{ width: "100%" }}
                  className={"tab-button cursor inactive-tab"}
                >
                  <p className="no-margin-bottom">Saved Queries</p>
                </div>
                {/* <div onClick={() => selectTab('savedQuery')} style={{ width: '100%' }} className={'tab-button cursor inactive-tab'}><p className="no-margin-bottom">Saved Queried Results</p></div>
                        <div onClick={() => {}} style={{ width: '100%' }} className={'tab-button cursor inactive-tab'}><p className="no-margin-bottom">Query Columns</p></div> */}
                <div
                  onClick={() => {
                    dispatch(changeQueryLocation({}));
                    selectTab("collegeQuery");
                  }}
                  style={{ width: "100%" }}
                  className={"tab-button cursor inactive-tab"}
                >
                  <p className="no-margin-bottom">C/C Student Query</p>
                </div>
              </div>
              {loading ? (
                <CustomLoader />
              ) : (
                <div
                  className="scrollBar_hidden"
                  style={{
                    border: "2px solid var(--message-time-color)",
                    height: "100%",
                    background: "white",
                    marginLeft: "2px",
                    padding: "0px 20px",
                  }}
                >
                  <div className="top-border" />
                  <div className="display-selected-query">
                    <p
                      className="no-margin-bottom bold"
                      style={{ textAlign: "center" }}
                    >
                      {queryTittle ? "Query Name - " + queryTittle : ""}
                    </p>
                    <p className="no-margin-bottom bold">SET QUERY CRITERIA</p>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: "5px",
                      }}
                    >
                      <p className="no-margin-bottom">Choosen Filter:</p>
                      <div onClick={() => clear()} className="clear-button">
                        Clear All
                      </div>
                      {!openAdvanceQuerySection && (
                        <div
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            margin: "0px 5px",
                          }}
                        >
                          {selectedQueries &&
                            selectedQueries.map((item) => {
                              return (
                                <div
                                  className="flex"
                                  style={{
                                    margin: "0 15px",
                                    position: "relative",
                                  }}
                                >
                                  {item &&
                                    item.query &&
                                    (Array.isArray(item.query)
                                      ? item.query.length > 0
                                      : item.query) && (
                                      <div
                                        className="cursor"
                                        onClick={
                                          item.query.type === "gender"
                                            ? () => removeValueForCheckBox(item)
                                            : () =>
                                                handleCheckBox(
                                                  item.query,
                                                  "",
                                                  item.type
                                                )
                                        }
                                      >
                                        <CloseOutlined className="cancel-icon" />
                                      </div>
                                    )}
                                  <p
                                    style={{
                                      padding: "0 5px 0 10px",
                                      background:
                                        "var(--selected-primary-button-color)",
                                      color: "var(--input-label-color)",
                                      borderTopRightRadius: "15px",
                                      borderBottomRightRadius: "15px",
                                    }}
                                    className="no-margin-bottom"
                                  >
                                    {item.query.name || item.query.stem_name}
                                  </p>
                                </div>
                              );
                            })}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="bottom-border" />
                  <div style={{ margin: "15px 0px" }}>
                    <p
                      style={{
                        fontWeight: "bold",
                        fontSize: "14px",
                        color: "#333333",
                      }}
                    >
                      Set Criteria of Student Search
                    </p>
                    <div
                      style={{ marginTop: "25px" }}
                      className="col-md-12 row"
                    >
                      <div className="col-md-4">
                        <div className="query-title-containers ">
                          <p style={{ marginBottom: "0px" }} className="bold">
                            Gender
                          </p>
                        </div>
                        <div className="gender-value-container">
                          {genderData &&
                            genderData.map((item, index) => {
                              return (
                                <div key={index} className="flex">
                                  <input
                                    type="checkbox"
                                    name={item.value}
                                    onChange={() => handleGenderCheckbox(item)}
                                    value={genderValue && genderValue.value}
                                    checked={
                                      genderValue &&
                                      genderValue.value === item.value
                                    }
                                  />
                                  <p
                                    style={{
                                      marginLeft: "10px",
                                      fontSize: "14px",
                                    }}
                                    className="no-margin-bottom"
                                  >
                                    {item.name}
                                  </p>
                                </div>
                              );
                            })}
                        </div>
                      </div>
                      <div className="col-md-4 ">
                        <SingleQuery
                          data={showStateData}
                          name="state"
                          config={config}
                          valueWhichExpand={valueWhichExpand}
                          handleCheckBox={(value, type) =>
                            handleCheckBox(value, "contains", type)
                          }
                          onShowMore={() => onShowMore("State")}
                          selectedQueries={selectedQueries && selectedQueries}
                        />
                      </div>
                      <div className="col-md-4">
                        <SingleQuery
                          loading={loading}
                          data={showEthData}
                          config={config}
                          name="ethnicity"
                          valueWhichExpand={valueWhichExpand}
                          onShowMore={() => onShowMore("ethnicity")}
                          handleCheckBox={(value, type) =>
                            handleCheckBox(value, "contains", type)
                          }
                          selectedQueries={selectedQueries && selectedQueries}
                        />
                      </div>
                    </div>
                    <div>
                      <GPASlider
                        handleGPASlider={handleGPASlider}
                        minGPA={minGPA}
                        maxGPA={maxGPA}
                      />
                    </div>
                    <div
                      style={{ marginTop: "25px" }}
                      className="col-md-12 row"
                    >
                      <div className="col-md-4">
                        <SingleQuery
                          data={grade}
                          name="grade"
                          config={config}
                          valueWhichExpand={valueWhichExpand}
                          onShowMore={() => onShowMore("grade")}
                          handleCheckBox={(value, type) =>
                            handleCheckBox(value, "contains", type)
                          }
                          selectedQueries={selectedQueries && selectedQueries}
                        />
                      </div>
                      <div className="col-md-4 ">
                        <SingleQuery
                          loading={loading}
                          data={showAcademicsData}
                          name="academics"
                          config={config}
                          valueWhichExpand={valueWhichExpand}
                          onShowMore={() => onShowMore("academics")}
                          handleCheckBox={(value, type) =>
                            handleCheckBox(value, "contains", type)
                          }
                          selectedQueries={selectedQueries && selectedQueries}
                        />
                      </div>
                      <div className="col-md-4">
                        <SingleQuery
                          loading={loading}
                          data={showActivitiesData}
                          name="activities"
                          config={config}
                          valueWhichExpand={valueWhichExpand}
                          onShowMore={() => onShowMore("activities")}
                          handleCheckBox={(value, type) =>
                            handleCheckBox(value, "contains", type)
                          }
                          selectedQueries={selectedQueries && selectedQueries}
                        />
                      </div>
                    </div>
                    <div
                      style={{ marginTop: "25px" }}
                      className="col-md-12 row"
                    >
                      <div className="col-md-4">
                        <SingleQuery
                          loading={loading}
                          data={showAthleticsData}
                          name="athletics"
                          config={config}
                          valueWhichExpand={valueWhichExpand}
                          onShowMore={() => onShowMore("athletics")}
                          handleCheckBox={(value, type) =>
                            handleCheckBox(value, "contains", type)
                          }
                          selectedQueries={selectedQueries && selectedQueries}
                        />
                      </div>
                      <div className="col-md-4 ">
                        <SingleQuery
                          loading={loading}
                          data={showInterestData}
                          name="interests"
                          config={config}
                          valueWhichExpand={valueWhichExpand}
                          onShowMore={() => onShowMore("interests")}
                          handleCheckBox={(value, type) =>
                            handleCheckBox(value, "contains", type)
                          }
                          selectedQueries={selectedQueries && selectedQueries}
                        />
                      </div>
                      <div className="col-md-4">
                        <SingleQuery
                          loading={loading}
                          data={stemData}
                          name="subject matter proficiency"
                          config={config}
                          valueWhichExpand={valueWhichExpand}
                          handleCheckBox={(value, type) =>
                            handleCheckBox(value, "contains", type)
                          }
                          selectedQueries={selectedQueries && selectedQueries}
                        />
                      </div>
                    </div>
                    <div
                      className="flex"
                      style={{ justifyContent: "space-evenly" }}
                    >
                      <div
                        style={{
                          margin: "15px 0px",
                        }}
                        className="flex"
                      >
                        <input
                          type="checkbox"
                          checked={studentFirstGeneration}
                          onChange={() =>
                            handleCheckBox(
                              studentFirstGenerationValue,
                              "contains",
                              "student first generation"
                            )
                          }
                        />
                        <p
                          style={{ marginLeft: "10px", fontSize: "14px" }}
                          className="no-margin-bottom bold"
                        >
                          First Generation Student
                        </p>
                      </div>
                      <div
                        style={{
                          margin: "15px 0px",
                        }}
                        className="flex"
                      >
                        <input
                          type="checkbox"
                          checked={ucGreenRequirement}
                          onChange={() =>
                            handleCheckBox(
                              ucGreenRequirementValue,
                              "contains",
                              "uc_status"
                            )
                          }
                        />
                        <p
                          style={{ marginLeft: "10px", fontSize: "14px" }}
                          className="no-margin-bottom bold"
                        >
                          UC Minimum Green Students
                        </p>
                      </div>
                    </div>
                    <div
                      style={{ width: "max-content" }}
                      onClick={() => openAdvanceQuery()}
                      className="cursor"
                    >
                      <p className="bold advance-query-text">
                        + Add Advanced Query Criteria
                      </p>
                    </div>
                    {openAdvanceQuerySection && (
                      <button
                        onClick={() => addMoreAdvanceQuery()}
                        className="query-button font bold"
                      >
                        +Add Additional Criteria
                      </button>
                    )}
                    {openAdvanceQuerySection &&
                      noOfAdvanceQuery.map((item, index) => (
                        <div key={item}>
                          <AdvanceQueryCriteria
                            interestData={interestData}
                            activitiesData={activitiesData}
                            academicsData={academicsData}
                            athleticsData={athleticsData}
                            ethnicityData={ethnicity}
                            stemData={stemData}
                            config={config}
                            createConfig={createConfig}
                            handleCheckBox={handleCheckBox}
                            clearAll={clearAll}
                            selectedThirdParameter={selectedThirdParameter}
                            setSelectedThirdParameter={
                              setSelectedThirdParameter
                            }
                            item={item}
                            createConfigForGPAAdvanceQuery={
                              createConfigForGPAAdvanceQuery
                            }
                            onCloseAdvanceQuery={() =>
                              onCloseAdvanceQuery(index)
                            }
                          />
                        </div>
                      ))}
                    {openAdvanceQuerySection && (
                      <div
                        style={{ margin: "15px 0" }}
                        className="col-md-12 row"
                      >
                        <div className="col-md-4">
                          <button
                            onClick={() => getQueryResult()}
                            className="query-button font bold"
                          >
                            RUN ADVANCED QUERY
                          </button>
                        </div>

                        {/* <button
                            // onClick={() => (viewQueryData && viewQueryData?.length > 1 ? saveQuery() : editQuery() )}
                            onClick={()=> saveQuery()}
                            className="save-query-button font "
                            disabled={dis}
                            style={{opacity: dis ? 0.7 : '' }}
                          >
                            SAVE QUERY
                          </button> */}
                        {!dis && (
                          <div className="col-md-4">
                            <button
                              // onClick={() => (viewQueryData && viewQueryData?.length > 1 ? saveQuery() : editQuery())}
                              onClick={() => saveQuery()}
                              className="save-query-button font "
                              // disabled={dis}
                              // style={{opacity: dis ? 0.7 : '' }}
                            >
                              SAVE QUERY
                            </button>
                          </div>
                        )}
                        <div className="col-md-4">
                          <button
                            onClick={() => resetQueryCriteria()}
                            className="save-query-button font "
                          >
                            RESET QUERY CRITERIA
                          </button>
                        </div>
                      </div>
                    )}
                    <div
                      style={{
                        borderBottom: "3px solid black",
                        marginTop: "15px",
                      }}
                    />
                    {!openAdvanceQuerySection && (
                      <div
                        style={{ margin: "15px 0" }}
                        className="col-md-12 row"
                      >
                        <div className="col-md-4">
                          <button
                            onClick={() => getQueryResult()}
                            className="query-button font bold"
                            style={{ opacity: queryTittle ? 0.5 : 1 }}
                            disabled={queryTittle}
                          >
                            RUN QUERY
                          </button>
                        </div>
                        {/* <button
                            // onClick={() => (viewQueryData && viewQueryData?.length > 1 ? saveQuery() : editQuery())}
                            onClick={()=> saveQuery()}
                            className="save-query-button font "
                            disabled={dis}
                            style={{opacity: dis ? 0.7 : '' }}
                          >
                            SAVE QUERY
                          </button> */}
                        {!dis && (
                          <div className="col-md-4">
                            <button
                              // onClick={() => (viewQueryData && viewQueryData?.length > 1 ? saveQuery() : editQuery())}
                              onClick={() => saveQuery()}
                              className="save-query-button font "
                              // disabled={dis}
                              // style={{opacity: dis ? 0.7 : '' }}
                            >
                              SAVE QUERY
                            </button>
                          </div>
                        )}
                        <div className="col-md-4">
                          <button
                            onClick={() => resetQueryCriteria()}
                            className="save-query-button font "
                          >
                            RESET QUERY CRITERIA
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
          {openSaveQueryModal && (
            <SaveModal
              openSaveModal={openSaveQueryModal}
              setOpenSaveModal={setOpenSaveQueryModal}
              from="query-page"
              saveForCollegeQuery={overQuery && overQuery}
              saveForSiembra={saveForSiembra}
              inputValueForSave={inputValueForSave}
              setInputValueForSave={setInputValueForSave}
            />
          )}
          {openConfirmationForSiembra && (
            <ConfirmationDialog
              isOpen={openConfirmationForSiembra}
              setIsOpen={setOpenConfirmationForSiembra}
              query={configObject && configObject}
              inputValue={inputValueForSave}
              openErrorModal={openExistingErrorModal}
              setQuery={setConfigObject}
              setOpenErrorModal={setOpenExistingErrorModal}
              setInputValue={setInputValueForSave}
            />
          )}
          {openExistingErrorModal && (
            <ErrorModal
              openErrorModal={openExistingErrorModal}
              setOpenErrorModal={setOpenExistingErrorModal}
            />
          )}
        </div>
      </div>
    </Fragment>
  );
};
export default QueryComponent;
