import React, { Fragment, useEffect, useState, useRef } from "react";
import {
  faImage,
  faPaperclip,
  faSmile,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import MsgDataService from "../../services/MsgService";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import Check from "@mui/icons-material/Check";
import Clear from "@material-ui/icons/Clear";
import { useSelector } from "react-redux";
const API_URL = process.env.REACT_APP_API_URL;
const IMG_URL = process.env.REACT_APP_IMAGE_ASSET_URL;

const SingleMessage = ({
  msgData,
  isStudentOrCounselor,
  setShowSingleMessageComponent,
  conversition,
  getMsgData,
}) => {
  //console.log("msgData from the single message", msgData);
  const ref = useRef();
  const [msg, setMsg] = useState([msgData]);
  const [msgValue, setMsgValue] = useState();
  const [selectedfile, setSelectedFile] = useState();
  const [fileDataFromApi, setFileDataFromApi] = useState();
  const [selected, setSelected] = useState();

  const messageInputHandler = (e) => {
    setMsgValue(e.target.value);
  };

  // const handleFileChange = async (e) => {
  //   await setSelectedFile(ref.current.files[0]);
  // };
  const handleFileChange = async (e) => {
    // setSelectedFile(ref.current.files[0]);
    await getAttachment();
  };

  const sendCopyToParents = () => {
    setSelected(!selected);
  };

  // const submitAttachment = async () => {
  //   MsgDataService.attachment(selectedfile)
  //     .then((data) => {
  //       //console.log("data from attachment", data);
  //       setFileDataFromApi(data.data);
  //       ref.current.value = "";
  //     })
  //     .catch((err) => //console.log(err));
  // };

  const token = useSelector((state) => state.UserReducer.userObj.token);

  const getAttachment = () => {
    let selectedfile = ref.current.files[0];
    //console.log("selectedFile", selectedfile);
    const formData = new FormData();
    formData.append("file", selectedfile);
    // formData.append("is_message", true);
    fetch(`${API_URL}/api/recruiter/message_attachment/`, {
      method: "POST",
      body: formData,
      headers: {
        "x-authorization": `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        //console.log("data from attachment", data);
        setFileDataFromApi(data);
        ref.current.value = "";
      })
      .catch((error) => {
        //console.log("error from the error", error);
      });
  };

  const deleteAttachment = () => {
    let body ={
      file:fileDataFromApi?.Status?.image_name
    }
    MsgDataService.deleteattachment(body)
      .then((data) => {
        setFileDataFromApi();
      })
      .catch((error) => 
        console.log("error from the service", error));
  };

  // useEffect(() => {
  //     submitAttachment()
  // }, [selectedfile])

  const sendMessage = (e) => {
    if (msgValue === "" || msgValue.trim() === "" || msgValue === "undefined") {
      e.preventDefault();
    }
    else{
    let obj = {
      // answers: [],
      message_body: msgValue,
      dst_id: [msgData?.src_id],
      event_type:
      isStudentOrCounselor === "counselors"
      ? "recruiter_counselor"
      : isStudentOrCounselor === "students"
      ? "recruiter_student"
      : isStudentOrCounselor === "international-students"
      ? "international_student"
      : null,
      // student: [msgData && msgData && msgData.student.id],
    };
    if(isStudentOrCounselor === "international-students"){
      obj.is_international = true;
    }
    if (selected) {
      obj.parent_ack = selected;
    }
    //console.log("fileData",fileDataFromApi);
    if (fileDataFromApi) {
      obj.attachment_url= fileDataFromApi?.Status?.image_name;
    }
    MsgDataService.sendMessage(obj)
      .then((data) => {
        // setMsg()
        setSelected(false);
        setShowSingleMessageComponent(false);
        setMsgValue();
        getMsgData();
      })
      .catch((error) => {
        //console.log("error from the service", error);
      });
  }}

 

  // const getMessageData = (item) => {
  //   return item && item.body;
  // };

  // const getMessageDate = (item) => {
  //   return moment(item && item.date_sent).format("ddd MMM Do YYYY, hh:mm:ss a");
  // };

  // const senderName = (item) => {
  //   let name;
  //   name = item && item.src_id && item.src_id.name;
  //   return name;
  // };

  useEffect(() => {
    // let msg = Object.values(msgData.messages);
    setMsg(msg);
    // getMessageData();
    // getMessageDate();
    // senderName();
  }, [msgData]);

  //console.log("msgData 88", conversition);

  const scrollContainerRef = useRef(null);

  useEffect(() => {
    scrollContainerRef?.current?.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  })

  return (
    <Fragment>
      <div className="single-messageData-container">
        <p
          style={{
            marginBottom: "0px",
            padding: "10px 15px",
            borderBottom: "2px solid var(--text-color)",
            fontWeight: "bold",
          }}
        >{`Conversation with ${
          msgData?.name}`}
        </p> 
        <div className="singlemessage">
          {conversition &&
            conversition.map((item, index) => {
              ////console.log("item from msg", item);
              return ((index === conversition.length - 1) ? 
              (
                <div
                  key={index}
                  ref={scrollContainerRef}
                  style={{ margin: "10px", background: "white" }}
                >
                  <div className="date-time-delete-container">
                    {/* <img src={TrashIcon} alt="Delete" />
                    <div
                      style={{
                        marginRight: "15px",
                        borderLeft: "1px solid var(--message-time-color)",
                      }}
                      className="separator"
                    ></div> */}
                    <p className="date">{moment(item.date_sent).format("ddd MMM Do YYYY, hh:mm:ss a")}</p>
                  </div>
                  <div className="single-message-wrapper">
                    <div style={{ margin: "0 30px" }}>
                      <p>{item.src_name}</p>
                      <div style={{ textAlign: "end" }}>
                        <span style={{ fontSize: "14px" }}>
                          {item.message_body}
                        </span>
                        <br />
                        <br />
                        {/* {//console.log("msg", msg?.attachment)} */}
                        {item?.attachment_url && (
                          <div>
                            <a
                              className="download-attachment-anchor"
                              target="_blank"
                              href={item?.attachment_url}
                            >
                              <span className="flex download-attachment">
                                <FontAwesomeIcon
                                  style={{ marginRight: "7px" }}
                                  icon={faDownload}
                                />
                                <p
                                  style={{ marginBottom: "0px" }}
                                  className="no-margin-bottom"
                                >
                                  {/* Download Attachment */}
                                  {item?.file_name}
                                </p>
                              </span>
                            </a>
                          </div>
                        )}
                      </div>
                    </div>
                    <div>
                      <img src={IMG_URL+"placeholder.jpg"} alt="placeholder" />
                    </div>
                  </div>
                </div>
              ) :
              (
                <div
                  key={index}
                  style={{ margin: "10px", background: "white" }}
                >
                  <div className="date-time-delete-container">
                    {/* <img src={TrashIcon} alt="Delete" />
                    <div
                      style={{
                        marginRight: "15px",
                        borderLeft: "1px solid var(--message-time-color)",
                      }}
                      className="separator"
                    ></div> */}
                    <p className="date">{moment(item.date_sent).format("ddd MMM Do YYYY, hh:mm:ss a")}</p>
                  </div>
                  <div className="single-message-wrapper">
                    <div style={{ margin: "0 30px" }}>
                      <p><p>{item.src_name}</p></p>
                      <div style={{ textAlign: "end" }}>
                        <span style={{ fontSize: "14px" }}>
                          {item.message_body}
                        </span>
                        <br />
                        <br />
                        {/* {//console.log("msg", msg?.attachment)} */}
                        {item?.attachment_url && (
                          <div>
                            <a
                              className="download-attachment-anchor"
                              target="_blank"
                              href={item?.attachment_url}
                            >
                              <span className="flex download-attachment">
                                <FontAwesomeIcon
                                  style={{ marginRight: "7px" }}
                                  icon={faDownload}
                                />
                                <p
                                  style={{ marginBottom: "0px" }}
                                  className="no-margin-bottom"
                                >
                                  {/* Download Attachment */}
                                  {item?.file_name}
                                </p>
                              </span>
                            </a>
                          </div>
                        )}
                      </div>
                    </div>
                    <div>
                      <img src={IMG_URL+"placeholder.jpg"} alt="placeholder" />
                    </div>
                  </div>
                </div>
              ))
            })}
        </div>
        <div className="choose-template-container">
          <p>Choose a Scheduled Message Template</p>
        </div>
        <textarea
          rows="6"
          cols="30"
          className="textAreaInput"
          placeholder="Or type your message..."
          onChange={messageInputHandler}
          value={msgValue}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "15px",
          }}
          className="reply_container"
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ cursor: "pointer" }}>
              <FontAwesomeIcon
                icon={faImage}
                color="var(--input-label-color)"
              />
            </div>
            <div style={{ marginLeft: "10px", cursor: "pointer" }}>
              <FontAwesomeIcon icon={faPaperclip} />
            </div>
            <div style={{ marginLeft: "10px", cursor: "pointer" }}>
              <FontAwesomeIcon icon={faSmile} />
            </div>&emsp;
            {/* {msgData.event_type === "recruiter_student" ? ( 
              <div>
                <div
                  style={{
                    marginLeft: "10px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <div
                    onClick={sendCopyToParents}
                    style={{ cursor: "pointer" }}
                  >
                    {selected ? (
                      <Check className={selected ? "check" : ""} />
                    ) : (
                      <CheckBoxOutlineBlankIcon
                        className={!msgValue ? "" : "check"}
                      />
                    )}
                  </div>
                  <span
                    onClick={sendCopyToParents}
                    style={{ marginLeft: "10px", cursor: "pointer" }}
                  >
                    <button
                      disabled={!msgValue}
                      className="sendmessage"
                      style={{
                        color: !msgValue ? "var(--input-label-color)" : "white",
                      }}
                    >
                      Send Copy to Parents&nbsp;&nbsp;
                    </button>
                  </span>
                </div>
              </div>
             )  : null} */}
            <div>
              <input type="file" ref={ref} onChange={handleFileChange} />
            </div>
            {fileDataFromApi && (
              <div style={{ display: "flex", alignItems: "center" }}>
                <p style={{ color: "#008000", marginBottom: "0px" }}>
                  {fileDataFromApi && fileDataFromApi?.Status?.filename.slice(0,15)}
                </p>
                <div onClick={deleteAttachment} style={{ cursor: "pointer" }}>
                  <Clear className="cancelAttachment" />
                </div>
              </div>
            )}
          </div>
          <button
            onClick={sendMessage}
            className="sendmessage"
            style={{ color: !msgValue ? "var(--input-label-color)" : "white" }}
            disabled={!msg}
          >
            SEND MESSAGE
          </button>
        </div>
      </div>
    </Fragment>
  );
};
export default SingleMessage;
