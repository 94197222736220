import React, { Fragment, useState, useEffect, useLayoutEffect } from "react";
import CustomLoader from "../../../components/customLoader/CustomLoader";
import SingleQuery from "../../query/singlequery/SingleQuery";
import QueryService from "../../../services/QueryService";
import { useHistory, useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import GPASlider from "../../query/gpa_slider/GPA_SLIDER.js";
// import AdvanceQueryCriteria from './AdvanceQueryCriteria';
import CloseOutlined from "@mui/icons-material/CloseOutlined";
import ConfirmationDialog from "../../confirmationModalForSiembra/ConfirmationModalForSiembra.js";
import ErrorModal from "../../errorDisplayModal/ErrorDisplayModal.js";
// import DropDown from "../../collegequery/dropdown/DropDown";

// export const englishRequirementData = [
//   {
//     name: "Taken",
//     list_name: "english test",
//     value: "taken",
//   },
//   {
//     name: "Planned",
//     list_name: "english test",
//     value: "planned",
//   },
// ];

// export const studentLevelData = [
//   {
//     name: "Undergraduate",
//     list_name: "student level",
//     value: "undergraduate",
//   },
//   {
//     name: "Graduate",
//     list_name: "student level",
//     value: "graduate",
//   },
// ];

// export const countryData = [
//   {
//     name: "USA",
//     list_name: "country",
//     value: "usa",
//   },
//   {
//     name: "India",
//     list_name: "country",
//     value: "india",
//   },
//   {
//     name: "China",
//     list_name: "country",
//     value: "china",
//   },
//   {
//     name: "All",
//     list_name: "country",
//     value: "all",
//   }
// ];

// export const showBoardData = [
//   {
//     name: "Yes",
//     list_name: "board",
//     value: "yes",
//   },
//   {
//     name: "No",
//     list_name: "board",
//     value: "no",
//   },
// ]

// export const gender = [
//   {
//     name: "Female",
//     value: "female",
//   },
//   {
//     name: "Male",
//     value: "male",
//   },
//   {
//     name: "Non-Binary",
//     value: "unknown",
//   },
// ];

export const config = [];

const InternationalQuery = () => {
  // const englishRequirementFromData = _.cloneDeep(
  //   englishRequirementData
  // );
  // const studentLevData = _.cloneDeep(
  //  studentLevelData
  // );
  // const genderData = JSON.parse(JSON.stringify(gender));
  let authuser = localStorage.getItem("user");
  //let parsedUser = JSON.parse(authuser);
  let parsedUser = useSelector((state) => state.UserReducer.userObj);

  let history = useHistory();
  let location = useLocation();
  const queryData =
    location &&
    location.state &&
    location.state.state &&
    location.state.state.query
      ? location.state.state.query
      : [];
  const [inputValueForSave, setInputValueForSave] = useState("");
  const [selectedTab, setSelectedTab] = useState("customQuery");
  const [selectedQueries, setSelectedQueries] = useState([]);
  const [board, setBoardData] = useState();
  const [showBoardData, setShowBoardData] = useState();
  const [collegeData, setCollegeData] = useState();
  // const [countryData, setCountryData] = useState();
  // const [collageData, setCollageData] = useState();
  // const [showCollageData, setShowCollageData] = useState();
  //const [englishStatus, setEnglishStatus] = useState();
  //const [mathsStatus, setMathsStatus] = useState();
  const [valueWhichExpand, setValueWhichExpanded] = useState();
  const [englishMathRequirement, setEnglishMathRequirement] = useState(
    
  );
  // const [studentLevelReq, setStudentLevelReq] = useState(studentLevData)
  const [collegeVal, setCollegeVal] = useState([]);
  const [englishData, setEnglishData] = useState();
  const [showEnglishData, setShowEnglishData] = useState();

  const [countryData, setCountryData] = useState();
  const [showCountryData, setShowCountryData] = useState();

  const [genderData, setGenderData] = useState();

  const [athleticsData, setAthleticsData] = useState();
  const [showAthleticsData, setShowAthleticsData] = useState();

  const [interestData, setInterestData] = useState();
  const [showInterestData, setShowInterestData] = useState();

  const [levelData, setLevelData] = useState();
  const [showLevelData, setShowLevelData] = useState();
  const [
    selectedLevelQueries,
    setSelectedLevelQueries,
  ] = useState([]);
  const [selectedCountryQueries, setSelectedCountryQueries] = useState([]);
  const [configObject, setConfigObject] = useState([]);
  const [
    selectedEnglishTestQueries,
    setSelectedEnglishTestQueries,
  ] = useState([]);
  const [selectedAreaofStudyQueries, setSelectedAreaofStudyQueries] = useState(
    []
  );
  const [selectedBoardQueries, setSelectedBoardQueries] = useState([]);
  const [selectedAthleticsQueries, setSelectedAthleticsQueries] = useState([]);
  const [selectedActivitiesQueries, setSelectedActivitiesQueries] = useState(
    []
  );
  const [selectedInterestsQueries, setSelectedInterestsQueries] = useState([]);
  const [selectedThirdParameter, setSelectedThirdParameter] = useState([]);
  const [genderValue, setGenderValue] = useState();
  const [minGPA, setMinGPA] = useState(0);
  const [maxGPA, setMaxGPA] = useState(4);
  //const [openSaveQueryModal, setOpenSaveQueryModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openConfirmationForSiembra, setOpenConfirmationForSiembra] = useState(
    false
  );
  const [viewQueryData, setViewQueryData] = useState([queryData && queryData]);
  const [user, setUser] = useState(parsedUser);
  const [openExistingErrorModal, setOpenExistingErrorModal] = useState();
  const [page, setPage] = useState(1);

  const checkViewQuery = () => {
    if (viewQueryData && viewQueryData.length > 0) {
      let array = [];
      viewQueryData &&
        viewQueryData.map(async (item, index) => {
          for (const [key, value] of Object.entries(item)) {
            await array.push(value);
          }
          return array;
        });
      array.map((item) => {
        if (item.value === "male") {
          let selectedGender =
            genderData && genderData.filter((item) => item.value === "male");
          handleGenderCheckbox(selectedGender[0]);
        } else if (item.value === "female") {
          let selectedGender =
            genderData && genderData.filter((item) => item.value === "female");
          handleGenderCheckbox(selectedGender[0]);
        } else if (item.value === "unknown") {
          let selectedGender =
            genderData && genderData.filter((item) => item.value === "unknown");
          handleGenderCheckbox(selectedGender[0]);
        }

        if (item.config.value === "collage") {
          item &&
            item.multi_value.map((v) =>
              handleCheckBox(v, "contains", "collage")
            );
        }
        if (item.config.value === "country") {
          item.multi_value.map((v) =>
            handleCheckBox(v, "contains", "country")
          );
        }
        if (item.config.value === "academics") {
          item.multi_value.map((v) =>
            handleCheckBox(v, "contains", "academics")
          );
        }
        if (item.config.value === "athletics") {
          item.multi_value.map((v) =>
            handleCheckBox(v, "contains", "athletics")
          );
        }
        if (item.config.value === "interests") {
          item.multi_value.map((v) =>
            handleCheckBox(v, "contains", "interests")
          );
        }
        if (item.config.value === "activities") {
          item.multi_value.map((v) =>
            handleCheckBox(v, "contains", "activities")
          );
        }
        if (item.config.value === "english/math requirement") {
          item.multi_value.map((v) =>
            handleCheckBox(v, "contains", "english/math requirement")
          );
        }
        if (item.config.value === "declared area of study") {
          item.multi_value.map((v) =>
            handleCheckBox(v, "contains", "declared area of study")
          );
        }
      });
    }
  };

  useLayoutEffect(() => {
    if (viewQueryData && viewQueryData.length > 0) {
      checkViewQuery();
    }
  }, []);

  const resetQueryCriteria = () => {
    window.location.reload();
  };

  // const saveForSiembra = () => {
  //   setOpenConfirmationForSiembra(true);
  //   setOpenSaveQueryModal(false);
  // };

  const createConfig = (conf, operator = "contains", value) => {
    if (
      configObject &&
      configObject.some(
        (element) => element && element.config.value === conf.value
      )
    ) {
      configObject.forEach(async (element) => {
        if (element.config.value === conf.value) {
          element.multi_value = value;
        }
      });
    }
  };

  const onSelect = (selectedList) => {
    // //console.log("selectedList", selectedList);
    setCollegeVal(selectedList.map((item) => item.label));
  };

  let countryValue;
  const countryVal = () => {
    countryValue = selectedCountryQueries.map((item) => item.name);
  };
  if (selectedCountryQueries.length > 0) {
    countryVal();
  }

  let interestsValue;
  const interestsVal = () => {
    interestsValue = selectedInterestsQueries.map((item) => item.name);
  };
  if (selectedInterestsQueries.length > 0) {
    interestsVal();
  }

  let gpaVal;
  if (minGPA !== 0 || maxGPA !== 4) {
    gpaVal = { min: minGPA, max: maxGPA };
  } else if (minGPA > 0 || maxGPA > 4) {
    gpaVal = { min: minGPA, max: maxGPA };
  }

  let levelValue;
  const levelVal = () => {
    levelValue = selectedLevelQueries.map((item) => item.name);
  };
  if(selectedLevelQueries.length>0) {
    levelVal();
  }

  let engValue;
  const engVal = () => {
    engValue = selectedEnglishTestQueries.map((item) => item.name);
  }
  if(selectedEnglishTestQueries.length>0) {
    engVal();
  }

  let boardValue;
  const boardVal = () => {
    boardValue = selectedBoardQueries.map((item) => item.name);
  };
  if (selectedBoardQueries.length > 0) {
    boardVal();
  }

  let overQuery = {
    gender: genderValue && genderValue.name,
    country: countryValue,
    interests: interestsValue,
    gpa: gpaVal,
    desired_degree: levelValue,
    english_test: engValue,
    board_certification: boardValue,
  };

  //console.log("output",overQuery);

  const getQueryResultInternational = () => {
    localStorage.setItem("query", JSON.stringify(overQuery));
    history.push("/recruiter/query-result-international-query");
  };

  const createConfigForGender = (conf, operator, value) => {
    if (
      configObject &&
      configObject.some(
        (element) => element && element.config.value === conf.value
      )
    ) {
      configObject.forEach(async (element) => {
        if (element.config.value === conf.value) {
          element.value =
            value && value.value === "male"
              ? "male"
              : value.value === "female"
              ? "female"
              : "unknown";
          await setConfigObject(configObject);
        }
      });
    }
  };

  const removeValue = async (value, config, selectedThirdParameter) => {
    if (value.hasOwnProperty("Selected") && value["Selected"] === true) {
      if (selectedQueries && selectedQueries.length > 0) {
        let removedValueFromFilter = selectedQueries.filter(
          (i) => i.query.name !== value.name
        );
        setSelectedQueries(removedValueFromFilter);
      }
    }
    value["Selected"] = false;
    if (selectedThirdParameter && selectedThirdParameter.length > 0) {
      selectedThirdParameter.forEach((element) => {
        if (element === value) {
          let index = selectedThirdParameter.indexOf(element);
          selectedThirdParameter.splice(index, 1);
          setSelectedThirdParameter(selectedThirdParameter);
        } else if (value.name === element.label && value.id === element.value) {
          let index = selectedThirdParameter.indexOf(element);
          selectedThirdParameter.splice(index, 1);
          setSelectedThirdParameter(selectedThirdParameter);
        }
      });
    }
    configObject &&
      configObject.map(async (element) => {
        if (element.config.value && config.value === "country") {
          let index = selectedCountryQueries.indexOf(value);
          selectedCountryQueries.splice(index, 1);
          setSelectedCountryQueries(selectedCountryQueries);
        } 
        // else if (element.config.value && config.value === "athletics") {
        //   let index = selectedAthleticsQueries.findIndex(
        //     (item) => item.id === value.id
        //   );
        //   selectedAthleticsQueries.splice(index, 1);
        //   setSelectedAthleticsQueries(selectedAthleticsQueries);
        // } 
        // else if (element.config.value && config.value === "academics") {
        //   let index = selectedBoardQueries.findIndex(
        //     (item) => item.id === value.id
        //   );
        //   selectedBoardQueries.splice(index, 1);
        //   setSelectedBoardQueries(selectedBoardQueries);
        // } 
        // else if (element.config.value && config.value === "activities") {
        //   let index = selectedActivitiesQueries.findIndex(
        //     (item) => item.id === value.id
        //   );
        //   selectedActivitiesQueries.splice(index, 1);
        //   setSelectedActivitiesQueries(selectedActivitiesQueries);
        // } 
        else if (element.config.value && config.value === "interests") {
          let index = selectedInterestsQueries.findIndex(
            (item) => item.id === value.id
          );
          selectedInterestsQueries.splice(index, 1);
          setSelectedInterestsQueries(selectedInterestsQueries);
        } 

        // if (
        //   config.value !== "english/math requirement" &&
        //   config.value === element.config.value &&
        //   element.multi_value.length > 0
        // ) {
        //   element.multi_value.splice(
        //     element.multi_value.findIndex(
        //       (subelement) => subelement.id === value.id
        //     ),
        //     1
        //   );
        // }
        // if (config.value === "english/math requirement") {
        //   if (
        //     config.value === element.config.value &&
        //     element.multi_value.length > 0
        //   ) {
        //     let index = element.multi_value.filter(
        //       (item) => item.value !== value.value
        //     );
        //     element.multi_value = index;
        //   }
        // }
        // if (config.value === "community college") {
        //   if (
        //     config.value === element.config.value &&
        //     element.multi_value.length > 0
        //   ) {
        //     let index = element.multi_value.filter(
        //       (item) => item.code !== value.code && item.Selected === true
        //     );
        //     element.multi_value = index;
        //   }
        // }

        if (
          element.config.value !== "gender" &&
          element.multi_value.length === 0
        ) {
          let index = configObject.indexOf(element);
          configObject.splice(index, 1);
        }
      });
    await setConfigObject(configObject);
  };

  const handleCheckBox = async (value, operator = "contains", type) => {
    let selected = value;
    if (type === "english/math requirement" && value.hasOwnProperty("label")) {
      let modifiedObjForEnglishMathRequirement = {
        value: value?.value,
        name: value?.label,
      };
      value = modifiedObjForEnglishMathRequirement;
    }
    let originalElement = {};
    let finalValue = {};
    let objectValue = {};
    let multiValue = [];
    let selectedQueryFromView = [];
    if (
      !selected.hasOwnProperty("Selected") ||
      selected["Selected"] === false
    ) {
      objectValue = {
        query: selected,
        type: type,
      };
      selectedQueryFromView.push(objectValue);
      await setSelectedQueries((prevState) => [
        ...prevState,
        ...selectedQueryFromView,
      ]);
    }
    if (selected.hasOwnProperty("Selected") && selected["Selected"] === true) {
      if (selectedQueries && selectedQueries.length > 0) {
        let removedValueFromFilter = selectedQueries.filter(
          (i) => i.query.name !== selected.name
        );
        setSelectedQueries(removedValueFromFilter);
      }
    }
    if (type === "desired degree") {
      let conf =
        config && config.filter((item) => item.value === "desired degree");
      if (
        !selected.hasOwnProperty("Selected") ||
        selected["Selected"] === false
      ) {
        selected["Selected"] = true;
        multiValue.push(value);
        await setSelectedLevelQueries([
          ...selectedLevelQueries,
          ...multiValue,
        ]);
        createConfig(conf[0], operator, [
          ...selectedLevelQueries,
          ...multiValue,
        ]);
      } else {
        removeValue(value, conf[0], selectedLevelQueries);
      }
    } else if (type === "country") {
      let conf = config && config.filter((item) => item.value === "country");
      if (
        !selected.hasOwnProperty("Selected") ||
        selected["Selected"] === false
      ) {
        selected["Selected"] = true;
        multiValue.push(selected);
        setSelectedCountryQueries([
          ...selectedCountryQueries,
          ...multiValue,
        ]);
        createConfig(conf && conf[0], operator, [
          ...selectedCountryQueries,
          ...multiValue,
        ]);
      } else {
        removeValue(selected, conf[0], selectedCountryQueries);
      }
    } else if (type === "english test") {
      let conf =
        config &&
        config.filter((item) => item.value === "english test");
      if (
        !selected.hasOwnProperty("Selected") ||
        selected["Selected"] === false
      ) {
        selected["Selected"] = true;
        multiValue.push(selected);
        setSelectedEnglishTestQueries([
          ...selectedEnglishTestQueries,
          ...multiValue,
        ]);
        createConfig(conf[0], operator, [
          ...selectedEnglishTestQueries,
          ...multiValue,
        ]);
      } else {
        removeValue(selected, conf[0], selectedEnglishTestQueries);
      }
    } else if (type === "board certification") {
      let conf = config && config.filter((item) => item.value === "academics");
      if (
        !selected.hasOwnProperty("Selected") ||
        selected["Selected"] === false
      ) {
        selected["Selected"] = true;
        multiValue.push(selected);
        setSelectedBoardQueries([
          ...selectedBoardQueries,
          ...multiValue,
        ]);
        createConfig(conf[0], operator, [
          ...selectedBoardQueries,
          ...multiValue,
        ]);
      } else {
        removeValue(selected, conf[0], selectedBoardQueries);
      }
    } 
    
    // else if (type === "Area of interest") {
    //   let conf = config && config.filter((item) => item.value === "Area of interest");
    //   if (
    //     !selected.hasOwnProperty("Selected") ||
    //     selected["Selected"] === false
    //   ) {
    //     selected["Selected"] = true;
    //     multiValue.push(selected);
    //     //console.log(multiValue);
    //     setSelectedActivitiesQueries([
    //       ...selectedActivitiesQueries,
    //       ...multiValue,
    //     ]);
    //     createConfig(conf[0], operator, [
    //       ...selectedActivitiesQueries,
    //       ...multiValue,
    //     ]);
    //   } else {
    //     removeValue(selected, conf[0], selectedActivitiesQueries);
    //   }
    // } 
    else if (type === "Area of interest") {
      let conf = config && config.filter((item) => item.value === "interests");
      if (
        !selected.hasOwnProperty("Selected") ||
        selected["Selected"] === false
      ) {
        selected["Selected"] = true;
        multiValue.push(selected);
        setSelectedInterestsQueries([
          ...selectedInterestsQueries,
          ...multiValue,
        ]);
        createConfig(conf[0], operator, [
          ...selectedInterestsQueries,
          ...multiValue,
        ]);
      } else {
        removeValue(selected, conf[0], selectedInterestsQueries);
      }
    }
  };

  const onShowMore = async (value) => {
    if (value === "board") {  //ethnicity
      if (value === "board" && showBoardData.length === 3) {
        await setShowBoardData(showBoardData);
      } else {
        await setShowBoardData(showBoardData.slice(0, 3));
      }
    }
    if (value === "english") {
      if (value === "english" && showEnglishData.length === 3) {
        await setShowEnglishData(englishData);
      } else {
        await setShowEnglishData(englishData.slice(0, 3));
      }
    }
    if (value === "country") {
      if (value === "country" && showCountryData.length === 3) {
        await setShowCountryData(countryData);
      } else {
        await setShowCountryData(countryData.slice(0, 3));
      }
    }
    if (value === "interests") {
      if (value === "interests" && showInterestData.length === 3) {
        await setShowInterestData(interestData);
      } else {
        await setShowInterestData(interestData.slice(0, 3));
      }
    }
    if (value === "level") {
      if (value === "level" && showLevelData.length === 3) {
        await setShowLevelData(levelData);
      } else {
        await setShowLevelData(levelData.slice(0, 3));
      }
    }
  };

  const getcccData = () => {
    setLoading(true);
    QueryService.getInternational()
      .then((data) => {
        setLoading(false);
        //console.log("data",data.data);

        let AllBoard = data?.data?.object?.['board certification'];        ;
        let AllCountries = data?.data?.object?.country;
        let AllEnglish = data?.data?.object?.['english test'];
        let AllInterest = data?.data?.object?.intrest;
        let AllStud = data?.data?.object?.['desired degree'];
        let AllGender = data?.data?.object?.gender;

        let AllAthletics = data?.data?.object?.athletics;

        setGenderData(AllGender);

        setBoardData(AllBoard);
        setShowBoardData(AllBoard.slice(0, 3));

        setInterestData(AllInterest);
        setShowInterestData(AllInterest.slice(0, 3));
        setCountryData(AllCountries);
        setShowCountryData(AllCountries.slice(0, 3));
        setAthleticsData(AllAthletics);
        setShowAthleticsData(AllAthletics);

        setLevelData(AllStud);
        setShowLevelData(AllStud.slice(0, 3));
        
        setEnglishData(AllEnglish);
        setShowEnglishData(AllEnglish.slice(0, 3));
      })
      .catch((error) => {
        setLoading(false);
        //console.log("error from the service", error);
      });
  };

  useEffect(() => {
    getcccData();
  }, []);


  useEffect(() => {
    async function getSelectedQueries() {
      if (genderValue) {
        let query = {};
        query["type"] = "gender";
        query["name"] = genderValue && genderValue.name;
        let obj = {};
        obj.type = "gender";
        obj.query = query;
        await setSelectedQueries([...selectedQueries, obj]);
      }
    }

    getSelectedQueries();
  }, [genderValue]);


  const removeValueForCheckBox = async (item) => {
    setGenderValue();
    let filteredValue = selectedQueries && selectedQueries.indexOf(item);
    selectedQueries.splice(filteredValue, 1);
    setSelectedQueries(selectedQueries);
    configObject &&
      configObject.forEach((element) => {
        if (
          element.value === "male" ||
          element.value === "female" ||
          element.value === "unknown"
        ) {
          let index = configObject.indexOf(element);
          configObject.splice(index, 1);
        }
      });
    await setConfigObject(configObject);
  };

  const handleGenderCheckbox = async (value, operator = "contains") => {
    let conf = config && config.filter((item) => item.value === "gender");
    let filteredValue =
      selectedQueries.length > 0
        ? selectedQueries.filter((item) => {
            if (item.query.name === "Male") {
              let i = selectedQueries.indexOf(item);
              selectedQueries.splice(i, 1);
            } else if (item.query.name === "Female") {
              let i = selectedQueries.indexOf(item);
              selectedQueries.splice(i, 1);
            } else if (item.query.name === "Non-Binary") {
              let i = selectedQueries.indexOf(item);
              selectedQueries.splice(i, 1);
            }
          })
        : [];
    setSelectedQueries([...selectedQueries, ...filteredValue]);
    if (value.value === "male") {
      setGenderValue(value);
      createConfigForGender(conf[0], operator, value);
    } else if (value.value === "female") {
      await setGenderValue(value);
      createConfigForGender(conf[0], operator, value);
    } else {
      await setGenderValue(value);
      createConfigForGender(conf[0], operator, value);
    }
  };

  const handleGPASlider = async (value) => {
    let min = value[0];
    await setMinGPA(min);
    let max = value[1];
    await setMaxGPA(max);
  };

  useEffect(() => {
    if (minGPA !== 0 || maxGPA !== 4) {
      createConfigForGPA();
    }
  }, [minGPA, maxGPA]);

  const createConfigForGPA = () => {
    let obj = {
      min: minGPA,
      max: maxGPA,
    };
  };

  const clear = () => {
    setConfigObject([]);
    setSelectedQueries([]);
    setGenderValue();
  };

  return (
    <div>
      {loading ? (
        <CustomLoader />
      ) : (
        <div
          className="scrollBar_hidden"
        >
          <div className="top-border" />
          <div className="display-selected-query">
            <p className="no-margin-bottom bold">SET QUERY CRITERIA</p>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "5px",
              }}
            >
              <p className="no-margin-bottom">Choosen Filter:</p>
              <div onClick={() => clear()} className="clear-button">
                Clear All
              </div>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  margin: "0px 5px",
                }}
              >
                {selectedQueries &&
                  selectedQueries.map((item) => {
                    return (
                      <div
                        className="flex"
                        style={{
                          margin: "0 15px",
                          position: "relative",
                        }}
                      >
                        {item &&
                          item.query &&
                          (Array.isArray(item.query)
                            ? item.query.length > 0
                            : item.query) && (
                            <div
                              className="cursor"
                              onClick={
                                item.query.type === "gender"
                                  ? () => removeValueForCheckBox(item)
                                  : () =>
                                      handleCheckBox(item.query, "", item.type)
                              }
                            >
                              <CloseOutlined className="cancel-icon" />
                            </div>
                          )}
                        <p
                          style={{
                            padding: "0 5px 0 10px",
                            background: "var(--selected-primary-button-color)",
                            color: "var(--input-label-color)",
                            borderTopRightRadius: "15px",
                            borderBottomRightRadius: "15px",
                          }}
                          className="no-margin-bottom"
                        >
                          {item.query.name}
                        </p>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className="bottom-border" />
          <div style={{ margin: "15px 0px" }}>
            <p
              style={{
                fontWeight: "bold",
                fontSize: "14px",
                color: "#333333",
              }}
            >
              Set Criteria of Student Search
            </p>
            {/* <SingleQuery
            loading={loading}
            data={collageData}
            name="community college"
            valueWhichExpand={valueWhichExpand}
            handleCheckBox={(value, type) =>
              handleCheckBox(value, "contains", type)
            }
            selectedQueries={selectedQueries && selectedQueries}
          /> */}

            {/* <DropDown onSelect={onSelect} /> */}

            <div style={{ marginTop: "25px" }} className="col-md-12 row">
              <div className="col-md-4">
                <div className="query-title-containers ">
                  <p style={{ marginBottom: "0px" }} className="bold">
                    Gender
                  </p>
                </div>
                <div className="gender-value-container">
                  {genderData &&
                    genderData.map((item, index) => {
                      return (
                        <div key={index} className="flex">
                          <input
                            type="checkbox"
                            name={item.value}
                            onChange={() => handleGenderCheckbox(item)}
                            value={genderValue && genderValue.value}
                            checked={
                              genderValue && genderValue.value === item.value
                            }
                          />
                          <p
                            style={{
                              marginLeft: "10px",
                              fontSize: "14px",
                            }}
                            className="no-margin-bottom"
                          >
                            {item.name}
                          </p>
                        </div>
                      );
                    })}
                </div>
              </div>
              <div className="col-md-4">
                <SingleQuery
                  loading={loading}
                  data={showCountryData}
                  name="country"
                  valueWhichExpand={valueWhichExpand}
                  onShowMore={() => onShowMore("country")}
                  handleCheckBox={(value, type) =>
                    handleCheckBox(value, "contains", type)
                  }
                  selectedQueries={selectedQueries && selectedQueries}
                />
              </div>

              <div className="col-md-4">
                <SingleQuery
                  loading={loading}
                  data={showInterestData}
                  name="Area of interest"
                  valueWhichExpand={valueWhichExpand}
                  onShowMore={() => onShowMore("interests")}
                  handleCheckBox={(value, type) =>
                    handleCheckBox(value, "contains", type)
                  }
                  selectedQueries={selectedQueries && selectedQueries}
                />
              </div>
            </div>
            <div>
              <GPASlider
                handleGPASlider={handleGPASlider}
                minGPA={minGPA}
                maxGPA={maxGPA}
              />
            </div>
            <div style={{ marginTop: "25px" }} className="col-md-12 row">
            <div className="col-md-4">
                <SingleQuery
                  loading={loading}
                  data={showLevelData}
                  name="desired degree"
                  valueWhichExpand={valueWhichExpand}
                  onShowMore={() => onShowMore("level")}
                  handleCheckBox={(value, type) =>
                    handleCheckBox(value, "contains", type)
                  }
                  selectedQueries={selectedQueries && selectedQueries}
                />
              </div>
              <div className="col-md-4">
                <SingleQuery
                  data={showEnglishData}
                  name="english test"
                  valueWhichExpand={valueWhichExpand}
                  onShowMore={() => onShowMore("english")}
                  handleCheckBox={(value, type) =>
                    handleCheckBox(value, "contains", type)
                  }
                  selectedQueries={selectedQueries && selectedQueries}
                />
              </div>
              <div className="col-md-4">
                <SingleQuery
                  loading={loading}
                  data={showBoardData}
                  name="board certification"
                  valueWhichExpand={valueWhichExpand}
                  onShowMore={() => onShowMore("board")}
                  handleCheckBox={(value, type) =>
                    handleCheckBox(value, "contains", type)
                  }
                  selectedQueries={selectedQueries && selectedQueries}
                />
              </div>
              
            </div>
            {/* <div style={{ marginTop: "25px" }} className="col-md-12 row">
              <div className="col-md-4">
                <SingleQuery
                  loading={loading}
                  data={showAthleticsData}
                  name="athletics"
                  valueWhichExpand={valueWhichExpand}
                  onShowMore={() => onShowMore("athletics")}
                  handleCheckBox={(value, type) =>
                    handleCheckBox(value, "contains", type)
                  }
                  selectedQueries={selectedQueries && selectedQueries}
                />
              </div>
              
              <div className="col-md-4">
                <SingleQuery
                  loading={loading}
                  data={showDasData}
                  name="declared area of study"
                  valueWhichExpand={valueWhichExpand}
                  handleCheckBox={(value, type) =>
                    handleCheckBox(value, "contains", type)
                  }
                  onShowMore={() => onShowMore("declared area of study")}
                  selectedQueries={selectedQueries && selectedQueries}
                />
              </div>
            </div> */}
            <div
              style={{
                borderBottom: "3px solid black",
                marginTop: "15px",
              }}
            />
            <div style={{ margin: "15px 0" }} className="col-md-12 row">
              <div className="col-md-4">
                <button
                  onClick={() => getQueryResultInternational()}
                  className="query-button font bold"
                >
                  RUN QUERY
                </button>
              </div>
              {/* <div className="col-md-4">
          <button
            onClick={() => saveQuery()}
            className="save-query-button font "
          >
            SAVE QUERY
          </button>
        </div> */}
              <div className="col-md-4">
                <button
                  onClick={() => clear()}
                  className="save-query-button font "
                >
                  RESET QUERY CRITERIA
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {openConfirmationForSiembra && (
        <ConfirmationDialog
          isOpen={openConfirmationForSiembra}
          setIsOpen={setOpenConfirmationForSiembra}
          query={configObject && configObject}
          inputValue={inputValueForSave}
          openErrorModal={openExistingErrorModal}
          setQuery={setConfigObject}
          setOpenErrorModal={setOpenExistingErrorModal}
          setInputValue={setInputValueForSave}
        />
      )}
      {openExistingErrorModal && (
        <ErrorModal
          openErrorModal={openExistingErrorModal}
          setOpenErrorModal={setOpenExistingErrorModal}
        />
      )}
    </div>
  );
};

export default InternationalQuery;
