import React, { Fragment, useState, useEffect } from "react";
import EditIcon from "@mui/icons-material/Edit";
import CancelIcon from "@mui/icons-material/Cancel";

const CampaignBannerList = ({ data, onEditClick, onDeleteClick }) => {
  // console.log("data", data);
  return (
    <Fragment>
      {data &&
        data.map((item, index) => {
          return (
            <div className="flex single-campaign">
              <p className="no-margin-bottom campaign-text">{item.name}</p>
              <div style={{ height: "100%" }} className="flex">
                <div
                  onClick={() => onEditClick(item)}
                  className="cursor campaign-icons"
                >
                  <EditIcon />
                </div>
                <div className="campaign-separator" />
                {/* <div onClick={() => onDeleteClick(item)} className="cursor campaign-icons">
                                <CancelIcon />
                            </div> */}
              </div>
            </div>
          );
        })}
    </Fragment>
  );
};
export default CampaignBannerList;
