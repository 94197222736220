import { useRef, useState, useEffect, Fragment } from 'react';
import GetSuperCounselorStudentService from '../../../services/SuperCounselor/GetStudentService';
import AppHeader from "../../../Layout/AppHeader";
import AppSidebar from "../../../Layout/AppSidebar";
import CustomLoader from '../../customLoader/CustomLoader';

const DownloadStudentSummary = () => {
    const [selectedGoal, setSelectedGoal] = useState()
    const [publishData, setPublishData] = useState();
    const [user, setUser] = useState(localStorage.getItem('user') && JSON.parse(localStorage.getItem('user')))
    const [dataForDownload, setDataForDownload] = useState();
    const [loading, setLoading] = useState(false)



    function percentage(numerator, denominator) {
        var result;
        if ((numerator == 0) || (denominator == 0)) {
            result = 0;
        }
        else {
            var top = numerator / denominator;
            result = top * 100;
            result = Math.round(result)
        }
        return result;
    }


    var stRecord = [], stRecord1 = [], stRecord2 = [], stRecord3 = [], stRecord4 = [], total_per = [];
    function total_col_count(csv, length1, d1, d2, d3, d4, data) {
        var sum1 = 0, sum = 0;
        var temp = [];
        temp.push(data);
        for (var i = 0; i < length1; i++) {

            sum1 = stRecord1[i] + stRecord2[i] + stRecord3[i] + stRecord4[i];


            total_per[i] = sum1;

            temp.push(sum1);
        }
        sum = Number(d1) + Number(d2) + Number(d3) + Number(d4);
        temp.push(sum);

        csv.push(temp);

        temp = []
        temp.push("%")
        for (var i = 0; i < length1; i++) {

            var per = percentage(total_per[i], sum)
            temp.push("              " + per + " %")
        }
        csv.push(temp);
    }

    var template = [];

    function get_heading(heading, result) {
        template.push(heading);
        for (var i = 0; i < result.course_name.length; i++) {
            stRecord[i] = result.course_name[i];
            template.push(stRecord[i]);
        }
        template.push(["Total"]);
        return template;
    }


    function get_heading(heading, result) {
        template.push(heading);
        for (var i = 0; i < result.course_name.length; i++) {
            stRecord[i] = result.course_name[i];
            template.push(stRecord[i]);
        }
        template.push(["Total"]);
        return template;
    }

    function getHeaderRow1(result) {

        var s1 = 0, s2 = 0, s3 = 0, s4 = 0;
        var ret = [];
        var template1 = [], per_cent1 = [];
        var template2 = [], per_cent2 = [];
        var template3 = [], per_cent3 = [];
        var template4 = [], per_cent4 = [];
        var space = [];
        ret.push(space);

        ret.push("Yellow Student Status");
        ret.push(space);
        //headings
        var str = "Grade / Course Name";
        get_heading(str, result)
        ret.push(template.join(','));
        template = [];
        ret.push(space)
        //9 th grade
        template1.push(["9 th"]);
        per_cent1.push("%")
        if (result.course_count_9.length == 0) {
            for (var i = 0; i < result.course_name.length; i++) {
                stRecord1[i] = 0;
                template1.push(stRecord1[i]);
                s1 = result.total_count_r_9;
            }
            for (var i = 0; i < result.course_count_9.length; i++) {
                var per = percentage(stRecord1[i], s1);
                per_cent1.push("             " + per + " %")
            }

        }
        else {

            for (var i = 0; i < result.course_count_9.length; i++) {
                stRecord1[i] = result.course_count_9[i];
                template1.push(stRecord1[i]);
                //total_count
                s1 = result.total_count_r_9;

            }
            // for percentage calculation
            for (var i = 0; i < result.course_count_9.length; i++) {
                var per = percentage(stRecord1[i], s1);
                per_cent1.push("             " + per + " %")
            }
        }
        template1.push(s1);
        ret.push(template1);
        ret.push(per_cent1);
        ret.push(space);
        //10 th grade
        template2.push(["10 th"]);
        per_cent2.push("%")
        if (result.course_count_10.length == 0) {
            for (var i = 0; i < result.course_name.length; i++) {
                stRecord2[i] = 0;
                template2.push(stRecord2[i]);
                s2 = result.total_count_r_10;
            }
            for (var i = 0; i < result.course_count_10.length; i++) {
                var per = percentage(stRecord2[i], s2);
                per_cent2.push("             " + per + " %")
            }

        }
        else {
            for (var i = 0; i < result.course_count_10.length; i++) {

                stRecord2[i] = result.course_count_10[i];
                template2.push(stRecord2[i]);
                //percentage call
                s2 = result.total_count_r_10;
            }
            // for percentage calculation
            for (var i = 0; i < result.course_count_10.length; i++) {
                var per = percentage(stRecord2[i], s2);
                per_cent2.push("             " + per + " %")
            }
        }
        template2.push(s2);
        ret.push(template2);
        ret.push(per_cent2);
        ret.push(space);
        //11 th grade
        template3.push(["11 th"]);
        per_cent3.push("%")
        if (result.course_count_11.length == 0) {
            for (var i = 0; i < result.course_name.length; i++) {
                stRecord3[i] = 0;
                template3.push(stRecord3[i]);
                s3 = result.total_count_r_11;

            }
            for (var i = 0; i < result.course_count_11.length; i++) {
                var per = percentage(stRecord3[i], s3);
                per_cent3.push("             " + per + " %")
            }

        }
        else {
            for (var i = 0; i < result.course_count_11.length; i++) {
                stRecord3[i] = result.course_count_11[i];
                template3.push(stRecord3[i]);
                //percentage call
                var s3 = result.total_count_r_11;
            }
            // for percentage calculation
            for (var i = 0; i < result.course_count_11.length; i++) {
                var per = percentage(stRecord3[i], s3);
                per_cent3.push("             " + per + " %")
            }
        }
        template3.push(s3);
        ret.push(template3);
        ret.push(per_cent3);
        ret.push(space);
        //12th grade
        template4.push(["12 th"]);
        per_cent4.push("%")
        if (result.course_count_12.length == 0) {
            for (var i = 0; i < result.course_name.length; i++) {
                stRecord4[i] = 0;
                template4.push(stRecord4[i]);
                s4 = result.total_count_r_12;
            }
            for (var i = 0; i < result.course_count_12.length; i++) {
                var per = percentage(stRecord4[i], s4);
                per_cent4.push("             " + per + " %")
            }

        }
        else {
            for (var i = 0; i < result.course_count_12.length; i++) {
                stRecord4[i] = result.course_count_12[i];
                template4.push(stRecord4[i]);
                var s4 = result.total_count_r_12;
            }
            // for percentage calculation
            for (var i = 0; i < result.course_count_12.length; i++) {
                var per = percentage(stRecord4[i], s4);
                per_cent4.push("             " + per + " %")
            }
        }
        template4.push(s4);
        ret.push(template4);
        ret.push(per_cent4);
        ret.push(space);
        total_col_count(ret, result.course_name.length, s1, s2, s3, s4, "Total Yellow Status Students");
        ret.push(space)
        ret.push(space)
        ret.push(space); ret.push(space); ret.push(space)
        //call red
        getHeaderRow2(result, ret)
        ret = ret.join("\n");
        return ret;
    }
    // for red
    function getHeaderRow2(result, ret) {
        var s1 = 0, s2 = 0, s3 = 0, s4 = 0;
        var template1 = [], per_cent1 = [];
        var template2 = [], per_cent2 = [];
        var template3 = [], per_cent3 = [];
        var template4 = [], per_cent4 = [];
        var space = [];
        ret.push("Red Student Status")
        ret.push(space)
        //headings
        get_heading("Grade / Course Name", result)
        ret.push(template.join(','));
        template = [];
        ret.push(space)
        //9 th grade
        template1.push(["9 th"]);
        per_cent1.push("%")
        if (result.course_count_r_9.length == 0) {
            for (var i = 0; i < result.course_name.length; i++) {
                stRecord1[i] = 0;
                template1.push(stRecord1[i]);
                s1 = result.total_count_r_9;
            }
            for (var i = 0; i < result.course_count_r_9.length; i++) {
                var per = percentage(stRecord1[i], s1);
                per_cent1.push("             " + per + " %")
            }

        }
        else {

            for (var i = 0; i < result.course_count_r_9.length; i++) {
                stRecord1[i] = result.course_count_r_9[i];
                template1.push(stRecord1[i]);
                //total_count
                s1 = result.total_count_r_9;

            }
            // for percentage calculation
            for (var i = 0; i < result.course_count_r_9.length; i++) {
                var per = percentage(stRecord1[i], s1);
                per_cent1.push("             " + per + " %")
            }
        }
        template1.push(s1);

        ret.push(template1);
        ret.push(per_cent1);
        ret.push(space);

        //10 th grade
        template2.push(["10 th"]);
        per_cent2.push("%")
        if (result.course_count_r_10.length == 0) {
            for (var i = 0; i < result.course_name.length; i++) {
                stRecord2[i] = 0;
                template2.push(stRecord2[i]);
                s2 = result.total_count_r_10;
            }
            for (var i = 0; i < result.course_count_r_10.length; i++) {
                var per = percentage(stRecord2[i], s2);
                per_cent2.push("             " + per + " %")
            }


        }
        else {

            for (var i = 0; i < result.course_count_r_10.length; i++) {

                stRecord2[i] = result.course_count_r_10[i];
                template2.push(stRecord2[i]);
                //percentage call
                s2 = result.total_count_r_10;
            }
            // for percentage calculation
            for (var i = 0; i < result.course_count_r_10.length; i++) {
                var per = percentage(stRecord2[i], s2);
                per_cent2.push("             " + per + " %")
            }
        }
        template2.push(s2);
        ret.push(template2);
        ret.push(per_cent2);
        ret.push(space);
        //11 th grade
        template3.push(["11 th"]);
        per_cent3.push("%")
        if (result.course_count_r_11.length == 0) {
            for (var i = 0; i < result.course_name.length; i++) {
                stRecord3[i] = 0;

                template3.push(stRecord3[i]);
                s3 = result.total_count_r_11;
            }
            for (var i = 0; i < result.course_count_r_11.length; i++) {
                var per = percentage(stRecord3[i], s3);
                per_cent3.push("             " + per + " %")
            }

        }
        else {
            for (var i = 0; i < result.course_count_r_11.length; i++) {
                stRecord3[i] = result.course_count_r_11[i];
                template3.push(stRecord3[i]);
                //percentage call
                s3 = result.total_count_r_11;
            }
            // for percentage calculation
            for (var i = 0; i < result.course_count_r_11.length; i++) {
                var per = percentage(stRecord3[i], s3);
                per_cent3.push("             " + per + " %")
            }
        }
        template3.push(s3);
        ret.push(template3);
        ret.push(per_cent3);
        ret.push(space);

        //12th grade
        template4.push(["12 th"]);
        per_cent4.push("%")
        if (result.course_count_r_12.length == 0) {
            for (var i = 0; i < result.course_name.length; i++) {
                stRecord4[i] = 0;
                template4.push(stRecord4[i]);

                var s4 = result.total_count_r_12;
            }
            for (var i = 0; i < result.course_count_r_12.length; i++) {
                var per = percentage(stRecord4[i], s4);
                per_cent4.push("             " + per + " %")
            }

        }
        else {
            for (var i = 0; i < result.course_count_r_12.length; i++) {
                stRecord4[i] = result.course_count_r_12[i];
                template4.push(stRecord4[i]);
                var s4 = result.total_count_r_12;
            }
            // for percentage calculation
            for (var i = 0; i < result.course_count_r_12.length; i++) {
                var per = percentage(stRecord4[i], s4);
                per_cent4.push("             " + per + " %")
            }
        }
        template4.push(s4);
        ret.push(template4);
        ret.push(per_cent4);
        ret.push(space);
        total_col_count(ret, result.course_name.length, s1, s2, s3, s4, "Total Red Status Students");
        ret.push(space)

        return ret;
    }

    const getPublishData = () => {
        //console.log("getting called");
        let obj = {
            school_id:user?.school_id
        }
        setLoading(true)
        GetSuperCounselorStudentService.goalPublishManage(obj)
            .then(data => {
                //console.log("data from the publish", data);
                setPublishData(data.data.data);
                setLoading(false)
            })
            .catch(error => {
                setLoading(false)
                //console.log('error from the table', error)
            })
    }


    const handleGoalChange = (e) => {
        setSelectedGoal(e.target.value)
    }

    const getGoalData = () => {
        let obj = {
            school_id:user?.school_id,
            goal_id: selectedGoal
        }

        const downloadFile = (downloadURL, fileName) => {
            const downloadLink = document.createElement('a');
            document.body.appendChild(downloadLink);
            downloadLink.download = fileName;
            downloadLink.href = downloadURL;
            downloadLink.click();
        }
      
        GetSuperCounselorStudentService.studentStatistics(obj)
            .then(data => {
                //console.log("data from the download goal data", data);
                downloadFile(data.data.download, "My File")
            })
            .catch(error => {
                //console.log('error from the service', error)
            })
    }

    useEffect(() => {
        getPublishData();
    }, [])

    // useEffect(() => {
    //     let item = publishData?.filter(item => item?.goal === selectedGoal);
    //     //console.log("item from the useEffect", item);
    //     if (item && item.length > 0) {
    //         getGoalData()
    //     }

    // }, [selectedGoal])


    // const downLoadData = () => {
    //     var result_csv1 = [];
    //     result_csv1 = getHeaderRow1(dataForDownload);
    //     var csvDownload = result_csv1;
    //     var saving = document.createElement('a');
    //     saving.href = 'data:attachment/csv,' + encodeURIComponent(csvDownload);
    //     saving.download = 'download_student_status.csv';
    //     document.body.appendChild(saving);
    //     saving.click();
    // }
    return (
        <Fragment>
      <AppHeader />
      <div className="app-main">
      <AppSidebar />
      <div className="app-main__outer">
            {loading ? <CustomLoader /> : <div style={{ padding: '20px' }}>
                <div>
                    <p style={{ fontSize: '18px', fontWeight: '700' }}>DOWNLOAD PROGRESS SUMMARY</p>
                    <p style={{ fontSize: '18px', color: '#333' }}>Goal:</p>
                </div>
                <div className="flex" style={{ justifyContent: 'space-between' }}>
                    <select onChange={handleGoalChange} style={{ height: '56px', minWidth: '524px' }}>
                        <option value="" disabled selected>Select Goal</option>
                        {publishData?.map((item, index) => (
                            <option key={index} value={item?.id}>{item?.goal}</option>
                        ))}
                    </select>
                </div>
                <br/>
                <button className="save-portfolio-button" onClick={() => getGoalData()} disabled={!selectedGoal}>Download Report</button>
            </div>}
            </div>
            </div>
        </Fragment>
    )
}
export default DownloadStudentSummary