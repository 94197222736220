import { Pagination } from "@mui/material";
import React, { useState, useEffect, Fragment } from "react";
import { useHistory } from "react-router";
import QueryService from "../../services/QueryService";
import DeleteModal from "../deleteModal/DeleteModal";
import CustomLoader from "../customLoader/CustomLoader";
import DuplicateModal from "../duplicateModal/DupicateModal";
import ErrorModal from "../errorDisplayModal/ErrorDisplayModal";
import ConfirmationDialog from "../confirmationModalForSiembra/ConfirmationModalForSiembra";
import { useDispatch, useSelector } from "react-redux";
import AppHeader from "../../Layout/AppHeader";
import AppSidebar from "../../Layout/AppSidebar";
import { changeQueryLocation } from "../../reducers/locationReducer";
const IMG_URL = process.env.REACT_APP_IMAGE_ASSET_URL;

const SchoolQuery = () => {
  let history = useHistory();
  let authuser = localStorage.getItem("user");
  // let parsedUser = JSON.parse(authuser);
  let parsedUser = useSelector((state) => state.UserReducer.userObj);
  const [schoolQueryData, setSchoolQueryData] = useState();
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [page, setPage] = useState(1);
  const [paginationCount, setPaginationCount] = useState(0);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteQuery, setDeleteQuery] = useState();
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(parsedUser);
  const [openDuplicateModal, setOpenDuplicateModal] = useState(false);
  const [duplicateQueryData, setDuplicateQueryData] = useState();
  const [inputValue, setInputValue] = useState("");
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const dispatch = useDispatch();

  const navigateToQuery = () => {
    localStorage.removeItem("query");
    history.push("/recruiter/search-query");
  };

  const getSchoolQueryPage = () => {
    setLoading(true);
    // let obj = {
    //   advanced_query: "recruiter.advanced_query",
    //   college: user && user.college_id,
    //   ,
    //   query_type: "recruiter.simple_query",
    //   role: user && user.role && user.role.title,
    // };
    QueryService.getSchoolQueryPage(user && user.college, "recruiter.student", page)
      .then((data) => {
        setLoading(false);
        //console.log("data from the service", data.data);
        setSchoolQueryData(data?.data?.final_query);
        setPaginationCount(data.data.max);
      })
      .catch((error) => {
        setLoading(false);
        //console.error("error from services", error);
      });
  };

  const getQueryResult = (item) => {
    // let obj = {};
    let a = item.where_clause;
    // obj.queries = [null, ...a];
    // obj.college_id = user?.college;
    // obj.query_id = "";
    // obj.recruiter_id = user?.id;
    let query = JSON.stringify(a);
    localStorage.setItem("query", query);
    history.push("/recruiter/query-result");
  };

  // const getQueryResult = (item) => {
  //     let arr = [];
  //     let arrayObject = {}
  //     for (const [key, value] of Object.entries(item && item.query)) {
  //         arrayObject = value;
  //         arr.push(arrayObject)
  //     }
  //     let obj = {
  //         college_id: user && user.college_id,
  //         queries: arr,
  //         query_id: item && item.id,
  //         recruiter_id: user && user.id
  //     }
  //     setLoading(true)
  //     QueryService.getQueryResult(obj)
  //         .then(data => {
  //             getResultDisplay(data.data)
  //         })
  //         .catch(error => {
  //             setLoading(false)
  //             //console.error('error from the service', error)
  //         })
  // }

  useEffect(() => {
    getSchoolQueryPage();
  }, [page]);

  const selectTab = (value) => {
    if (value === "customQuery") {
      localStorage.removeItem("query");
      history.push("/recruiter/search-query");
    } else if (value === "schoolQuery") {
      localStorage.removeItem("query");
      history.push("/recruiter/school-query");
    } else if (value === "savedQuery") {
      localStorage.removeItem("query");
      history.push("/recruiter/saved-query");
    } else if (value === "collegeQuery") {
      localStorage.removeItem("query");
      history.push("/recruiter/college-query");
    }
  };

  const openDeleteModalHandler = async (value) => {
    await setDeleteQuery(value);
    setOpenDeleteModal(true);
  };

  const openDuplicateModalHandler = async (value) => {
    getDuplicateQueryData(value);
    // setOpenDuplicateModal(true)
  };

  const getDuplicateQueryData = (value) => {
    let obj = {
      college: user && user.college_id,
      creator: user && user.id,
      query_id: value.id,
    };
    QueryService.duplicateQuery(obj)
      .then((data) => {
        //console.log("data from duplicate", data);
        setDuplicateQueryData(data.data.new_id);
        setOpenDuplicateModal(true);
      })
      .catch((error) => {
        //console.error("error from the service", error);
      });
  };

  // const handleViewQuery = (item) => {
  //   setLoading(true);
  //   //console.log("item from the handleViewQuery", item);
  //   QueryService.getViewquery(item.id)
  //     .then((data) => {
  //       //console.log("data from the view Query", data);
  //       history.push( {
  //         pathname: "/recruiter/search-query",
  //         state: data.data,
  //       });
  //     })
  //     .catch((error) => {
  //       //console.error("error from the service", error);
  //     });
  // };

  const handleViewQuery = (item) => {
    setLoading(true);
    //console.log("item from the handleViewQuery", item);
    let obj={
      query_id: item.id
    }
    QueryService.getViewquery(obj)
      .then((data) => {
        //console.log("data query",data)
        let newData = data?.data?.query?.where_clause;
        // let orderedData = newData.reduce((acc, item, index) => {
        //   if (item.config.value === "gender") {
        //     acc[index + 1] = acc[1];
        //     acc[1] = item;
        //   } else {
        //     acc[index + 1] = item;
        //   }
        //   return acc;
        // }, {});

        let final = { ...newData };
        dispatch(changeQueryLocation(final));
        history.push({
          pathname: "/recruiter/search-query",
          state: { someValue: data?.data?.query?.query_name}
        });
      })
      .catch((error) => {
        //console.error("error from the service", error);
      });
  };

  return (
    <Fragment>
      <AppHeader />
      <div className="app-main">
        <AppSidebar />
        <div className="app-main__outer">
          <div className="name_message_header">
            <p>
              Hello {user ? `${user?.first_name} ${user?.last_name}!!` : ""}
            </p>
          </div>
          <div
            style={{
              padding: "20px",
              background: "var(--background-color)",
              height: "100%",
              overflowY: "scroll",
            }}
          >
            <div style={{ marginTop: "20px" }} className="tab-grid">
              <div
                onClick={() => selectTab("customQuery")}
                style={{ width: "100%" }}
                className="tab-button inactive-tab cursor"
              >
                <p className="no-margin-bottom">H/S Student Query</p>
              </div>
              <div
                onClick={() => selectTab("schoolQuery")}
                style={{ width: "100%" }}
                className="tab-button active-tab cursor"
              >
                <p className="no-margin-bottom">Saved Queries</p>
              </div>
              <div
                onClick={() => selectTab("collegeQuery")}
                style={{ width: "100%" }}
                className={"tab-button cursor inactive-tab"}
              >
                <p className="no-margin-bottom">C/C Student Query</p>
              </div>
              {/* <div onClick={()=>selectTab('savedQuery')} style={{ width: '100%' }} className="tab-button inactive-tab cursor"><p className="no-margin-bottom">Saved Queried Results</p></div>
                    <div onClick={()=>{}}  style={{ width: '100%' }} className="tab-button inactive-tab cursor"><p className="no-margin-bottom">Query Columns</p></div> */}
            </div>
            <div className="school-queries-container">
              <div
                style={{
                  padding: "20px",
                  justifyContent: "space-between",
                  borderBottom: "2px solid #333333",
                }}
                className="flex"
              >
                <div className="flex">
                  <img
                    style={{ width: "40px", height: "60px" }}
                    src={IMG_URL + "SchoolQuery.svg"}
                    alt="School Query"
                  />
                  <div style={{ padding: "0px 15px" }}>
                    {/* <p
                      style={{ fontSize: "24px" }}
                      className="no-margin-bottom color"
                    >
                      {paginationCount && schoolQueryData
                        ? paginationCount * schoolQueryData.length
                        : 0} */}
                        {/* {schoolQueryData?.length} */}
                    {/* </p> */}
                    <p
                      style={{ fontSize: "14px", fontWeight: "bold" }}
                      className="no-margin-bottom color"
                    >
                      School Queries
                    </p>
                  </div>
                </div>
                <div className="cursor" onClick={() => navigateToQuery()}>
                  <p
                    className="font color"
                    style={{ textDecoration: "underline" }}
                  >
                    H/S Student Query
                  </p>
                </div>
              </div>
              <div style={{ margin: "30px 0px" }}>
                <p className="bold color">ALL SCHOOL QUERIES</p>
              </div>
              {loading ? (
                <CustomLoader />
              ) : (
                <div>
                  {schoolQueryData &&
                    schoolQueryData?.map((item, index) => (
                      <div
                        key={index}
                        style={{
                          padding: "25px",
                          borderLeft: "1px solid #ece",
                          borderRight: "1px solid #ece",
                          borderTop: "1px solid #ece",
                          borderBottom:
                            "3px solid var(--selected-primary-button-color)",
                          margin: "10px 0px",
                        }}
                      >
                        <div className="col-md-12 row">
                          <div className="col-md-3">
                            <p
                              style={{
                                fontSize: "18px",
                                color: "#351724",
                                fontWeight: "bold",
                                textTransform: "capitalize",
                              }}
                            >
                              {item.query_name}
                            </p>
                          </div>
                          <div className="col-md-9 row">
                            <div className="col-md-4">
                              <button
                                className="query-button-active"
                                onClick={() => getQueryResult(item)}
                              >
                                RUN QUERY
                              </button>
                            </div>
                            {/* <div className="col-md-3">
                              <button
                                className="query-button-inactive"
                                onClick={() => openDuplicateModalHandler(item)}
                                style={{opacity:"0.7"}}
                                disabled
                              >
                                DUPLICATE QUERY
                              </button>
                            </div> */}
                            <div className="col-md-4">
                              {/* {item && item.creator_id === user.id ? (
                                <button
                                  onClick={() => handleViewQuery(item)}
                                  className="query-button-inactive"
                                >
                                  EDIT QUERY
                                </button>
                              ) : ( */}
                                <button
                                  onClick={() => handleViewQuery(item)}
                                  className="query-button-inactive"
                                >
                                  VIEW QUERY
                                </button>
                             {/* )} */}
                            </div>
                            <div className="col-md-4">
                              <button
                                disabled={item.is_global === true}
                                style={{
                                  opacity: item && item.is_global === true ? "0.7" : "1",
                                }}
                                className="query-button-inactive"
                                onClick={() => openDeleteModalHandler(item)}
                              >
                                DELETE QUERY
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              )}
              <Pagination
                count={paginationCount}
                onChange={(event, page) => setPage(page)}
                primary
              />
            </div>
          </div>
          {openDeleteModal && (
            <DeleteModal
              openDeleteModal={openDeleteModal}
              setOpenDeleteModal={setOpenDeleteModal}
              deleteValue={deleteQuery}
              setDeleteValue={setDeleteQuery}
              getSavedQueryPage={getSchoolQueryPage}
              from="school-query"
              getQueryPage={getSchoolQueryPage}
            />
          )}

          {openDuplicateModal && (
            <DuplicateModal
              openDuplicateModal={openDuplicateModal}
              setOpenDuplicateModal={setOpenDuplicateModal}
              duplicateQuery={duplicateQueryData}
              setOpenErrorModal={setOpenErrorModal}
              openErrorModal={openErrorModal}
              getSchoolQueryPage={getSchoolQueryPage}
              inputValue={inputValue}
              setInputValue={setInputValue}
              openConfirmationModal={openConfirmationModal}
              setOpenConfirmationModal={setOpenConfirmationModal}
            />
          )}

          {openErrorModal && (
            <ErrorModal
              openErrorModal={openErrorModal}
              setOpenErrorModal={setOpenErrorModal}
              text="Query"
            />
          )}
          {openConfirmationModal && (
            <ConfirmationDialog
              isOpen={openConfirmationModal}
              setIsOpen={setOpenConfirmationModal}
              inputValue={inputValue}
              query={duplicateQueryData}
              setInputValue={setInputValue}
              getSchoolQueryPage={getSchoolQueryPage}
              from="schoolQuery"
              openErrorModal={openErrorModal}
              setOpenErrorModal={setOpenErrorModal}
            />
          )}
        </div>
      </div>
    </Fragment>
  );
};
export default SchoolQuery;
