import { Route, Redirect } from "react-router-dom";
import React, { Fragment } from "react";
import PrivateRoutes from "./PrivateRoutes";
import PublicRoutes from "./PublicRoutes";

import { ToastContainer } from "react-toastify";
import Storage from "../services/Storage";
import { useSelector } from "react-redux";
import { Roles } from "../utils/Roles";

const AppMain = () => {
  let token = Storage.getTokenInCookie() || null;
  let userData = useSelector((state) => state.UserReducer.userObj);
  return (
    <Fragment>
      <PrivateRoutes />
      <PublicRoutes />
      <Route
        exact
        path="/"
        render={() => {
          if (!token) {
            return <Redirect to="/login" />;
          } else {
            if (
              userData?.role === Roles.STUDENT_NAME &&
              userData?.is_college_email_verified === true
            ) {
              return <Redirect to="/student/student-progress" />;
            } else if (
              userData?.role === Roles.STUDENT_NAME &&
              userData?.is_college_email_verified === false
            ) {
              return <Redirect to="/student/dashboard" />;
            } 
            // else if (
            //   userData?.role === Roles.COUNSELOR_NAME &&
            //   userData?.is_super_counselor === true
            // ) {
            //   return <Redirect to="/super_admin/all-students" />;
            // } 
            else if (userData?.role === Roles.COUNSELOR_NAME) {
              return <Redirect to="/counselor/all-students" />;
            } 
            // else if (userData?.role === Roles.RECRUITER_NAME) {
            //   return <Redirect to="/recruiter/search-query" />;
            // } else if (userData?.role === Roles.ADMIN_NAME) {
            //   return <Redirect to="/admin/export-students" />;
            // } else if (
            //   userData?.role === Roles.SCHOOL_IT_NAME &&
            //   userData?.is_setup === false
            // ) {
            //   return <Redirect to="/school_it/student_statistics" />;
            // } else if (
            //   userData?.role === Roles.SCHOOL_IT_NAME &&
            //   userData?.is_setup === true
            // ) {
            //   return <Redirect to="/school_it/school_setup" />;
            // }
          }
        }}
      />
      <ToastContainer />
    </Fragment>
  );
};

export default AppMain;
