import { request } from "../Request";
import { routes } from "../../constants/constant.route";
import { PopoverBody } from "reactstrap";
const API_URL = process.env.REACT_APP_API_URL;

export default class GetStudentService {
  static getMessageListPerUser = (page, src_id) => {
    return request(
      "GET",
      `${API_URL}${routes.schoolIT.received_message}?page=${page}&src_id=${src_id}`,
      null,
      null,
      null,
      null,
      null
    );
  };

  static getStudent(obj) {
    if(obj.q){
      return request(
        "GET",
        `${API_URL}${routes.schoolIT.getStudent}?role=${obj.role}&school_id=${obj.school}&q=${obj.q}&page=${obj.page}`,
        null,
        null,
        null,
        null
      );
    }
    else {
      return request(
        "GET",
        `${API_URL}${routes.schoolIT.getStudent}?role=${obj.role}&school_id=${obj.school}&student=${obj.student}&page=${obj.page}`,
        null,
        null,
        null,
        null
      );
    }
    
  }

  static getSchoolsList(obj) {
    return request(
      "GET",
      `${API_URL}${routes.schoolIT.getSchoolsList}?school=${obj.q}`,
      null,
      null,
      null,
      null
    );
  }

  static getDistrictsList(body) {
    return request(
      "POST",
      `${API_URL}${routes.schoolIT.getDistrictsList}`,
      null,
      body,
      null,
      null
    );
  }

  static getStudentCountForCSVImport(school) {
    return request(
      "GET",
      `${API_URL}${routes.schoolIT.studentCount}?school_id=${school}`,
      null,
      null,
      null,
      null
    );
  }

  static getDataSwal(school) {
    return request(
      "GET",
      `${API_URL}${routes.schoolIT.getDataSwal}/?school=${school}`,
      null,
      null,
      null,
      null
    );
  }

  static getStudentDataForCSVImport(limit, offset, school) {
    return request(
      "GET",
      `${API_URL}${routes.schoolIT.downloadStudent}?school=${school}&limit=${limit}&offset=${offset}`,
      null,
      null,
      null,
      null
    );
  }

  static getMaintainGroupData(obj) {
    return request(
      "GET",
      `${API_URL}${routes.schoolIT.getMaintainGroupIT}?school=${obj.school}&counselor=${obj.counselor}&page=${obj.page}`,
      null,
      null,
      null,
      null
    );
  }

  static getSelectedGroupData(id) {
    return request(
      "GET",
      `${API_URL}${routes.schoolIT.getShowMaintain}?group_id=${id}`,
      null,
      null,
      null,
      null
    );
  }

  static createGroup(body) {
    return request(
      "POST",
      `${API_URL}${routes.schoolIT.getMaintainCreateGroup}`,
      null,
      body,
      null,
      null
    );
  }

  static deleteGroup(body) {
    return request(
      "DELETE",
      `${API_URL}${routes.schoolIT.school_it_gr_delete}`,
      null,
      body,
      null,
      null
    );
  }

  static getStudentForMaintainGroup(body) {
    return request(
      "POST",
      `${API_URL}${routes.schoolIT.getStudentForMaintainGroup}`,
      null,
      body,
      null,
      null
    );
  }

  static addStudentInGroup(body) {
    return request(
      "POST",
      `${API_URL}${routes.schoolIT.addStudentInGroup}`,
      null,
      body,
      null,
      null
    );
  }

  static deleteStudentFromGroup(body) {
    return request(
      "POST",
      `${API_URL}${routes.schoolIT.school_it_student_del}`,
      null,
      body,
      null,
      null
    );
  }

  static getMessageForStudentInIT(obj) {
    return request(
      "GET",
      `${API_URL}${routes.schoolIT.getMessageForSchoolIt}?page=${obj.page}&event_type=${obj.event_type}&counselor_id=${obj.counselor_id}&school_id=${obj.school_id}`,
      null,
      null,
      null,
      null
    );
  }

  static deleteMessageForStudent(body) {
    return request(
      "DELETE",
      `${API_URL}${routes.schoolIT.deleteStudentMessage}`,
      null,
      body,
      null,
      null
    );
  }

  static sendMessageToStudent(id, body) {
    return request(
      "POST",
      `${API_URL}${routes.sendMessage.SEND_MESSAGE}/${id}/reply`,
      null,
      body,
      null,
      null
    );
  }

  static sendSftpProcess(body) {
    return request(
      "POST",
      `${API_URL}${routes.sendSftp.SEND_SFTP}`,
      null,
      body,
      null,
      null
    );
  }

  static getStudentListForMessage(obj) {
    return request(
      "GET",
      `${API_URL}${routes.schoolIT.downloadStudent}?role=${obj.role}&q=${obj.q}`,
      null,
      null,
      null,
      null
    );
  }

  static getMessageForGroupInIT(obj) {
    return request(
      "GET",
      `${API_URL}${routes.counselor.getDeleteGroupMessage}?page=${obj.page}`,
      null,
      null,
      null,
      null
    );
  }

  static getGroupForCreateMessage(obj) {
    return request(
      "GET",
      `${API_URL}${routes.counselor.getGroupName}?counselor_id=${obj.counselor_id}&q=${obj.q}&school_id=${obj.school_id}`,
      null,
      null,
      null,
      null
    );
  }

  static getCounselorName(value, counselor, schoolId) {
    return request(
      "GET",
      `${API_URL}${routes.schoolIT.getCounselor}?q=${value}&role=${counselor}&school_id=${schoolId}`,
      null,
      null,
      null,
      null
    );
  }

  static getActivitiesList(value, selected) {
    return request(
      "GET",
      `${API_URL}${routes.schoolIT.getActivities}?${selected}activity=${value}`,
      null,
      null,
      null,
      null
    );
  }

  static getAthleticsList(value,selected) {
    return request(
      "GET",
      `${API_URL}${routes.schoolIT.getActivities}?${selected}athletics=${value}`,
      null,
      null,
      null,
      null
    );
  }

  static saveStudent(body) {
    return request(
      "POST",
      `${API_URL}${routes.schoolIT.saveStudent}`,
      null,
      body,
      null,
      null
    );
  }

  static goalPage(body) {
    return request(
      "POST",
      `${API_URL}${routes.schoolIT.goalPage}`,
      null,
      body,
      null,
      null
    );
  }

  static coursePage(body) {
    return request(
      "POST",
      `${API_URL}${routes.schoolIT.coursePage}`,
      null,
      body,
      null,
      null
    );
  }

  static duplicateGoal(body) {
    return request(
      "POST",
      `${API_URL}${routes.schoolIT.duplicateGoal}`,
      null,
      body,
      null,
      null
    );
  }

  static goalRequirement(body) {
    return request(
      "POST",
      `${API_URL}${routes.schoolIT.goalrequirement}`,
      null,
      body,
      null,
      null
    );
  }

  static getCourse(id) {
    return request(
      "GET",
      `${API_URL}${routes.schoolIT.getSelectedCourse}/${id}`,
      null,
      null,
      null,
      null
    );
  }

  static getAllCourse(schoolId) {
    return request(
      "GET",
      `${API_URL}${routes.schoolIT.getSelectedCourse}?school=${schoolId}`,
      null,
      null,
      null,
      null
    );
  }

  static getGoalDetail(id) {
    return request(
      "GET",
      `${API_URL}${routes.schoolIT.getGoalDetail}/${id}`,
      null,
      null,
      null,
      null
    );
  }

  static createCourse(body) {
    return request(
      "POST",
      `${API_URL}${routes.schoolIT.getSelectedCourse}`,
      null,
      body,
      null,
      null
    );
  }

  static updateCourse(body,id) {
    return request(
      "PATCH",
      `${API_URL}${routes.schoolIT.getSelectedCourse}/${id}`,
      null,
      body,
      null,
      null
    );
  }

  static deleteCourseRequirement(body) {
    return request(
      "POST",
      `${API_URL}${routes.schoolIT.getSelectedCourse}/requirement`,
      null,
      body,
      null,
      null
    );
  }

  static deleteGoalRequirement(body) {
    return request(
      "POST",
      `${API_URL}${routes.schoolIT.getGoalDetail}/requirement`,
      null,
      body,
      null,
      null
    );
  }

  static deleteCourse(body) {
    return request(
      "POST",
      `${API_URL}${routes.schoolIT.getSelectedCourse}/delete`,
      null,
      body,
      null,
      null
    );
  }

  static deleteGoal(body) {
    return request(
      "POST",
      `${API_URL}${routes.schoolIT.getGoalDetail}/delete`,
      null,
      body,
      null,
      null
    );
  }

  static deleteCourseConfirmation(body) {
    return request(
      "POST",
      `${API_URL}${routes.schoolIT.getSelectedCourse}/course-delete`,
      null,
      body,
      null,
      null
    );
  }

  static deleteGoalConfirmation(body) {
    return request(
      "POST",
      `${API_URL}${routes.schoolIT.deleteGoalDetail}`,
      null,
      body,
      null,
      null
    );
  }

  static undoCourseDelete(body) {
    return request(
      "POST",
      `${API_URL}${routes.schoolIT.getSelectedCourse}/undo`,
      null,
      body,
      null,
      null
    );
  }

  static undoGoalDelete(body) {
    return request(
      "POST",
      `${API_URL}${routes.schoolIT.getGoalDetail}/undo`,
      null,
      body,
      null,
      null
    );
  }

  static listOfCourse(id) {
    return request(
      "GET",
      `${API_URL}${routes.schoolIT.listOfCourse}?school=${id}`,
      null,
      null,
      null,
      null
    );
  }

  static listOfOperators() {
    return request(
      "GET",
      `${API_URL}${routes.schoolIT.fieldOperators}`,
      null,
      null,
      null,
      null
    );
  }

  static getDataSrcPage(body) {
    return request(
      "POST",
      `${API_URL}${routes.schoolIT.dataSrcPage}`,
      null,
      body,
      null,
      null
    );
  }

  static deleteDataSourceRequirement(body) {
    return request(
      "POST",
      `${API_URL}${routes.schoolIT.DataSrc}/requirements`,
      null,
      body,
      null,
      null
    );
  }

  static deleteDataSource(body) {
    return request(
      "POST",
      `${API_URL}${routes.schoolIT.DataSrc}/datasrc-delete`,
      null,
      body,
      null,
      null
    );
  }

  static selectedDataSource(id) {
    return request(
      "GET",
      `${API_URL}${routes.schoolIT.DataSrc}/${id}`,
      null,
      null,
      null,
      null
    );
  }

  static getDataSrc(id) {
    return request(
      "GET",
      `${API_URL}${routes.schoolIT.DataSrc}?school_id=${id}`,
      null,
      null,
      null,
      null
    );
  }

  static getDataUploadHistory(body) {
    return request(
      "POST",
      `${API_URL}${routes.schoolIT.uploadDataHistory}`,
      null,
      body,
      null,
      null
    );
  }
  static deleteHistoryItem(body) {
    return request(
      "POST",
      `${API_URL}${routes.schoolIT.deleteHistoryItem}`,
      null,
      body,
      null,
      null
    );
  }
  static runStatusDataSrc(body) {
    return request(
      "POST",
      `${API_URL}${routes.schoolIT.runStatusDataSrc}`,
      null,
      body,
      null,
      null
    );
  }

  static uploadStudentData(body) {
    return request(
      "POST",
      `${API_URL}${routes.schoolIT.uploadStudentData}`,
      null,
      body,
      null,
      null
    );
  }
  static getConfigureMapList(body) {
    return request(
      "POST",
      `${API_URL}${routes.schoolIT.mapCollegeConfigurelist}`,
      null,
      body,
      null,
      null
    );
  }

  static getGoalFieldTypes() {
    return request(
      "GET",
      `${API_URL}${routes.schoolIT.goalFieldTypes}`,
      null,
      null,
      null,
      null
    );
  }

  static getDataSrcCol(id) {
    return request(
      "GET",
      `${API_URL}${routes.schoolIT.dataSourceCol}?datasource=${id}`,
      null,
      null,
      null,
      null
    );
  }

  static getArithemeticOperator() {
    return request(
      "GET",
      `${API_URL}${routes.schoolIT.arithemetic}`,
      null,
      null,
      null,
      null
    );
  }

  static getFieldTypes() {
    return request(
      "GET",
      `${API_URL}${routes.schoolIT.fieldTypes}`,
      null,
      null,
      null,
      null
    );
  }

  static goalFieldForCreateGoal(id) {
    return request(
      "GET",
      `${API_URL}${routes.schoolIT.goalFieldForCreateGoal}?school=${id}`,
      null,
      null,
      null,
      null
    );
  }

  static uploadAfter(body) {
    return request(
      "POST",
      `${API_URL}${routes.schoolIT.uploadFileAfter}`,
      null,
      body,
      null,
      null
    );
  }

  static saveMapCollege(body) {
    return request(
      "POST",
      `${API_URL}${routes.schoolIT.saveMapSchoolCollege}`,
      null,
      body,
      null,
      null
    );
  }

  static saveModalOption(body) {
    return request(
      "POST",
      `${API_URL}${routes.schoolIT.saveMapForModalOptions}`,
      null,
      body,
      null,
      null
    );
  }

  static mapList(body) {
    return request(
      "POST",
      `${API_URL}${routes.schoolIT.mapList}`,
      null,
      body,
      null,
      null
    );
  }

  // static getGoalFieldNames(id) {
  //   return request(
  //     "GET",
  //     `${API_URL}${routes.schoolIT.goalFieldForCreateGoal}?school=${id}`,
  //     null,
  //     null,
  //     null,
  //     null
  //   );
  // }

  static saveGoalMap = (body) => {
    return request(
      "POST",
      `${API_URL}${routes.schoolIT.goalFieldForSaveGoal}`,
      null,
      body,
      null,
      null
    );
  };

  static removeGoalMap = (body) => {
    return request(
      "POST",
      `${API_URL}${routes.schoolIT.goalFieldForRemoveGoal}`,
      null,
      body,
      null,
      null
    );
  };

  static saveDataSource = (body) => {
    return request(
      "POST",
      `${API_URL}${routes.schoolIT.DataSrc}`,
      null,
      body,
      null,
      null
    );
  };

  static updateDataSource = (body,id) => {
    return request(
      "PATCH",
      `${API_URL}${routes.schoolIT.DataSrc}/${id}`,
      null,
      body,
      null,
      null
    );
  };

  static getGoalPublisher = (schoolId, text) => {
    return request(
      "GET",
      `${API_URL}${routes.counselor.goalPublishManage}?manage=${schoolId}&q=${text}`,
      null,
      null,
      null,
      null
    );
  };

  static coursePublished = (body) => {
    return request(
      "POST",
      `${API_URL}${routes.schoolIT.coursePublish}`,
      null,
      body,
      null,
      null
    );
  };

  static goalPublished = (body) => {
    return request(
      "POST",
      `${API_URL}${routes.schoolIT.goalPublish}`,
      null,
      body,
      null,
      null
    );
  };

  static goalPublish = (body) => {
    return request(
      "POST",
      `${API_URL}${routes.schoolIT.goalPublish1}`,
      null,
      body,
      null,
      null
    );
  }

  static calculateGoalPublish = (body) => {
    return request(
      "POST",
      `${API_URL}${routes.schoolIT.calculateGoalPublish}`,
      null,
      body,
      null,
      null
    );
  };

  static updateDefaultGoal = (body) => {
    return request(
      "POST",
      `${API_URL}${routes.schoolIT.updateDefaultGoal}`,
      null,
      body,
      null,
      null
    );
  };

  static createGoalMap = (body) => {
    return request(
      "POST",
      `${API_URL}${routes.schoolIT.createGoalMap}`,
      null,
      body,
      null,
      null
    );
  };

  static saveNewCourse = (body) => {
    return request(
      "POST",
      `${API_URL}${routes.schoolIT.getSelectedCourse}`,
      null,
      body,
      null,
      null
    );
  };

  static saveNewGoalField = (body) => {
    return request(
      "POST",
      `${API_URL}${routes.schoolIT.goalFieldForCreateGoal}`,
      null,
      body,
      null,
      null
    );
  };

  static copyCourseMap = (courseId, schoolId) => {
    return request(
      "GET",
      `${API_URL}${routes.schoolIT.courseMap}?map=${courseId}&school=${schoolId}`,
      null,
      null,
      null,
      null
    );
  };

  static sendMessage = (body) => {
    return request(
      "POST",
      `${API_URL}${routes.sendMessage.SEND_MESSAGE}`,
      null,
      body,
      null,
      null
    );
  };

  static duplicateCourse = (body) => {
    return request(
      "POST",
      `${API_URL}${routes.schoolIT.duplicateCourse}`,
      null,
      body,
      null,
      null
    );
  };

  static listUpdateMapCourse = (body) => {
    return request(
      "POST",
      `${API_URL}${routes.schoolIT.listUpdateMapCourse}`,
      null,
      body,
      null,
      null
    );
  };

  static getDifferentGoal(id) {
    return request(
      "GET",
      `${API_URL}${routes.schoolIT.status_calculation}?school_id=${id}`,
      null,
      null,
      null,
      null
    );
  };

  static getStudentStatisticsData = (id) => {
    return request(
      "GET", 
      `${API_URL}${routes.schoolIT.getStudentStatisticsData}?school_id=${id}`,
       null, 
       null,
       null, 
       null
    );
  };

  static updateStudent(body,id) {
    return request(
      "PUT",
      `${API_URL}${routes.schoolIT.saveStudent}`,
      null,
      body,
      null,
      null
    );
  };

  static modifyStudent(student) {
    return request(
      "GET",
      `${API_URL}${routes.schoolIT.studentModifyData}?student_id=${student}`,
      null,
      null,
      null,
      null
    );
  };

  static searchStudent(obj) {
    return request(
      "GET",
      `${API_URL}${routes.schoolIT.saveStudent}?q=${obj.q}&role=${obj.role}&school_id=${obj.school}`,
      null,
      null,
      null,
      null
    );
  };

  static deleteStudent(id){
    return request(
      "DELETE",
      `${API_URL}${routes.schoolIT.saveStudent}?id=${id}`,
      null,
      null,
      null,
      null
    );
  };

  static mergeStudent(body) {
    return request(
      "POST",
      `${API_URL}${routes.schoolIT.mergeStudent}`,
      null,
      body,
      null,
      null
    );
  };
};