import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect, useRef } from "react";
import { Form, FormGroup, Input, Label, Tooltip } from "reactstrap";
import { CommonButton } from "../../components";
import Loading from "../../components/Loading/Loading";
import RegisterService from "../../services/RegisterService";
import "./CreateStudentAccount.scss";
import swal from "sweetalert";
import { useHistory } from "react-router-dom";
import { CommonMessages } from "../../constants/CommonMessages";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { Diversity1 } from "@mui/icons-material";

const IMG_URL = process.env.REACT_APP_IMAGE_ASSET_URL;

// is_middle_school : false => higherSchoolData
// const gradeData = [
//   { name: "01", value: "01" },
//   { name: "02", value: "02" },
//   { name: "03", value: "03" },
//   { name: "04", value: "04" },
//   { name: "05", value: "05" },
//   { name: "06", value: "06" },
//   { name: "07", value: "07" },
//   { name: "08", value: "08" },
//   { name: "09", value: "09" },
//   { name: "10", value: "10" },
//   { name: "11", value: "11" },
//   { name: "12", value: "12" },
// ];
// const higherSchoolData = [
//   { name: "01", value: "01" },
//   { name: "02", value: "02" },
//   { name: "03", value: "03" },
//   { name: "04", value: "04" },
//   { name: "05", value: "05" },
//   { name: "06", value: "06" },
//   { name: "07", value: "07" },
//   { name: "08", value: "08" },
//   { name: "09", value: "09" },
//   { name: "10", value: "10" },
//   { name: "11", value: "11" },
//   { name: "12", value: "12" },
// ];

const CreateStudentAccount = () => {
  const history = useHistory();

  const initState = {
    schoolName: "",
    firstName: "",
    lastname: "",
    stdId: "",
    gender: "",
    semester: "",
    collegeEmail: "",
    password: "",
    confirmPassword: "",
    mobileNo: "",
    countryName: "United States",
    selectedState: "",
  };
  const [stdForm, setStdForm] = useState(initState);
  const [show, setShow] = useState(false);
  const [schoolArr, setSchoolArr] = useState([]);
  const [selectedCollege, setSelectedCollege] = useState([]);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const [counstData, setCounstData] = useState([]);

  const [agreeCheck, setAgreeCheck] = useState(true);
  const [firstAttendCheck, setFirstAttendCheck] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showPswd, setShowPswd] = useState(false);
  const [showconfirmPswd, setShowconfirmPswd] = useState(false);

  // Next click state
  const [detailArr, setDetailArr] = useState({});
  const [gradeArr, setGradeArr] = useState([]);
  const [states, setStates] = useState([]);
  const countryRef = useRef();
  const timeoutRef = useRef();

  const toggleTooltip = () => {
    setTooltipOpen(!tooltipOpen);
  };

  const handleChange = (e) => {
    let { name, value } = e.target;
    const lowercasedValue = value;
    setStdForm({ ...stdForm, [name]: lowercasedValue });

    if (name === "schoolName") {
      // setStdForm({ ...stdForm, schoolName: value, firstName: '', lastname: '', stdId: '',currentGrade: '' });
      if (value !== "" && value.length >= 3) {
        setLoading(false);
        let query = {
          q: value,
        };
        RegisterService.getSchoolNameList(query)
          .then((data) => {
            setSchoolArr(data?.data?.objects);

            let schoolData = data?.data?.objects || [];

            if (schoolData && schoolData.length > 0) {
              let selectedSchoolData =
                schoolData.filter(
                  (schlData) =>
                    schlData.name.toLowerCase().trim() ===
                    value.toLowerCase().trim()
                ) || [];
              setSelectedCollege(selectedSchoolData);

              // set Grade Array
              // if (selectedSchoolData && selectedSchoolData.length > 0) {
              //   if (selectedSchoolData[0].is_middle_school === false) {
              //     setGradeArr(higherSchoolData);
              //   } else {
              //     setGradeArr(gradeData);
              //   }
              // } else {
              //   setGradeArr([]);
              //   setCounstData([]);
              //   setShow(false);
              // }
            }

            setLoading(false);
          })
          .catch((error) => {
            //console.log("error from the service", error);
            setLoading(false);
          });
      } else {
        setSchoolArr([]);
        setSelectedCollege([]);
        setGradeArr([]);
        setCounstData([]);
        setShow(false);
      }
    }
  };

  const handleChangeFirstAttend = (e) => {
    setFirstAttendCheck(e.target.checked);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Next Click
    let userData;
    if (!show) {
      if (selectedCollege.length === 0) {
        swal("", CommonMessages.schoolNameMatchError, "error");
      } else if (selectedCollege && selectedCollege.length > 0) {
        setLoading(true);
        let obj = {
          first_name: stdForm.firstName,
          last_name: stdForm.lastname,
          college_id: selectedCollege[0].id || "",
          student_id: stdForm.stdId,
        };
        RegisterService.checkStudentDetail(obj)
          .then((res) => {
            if (res.status === 200) {
              swal({
                text: res?.data?.status,
                icon: "error",
                buttons: {
                  OK: true,
                },
                closeOnClickOutside: false,
              }).then((value) => {
                if (value === "OK") {
                  history.push("/login");
                }
              });
            } else if (res.status === 201 && res.data?.flag === 1) {
              swal("", CommonMessages.userDetailMatchSuccess, "success");

              userData = res?.data?.user_id || {};
              setFirstAttendCheck(res?.data?.user_id?.first_generation);
              setAgreeCheck(res?.data?.user_id?.student_career_opt_in);
              setStdForm((prev) => {
                return {
                  ...prev,
                  firstName: userData?.first_name,
                  lastname: userData?.last_name,
                  stdId: userData?.student_id,
                  semester:
                    userData?.semester?.toString().padStart(2, "0") || "",
                  gender: userData?.gender,
                  counselor: userData?.counselor_name,
                  collegeEmail: userData?.email,
                  mobileNo: userData?.mobile,
                  password: "",
                  confirmPassword: "",
                  counselorId: userData?.counselor_id,
                  role: userData?.role,
                  countryName: userData?.country,
                  selectedState: userData?.state || "",
                };
              });
              setDetailArr(res?.data);
              setShow(true);
            }
            setLoading(false);
          })
          .catch((error) => {
            //console.log("error from the service", error);
            setLoading(false);
          });
      }
    }

    if (show) {
      if (stdForm.password !== stdForm.confirmPassword) {
        swal("", CommonMessages.passwordMatchError, "error");
      } else if (selectedCollege) {
        setLoading(true);
        let createStudentObj = {
          counselor: [stdForm.counselorId] || [],
          semester: stdForm.semester || "",
          email: stdForm.collegeEmail || "",
          first_name: stdForm.firstName || "",
          // flag: counstFlag,
          country: "United States",
          state: stdForm.selectedState,
          // group_id: stdForm.group || null,
          last_name: stdForm.lastname || "",
          gender: stdForm.gender,
          mobile: stdForm.mobileNo || "",
          password: stdForm.password || "",
          confirm_password: stdForm.confirmPassword || "",

          // school: selectedCollege && selectedCollege.length > 0 ? selectedCollege[0].id : '',
          // school_codename: selectedCollege && selectedCollege.length > 0 ? selectedCollege[0].codename : '',
          college_id:
            selectedCollege && selectedCollege.length > 0
              ? selectedCollege[0].id
              : "",
          // school_name: stdForm.schoolName || '',
          // school_upload:
          //   selectedCollege && selectedCollege.length > 0
          //     ? selectedCollege[0].upload_csv
          //     : false,

          first_generation: firstAttendCheck || false,
          // student_id: detailArr?.user_id?.student_id || null, // user detail id
          student_career_opt_in: agreeCheck,
          user_ptr_id: detailArr?.user_id?.user_ptr_id || "",
          type: stdForm?.role, // "student"
        };

        RegisterService.createStudentAccount(createStudentObj)
          .then((res) => {
            if (res?.status === 200) {
              swal("", CommonMessages.studentRegSuccess, "success");
              setTimeout(() => {
                history.push("/login");
              }, 3000);
            } else {
              swal(" ", "error to register", "error");
            }
            setLoading(false);
          })
          .catch((error) => {
            swal("", error?.response?.data?.message, "error");
            setLoading(false);
          });
      }
    }
  };

  useEffect(() => {
    setLoading(true);
    RegisterService.getStateDataList()
      .then((res) => {
        setStates(res?.data?.state);
        setLoading(false);
      })
      .catch((error) => {
        //console.log("Error fetching states for United States:", error);
        setLoading(false);
      });
  }, []);

  const handleStateChange = (e) => {
    const { value } = e.target;
    setStdForm({ ...stdForm, selectedState: value });
  };

  return (
    <>
      {loading && <Loading />}
      <div className="studentAccountWrap">
        <div className="studentAccountInner text-center">
          <img
            src={IMG_URL + "white-transfer-logo.png"}
            alt="whiteLogo"
            className="logoImg img-fluid"
          />
          <div className="studentAccountBlockwrap">
            <div className="studentAccountBlockInner">
              <Form onSubmit={handleSubmit} autoComplete="off">
                <div className="boxWrap">
                  <div className="boxwrapInner">
                    <h2 className="accountTitle text-center">
                      Create your student connect account
                    </h2>
                    <FormGroup className="text-left">
                      <Label className="customLabel">
                        Community College Name{" "}
                        <span className="requiredField">*</span>
                      </Label>
                      <input
                        required
                        placeholder="Enter your community college name"
                        className="form-control custom-select"
                        type="text"
                        list="listOfSchool"
                        name="schoolName"
                        value={stdForm.schoolName || ""}
                        onChange={handleChange}
                      />
                      <datalist id="listOfSchool" className="col-12">
                        {schoolArr && schoolArr.length > 0
                          ? schoolArr.map((item, index) => {
                              return <option value={item.name} key={index} />;
                            })
                          : ""}
                      </datalist>
                    </FormGroup>
                    <FormGroup className="text-left">
                      <Label className="customLabel">
                        First Name <span className="requiredField">*</span>
                      </Label>
                      <Input
                        required
                        className="form-control"
                        placeholder="Enter your first name"
                        type="text"
                        name="firstName"
                        value={stdForm.firstName || ""}
                        onChange={handleChange}
                      />
                    </FormGroup>
                    <FormGroup className="text-left">
                      <Label className="customLabel">
                        Last Name <span className="requiredField">*</span>
                      </Label>
                      <Input
                        required
                        className="form-control"
                        placeholder="Enter your last name"
                        type="text"
                        name="lastname"
                        value={stdForm.lastname || ""}
                        onChange={handleChange}
                      />
                    </FormGroup>
                    {/* {show ?
                                            <FormGroup className='text-left'>
                                                <Label className='customLabel'>Middle Name </Label>
                                                <Input
                                                    className="form-control"
                                                    placeholder='Enter your middle name'
                                                    type='text'
                                                    name='middlename'
                                                    value={stdForm.middlename || ''}
                                                    onChange={handleChange}
                                                />
                                            </FormGroup>
                                            : ''} */}
                    <FormGroup className="text-left">
                      <Label className="customLabel">
                        Student ID <span className="requiredField">*</span>
                      </Label>
                      <Input
                        required
                        className="form-control"
                        placeholder="Enter your student Id"
                        type="text"
                        name="stdId"
                        value={stdForm.stdId || ""}
                        onChange={handleChange}
                        // onKeyPress={(e) => {
                        //     if (!/[0-9]/.test(e.key)) {
                        //         e.preventDefault();
                        //     }
                        // }}
                      />
                    </FormGroup>
                    {show ? (
                      <>
                        <FormGroup className="text-left">
                          <Label className="customLabel">Gender</Label>
                          <Input
                            className="form-control"
                            type="select"
                            name="gender"
                            value={stdForm.gender}
                            onChange={handleChange}
                          >
                            <option value={""}>Select gender</option>
                            <option value={"Male"}>Male</option>
                            <option value={"Female"}>Female</option>
                            <option value={"Non-Binary"}>Non-Binary</option>
                          </Input>
                        </FormGroup>
                        <FormGroup className="text-left">
                          <Label className="customLabel">Semester</Label>
                          <Input
                            className="form-control"
                            type="text"
                            name="semester"
                            value={stdForm.semester}
                            disabled
                          />
                        </FormGroup>
                        <FormGroup className="text-left">
                          <Label for="country">
                            Country <span className="requiredField">*</span>
                          </Label>
                          <Input
                            required
                            className="form-control"
                            placeholder="Enter your country name"
                            type="text"
                            name="country"
                            ref={countryRef}
                            list="listOfCountry"
                            value={"United States"}
                            autoComplete="off"
                            disabled
                          />
                        </FormGroup>
                        <FormGroup className="text-left">
                          <Label for="state">
                            State <span className="requiredField">*</span>
                          </Label>
                          <Input
                            required
                            className="form-control"
                            type="select"
                            name="state"
                            value={stdForm.selectedState || ""}
                            onChange={handleStateChange}
                          >
                            <option value="" disabled>
                              Select your state name
                            </option>
                            {states.map((stat) => (
                              <option key={stat.name} value={stat.name}>
                                {stat.name}
                              </option>
                            ))}
                          </Input>
                        </FormGroup>
                        <FormGroup className="text-left">
                          <Label className="customLabel">
                            Counselor <span className="requiredField">*</span>
                          </Label>
                          <Input
                            required
                            className="form-control"
                            placeholder="Enter your counselor name"
                            type="text"
                            name="counselorName"
                            value={stdForm.counselor || ""}
                            onChange={handleChange}
                            disabled
                          />
                        </FormGroup>
                        <FormGroup className="text-left">
                          <Label className="customLabel">
                            College Email Address{" "}
                            <span className="requiredField">*</span>
                            <FontAwesomeIcon
                              icon={faInfoCircle}
                              id="TooltipCollegeEmail"
                              style={{
                                marginLeft: "5px",
                                cursor: "pointer",
                              }}
                            />
                          </Label>
                          <Tooltip
                            placement="right"
                            isOpen={tooltipOpen}
                            target="TooltipCollegeEmail"
                            toggle={toggleTooltip}
                          >
                            Enter student email ID provided by college
                          </Tooltip>
                          <Input
                            required
                            // pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
                            disabled
                            className="form-control"
                            placeholder="Enter your college email address"
                            type="email"
                            name="collegeEmail"
                            value={stdForm.collegeEmail || ""}
                            onChange={handleChange}
                          />
                        </FormGroup>
                        <FormGroup className="text-left">
                          <Label className="customLabel">
                            Password <span className="requiredField">*</span>
                          </Label>
                          <Input
                            required
                            className="form-control"
                            placeholder="Enter your password"
                            type={showPswd ? "text" : "password"}
                            name="password"
                            minLength="8"
                            value={stdForm.password || ""}
                            onChange={handleChange}
                          />
                          <FontAwesomeIcon
                            icon={showPswd ? faEye : faEyeSlash}
                            onClick={() => setShowPswd(!showPswd)}
                            style={{
                              position: "absolute",
                              right: "13px",
                              top: "41px",
                            }}
                          />
                        </FormGroup>
                        <FormGroup className="text-left">
                          <Label className="customLabel">
                            Confirm Password{" "}
                            <span className="requiredField">*</span>
                          </Label>
                          <Input
                            required
                            className="form-control"
                            placeholder="Re-enter your password"
                            type={showconfirmPswd ? "text" : "password"}
                            name="confirmPassword"
                            minLength="8"
                            value={stdForm.confirmPassword || ""}
                            onChange={handleChange}
                          />
                          <FontAwesomeIcon
                            icon={showconfirmPswd ? faEye : faEyeSlash}
                            onClick={() => setShowconfirmPswd(!showconfirmPswd)}
                            style={{
                              position: "absolute",
                              right: "13px",
                              top: "41px",
                            }}
                          />
                        </FormGroup>
                        <FormGroup className="text-left">
                          <Label className="customLabel">Mobile Number
                          <span className="requiredField">*</span>
                          </Label>
                          <Input
                            required
                            className="form-control"
                            placeholder="Enter your mobile number"
                            type="text"
                            name="mobileNo"
                            value={stdForm.mobileNo || ""}
                            onChange={handleChange}
                            // onKeyPress={(e) => {
                            //     if (!/[0-9]/.test(e.key)) {
                            //         e.preventDefault();
                            //     }
                            // }}
                          />
                          <p className="hintText">Hint: +13238511115</p>
                        </FormGroup>
                        <FormGroup
                          check
                          className="text-left mt-4 mb-2 customCheckbox"
                        >
                          <Label check>
                            <Input
                              type="checkbox"
                              name="firstAttendCheck"
                              checked={firstAttendCheck}
                              onChange={handleChangeFirstAttend}
                            />
                            I would be the first in my family to attend college.
                          </Label>
                        </FormGroup>

                        <FormGroup check className="text-left customCheckbox">
                          <Label check>
                            <Input
                              type="checkbox"
                              name="agreeCheck"
                              checked={agreeCheck}
                              onChange={() => setAgreeCheck(!agreeCheck)}
                            />
                            I agree to share my goals and academic progress with
                            college admissions professionals to help me to
                            college for a career and employment. I would like
                            their support to my academic and career degree or certificate
                            through SMS communication.
                          </Label>
                        </FormGroup>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="btnWrap">
                    <div className="btnWrapper">
                      <div className="btnInner">
                        <CommonButton
                          name="Cancel"
                          btnClass="secondaryBtn w-100 stdFormBtn"
                          onClick={() => {
                            if (show) {
                              setShow(false);
                            } else {
                              history.goBack();
                            }
                          }}
                        />
                      </div>
                      {show ? (
                        <div className="btnInner">
                          <CommonButton
                            type="submit"
                            name="Create your account"
                            btnClass="primaryBtn w-100 stdFormBtn"
                            //   onClick={}
                          />
                        </div>
                      ) : (
                        <div className="btnInner">
                          <CommonButton
                            type="submit"
                            name="Next"
                            btnClass="primaryBtn w-100 stdFormBtn"
                            //   onClick={}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateStudentAccount;
