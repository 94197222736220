import { request } from "./Request";
import { routes } from "../constants/constant.route";
import { urlConstants } from "../utils/urlConstants";

const { API_URL } = urlConstants;
const {
  GET_DISTRICT,
  GET_DISTRICT_BASE_SCHOOL,
  PERSONAL_STUDENT_REGISTER,
} = routes;
const { GET_SCHOOL_LIST } = routes.SCHOOL;
const {
  STUDENT_DETAIL_CHECK,
  REGISTER_COUNST,
  CREATE_STUDENT_ACCOUNT,
  COUNTRY_CHECK,
  STATE_CHECK,
  SEMESTER_DATA,
  PERSONAL_EMAIL_CHECK,
  PERSONAL_OTP_VERIFY,
} = routes.STUDENT_REGISTER;

export default class RegisterService {
  // SChool Name LIST
  static getSchoolNameList(queryValue) {
    let { q } = queryValue;
    return request(
      "GET",
      `${API_URL}${GET_SCHOOL_LIST}?q=${q}`,
      null,
      null,
      null,
      null,
      null
    );
  }

  // Next click check student detail
  static checkStudentDetail(body) {
    return request(
      "POST",
      `${API_URL}${STUDENT_DETAIL_CHECK}`,
      null,
      body,
      null,
      null,
      null
    );
  }

  // Country
  // static getCountryDataList(queryValue) {
  //     let { q } = queryValue;
  //     return request('GET', `${API_URL}${COUNTRY_CHECK}?q=${q}`, null, null, null, null, null,)
  // }

  // State
  static getStateDataList() {
    return request(
      "GET",
      `${API_URL}${STATE_CHECK}?country=UnitedStates`,
      null,
      null,
      null,
      null,
      null
    );
  }

  static getSemesterList() {
    return request(
      "GET",
      `${API_URL}${SEMESTER_DATA}`,
      null,
      null,
      null,
      null,
      null
    );
  }

  static getRegisterCounst(queryValue) {
    let { role, school, student } = queryValue;
    return request(
      "GET",
      `${API_URL}${REGISTER_COUNST}?role=${role}&school=${school}&student=${student}`,
      null,
      null,
      null,
      null,
      null
    );
  }

  // Create Student Account
  static createStudentAccount(body) {
    return request(
      "POST",
      `${API_URL}${CREATE_STUDENT_ACCOUNT}`,
      null,
      body,
      null,
      null,
      null
    );
  }

  //Personal Email Check
  static personalEmailCheck(body) {
    return request(
      "POST",
      `${API_URL}${PERSONAL_EMAIL_CHECK}`,
      null,
      body,
      null,
      null,
      null
    );
  }

  //Personal OTP Verify
  static personalOTPVerify(body) {
    return request(
      "POST",
      `${API_URL}${PERSONAL_OTP_VERIFY}`,
      null,
      body,
      null,
      null,
      null
    );
  }

  //Resend OTP Verify
  static resendOTPVerify(body) {
    return request(
      "POST",
      `${API_URL}${PERSONAL_EMAIL_CHECK}`,
      null,
      body,
      null,
      null,
      null
    );
  }

  // get District
  static getDistrictList(queryValue) {
    let { q } = queryValue;
    return request(
      "GET",
      `${API_URL}${GET_DISTRICT}?q=${q}`,
      null,
      null,
      null,
      null,
      null
    );
  }

  // get district base school
  static getDistrictbaseSchoolNameList = (queryValue) => {
    //console.log(queryValue);
    return request(
      "GET",
      `${API_URL}${GET_DISTRICT_BASE_SCHOOL}?dist_id=${queryValue}`,
      null,
      null,
      null,
      null,
      null
    );
  };

  //Personal Student Register
  static personalStudentRegister = (body) => {
    return request(
      "POST",
      `${API_URL}${PERSONAL_STUDENT_REGISTER}`,
      null,
      body,
      null,
      null,
      null
    );
  };
}
