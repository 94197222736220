import { request } from '../Request';
import { routes } from '../../constants/constant.route';
import { urlConstants } from "../../utils/urlConstants";
const {API_URL } = urlConstants;

export default class GetSuperCounselorStudentService {
    static getStudent(params) {
        const queryString = new URLSearchParams(params).toString();
        return request(
            "GET", 
            `${API_URL}${routes.superCounselor.getStudentForCounselor}?${queryString}`, 
            null, 
            null, 
            null, 
            null
        );
    }

    static getStudentStatistics(id) {
        return request("GET", `${API_URL}${routes.superCounselor.getStudentStatisticsData}?college_id=${id}`, null, null, null, null);
    }

    static goalPublishManage(obj) {
        return request("GET", `${API_URL}${routes.counselor.goalPublishManage}?school_id=${obj.school_id}`, null, null, null, null);
    }

    static studentStatistics(obj) {
        return request("GET", `${API_URL}${routes.superCounselor.download_status}?school_id=${obj.school_id}&goal_id=${obj.goal_id}`, null, null, null, null);
    }

    static getMyGroupList(id) {
        return request("GET", `${API_URL}${routes.superCounselor.getMyGroupList}?group_id=${id}`, null, null, null, null);
    }

    static addStudentToGroup(body){
        return request("POST", `${API_URL}${routes.counselor.getAddStudentGroup}`, null, body, null, null);
    }

    static deleteStudentToGroup(body){
        return request("DELETE", `${API_URL}${routes.counselor.getRemoveStudentGroup}`, null, body, null, null);
    }
}