import React, { Fragment, useState, useEffect, useLayoutEffect } from "react";
import SingleQuery from "../query/singlequery/SingleQuery";
import QueryService from "../../services/QueryService";
import GPASlider from "../query/gpa_slider/GPA_SLIDER.js";
import { useHistory, useLocation } from "react-router";
import CloseOutlined from "@mui/icons-material/CloseOutlined";
import CustomLoader from "../customLoader/CustomLoader";
//import SaveModalCCC from "../saveModal/SaveModalCCC";
import ConfirmationDialog from "../confirmationModalForSiembra/ConfirmationModalForSiembra";
import ErrorModal from "../errorDisplayModal/ErrorDisplayModal";
import DropDown from "../collegequery/dropdown/DropDown";
import _ from "lodash";
import Multiselect from "multiselect-react-dropdown";
import { useSelector } from "react-redux";
import AppHeader from "../../Layout/AppHeader";
import AppSidebar from "../../Layout/AppSidebar";
export const englishMathRequirementData = [
  {
    name: "Completed English Requirement",
    list_name: "English/Math Requirement",
    value: "english",
  },
  {
    name: "Completed Math Requirement",
    list_name: "English/Math Requirement",
    value: "math",
  },
];

export const gender = [
  {
    name: "Female",
    value: "female",
  },
  {
    name: "Male",
    value: "male",
  },
  {
    name: "Non-Binary",
    value: "unknown",
  },
];
export const config = [];

const CollegeQuery = () => {
  const englishMathRequirementFromData = _.cloneDeep(
    englishMathRequirementData
  );
  const genderData = JSON.parse(JSON.stringify(gender));
  let authuser = localStorage.getItem("user");
  //let parsedUser = JSON.parse(authuser);
  let parsedUser = useSelector((state) => state.UserReducer.userObj);

  let history = useHistory();
  let location = useLocation();
  const queryData =
    location &&
    location.state &&
    location.state.state &&
    location.state.state.query
      ? location.state.state.query
      : [];
  const [inputValueForSave, setInputValueForSave] = useState("");
  const [selectedTab, setSelectedTab] = useState("customQuery");
  const [selectedQueries, setSelectedQueries] = useState([]);
  const [ethnicity, setEthnicityData] = useState();
  const [showEthData, setShowEthData] = useState();
  const [collegeData, setCollegeData] = useState();
  // const [collageData, setCollageData] = useState();
  // const [showCollageData, setShowCollageData] = useState();
  //const [englishStatus, setEnglishStatus] = useState();
  //const [mathsStatus, setMathsStatus] = useState();
  const [valueWhichExpand, setValueWhichExpanded] = useState();
  const [englishMathRequirement, setEnglishMathRequirement] = useState(
    englishMathRequirementFromData
  );
  const [collegeVal, setCollegeVal] = useState([]);
  const [academicsData, setAcademicsData] = useState();
  const [showAcademicsData, setShowAcademicsData] = useState();
  const [activitiesData, setActivitiesData] = useState();
  const [showActivitiesData, setShowActivitiesData] = useState();
  const [athleticsData, setAthleticsData] = useState();
  const [showAthleticsData, setShowAthleticsData] = useState();
  const [interestData, setInterestData] = useState();
  const [showInterestData, setShowInterestData] = useState();
  const [dasData, setDasData] = useState();
  const [showDasData, setShowDasData] = useState();
  const [
    selectedCommunityCollQueries,
    setSelectedCommunityCollQueries,
  ] = useState([]);
  const [selectedEthnicityQueries, setSelectedEthnicityQueries] = useState([]);
  const [configObject, setConfigObject] = useState([]);
  const [
    selectedEnglishMathRequirementQueries,
    setSelectedEnglishMathRequirementQueries,
  ] = useState([]);
  const [selectedAreaofStudyQueries, setSelectedAreaofStudyQueries] = useState(
    []
  );
  const [selectedAcademicsQueries, setSelectedAcademicsQueries] = useState([]);
  const [selectedAthleticsQueries, setSelectedAthleticsQueries] = useState([]);
  const [selectedActivitiesQueries, setSelectedActivitiesQueries] = useState(
    []
  );
  const [selectedInterestsQueries, setSelectedInterestsQueries] = useState([]);
  const [selectedThirdParameter, setSelectedThirdParameter] = useState([]);
  const [genderValue, setGenderValue] = useState();
  const [minGPA, setMinGPA] = useState(0);
  const [maxGPA, setMaxGPA] = useState(4);
  //const [openSaveQueryModal, setOpenSaveQueryModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openConfirmationForSiembra, setOpenConfirmationForSiembra] = useState(
    false
  );
  const [viewQueryData, setViewQueryData] = useState([queryData && queryData]);
  const [user, setUser] = useState(parsedUser);
  const [openExistingErrorModal, setOpenExistingErrorModal] = useState();
  const [page, setPage] = useState(1);

  const checkViewQuery = () => {
    if (viewQueryData && viewQueryData.length > 0) {
      let array = [];
      viewQueryData &&
        viewQueryData.map(async (item, index) => {
          for (const [key, value] of Object.entries(item)) {
            await array.push(value);
          }
          return array;
        });
      array.map((item) => {
        if (item.value === "male") {
          let selectedGender =
            genderData && genderData.filter((item) => item.value === "male");
          handleGenderCheckbox(selectedGender[0]);
        } else if (item.value === "female") {
          let selectedGender =
            genderData && genderData.filter((item) => item.value === "female");
          handleGenderCheckbox(selectedGender[0]);
        } else if (item.value === "unknown") {
          let selectedGender =
            genderData && genderData.filter((item) => item.value === "unknown");
          handleGenderCheckbox(selectedGender[0]);
        }

        if (item.config.value === "collage") {
          item &&
            item.multi_value.map((v) =>
              handleCheckBox(v, "contains", "collage")
            );
        }
        if (item.config.value === "ethnicity") {
          item.multi_value.map((v) =>
            handleCheckBox(v, "contains", "ethnicity")
          );
        }
        if (item.config.value === "academics") {
          item.multi_value.map((v) =>
            handleCheckBox(v, "contains", "academics")
          );
        }
        if (item.config.value === "athletics") {
          item.multi_value.map((v) =>
            handleCheckBox(v, "contains", "athletics")
          );
        }
        if (item.config.value === "interests") {
          item.multi_value.map((v) =>
            handleCheckBox(v, "contains", "interests")
          );
        }
        if (item.config.value === "activities") {
          item.multi_value.map((v) =>
            handleCheckBox(v, "contains", "activities")
          );
        }
        if (item.config.value === "english/math requirement") {
          item.multi_value.map((v) =>
            handleCheckBox(v, "contains", "english/math requirement")
          );
        }
        if (item.config.value === "declared area of study") {
          item.multi_value.map((v) =>
            handleCheckBox(v, "contains", "declared area of study")
          );
        }
      });
    }
  };

  useLayoutEffect(() => {
    if (viewQueryData && viewQueryData.length > 0) {
      checkViewQuery();
    }
  }, []);

  const resetQueryCriteria = () => {
    window.location.reload();
  };

  // const saveForSiembra = () => {
  //   setOpenConfirmationForSiembra(true);
  //   setOpenSaveQueryModal(false);
  // };

  const createConfig = (conf, operator = "contains", value) => {
    if (
      configObject &&
      configObject.some(
        (element) => element && element.config.value === conf.value
      )
    ) {
      configObject.forEach(async (element) => {
        if (element.config.value === conf.value) {
          element.multi_value = value;
        }
      });
    }
  };

  let gpaVal;
  if (minGPA !== 0 || maxGPA !== 4) {
    gpaVal = { min: minGPA, max: maxGPA };
  } else if (minGPA > 0 || maxGPA > 4) {
    gpaVal = { min: minGPA, max: maxGPA };
  }

  // let collegeValue;
  //   const collegeVal = () => {
  //     collegeValue = selectedCommunityCollQueries.map((item) => item.name);
  //   };
  //   if (selectedCommunityCollQueries.length > 0) {
  //     collegeVal();
  //   }

  const onSelect = (selectedList) => {
    //console.log("selectedList", selectedList);
    setCollegeVal(selectedList.map((item) => item.label));
  };

  const onRemove = (selectedList, removedItem) => {
    ////console.log(selectedList, removedItem);
  };

  useEffect(() => {
    onRemove();
  });

  let ethnicityValue;
  const ethnicityVal = () => {
    ethnicityValue = selectedEthnicityQueries.map((item) => item.name);
  };
  if (selectedEthnicityQueries.length > 0) {
    ethnicityVal();
  }

  let academicsValue;
  const academicsVal = () => {
    academicsValue = selectedAcademicsQueries.map((item) => item.name);
  };
  if (selectedAcademicsQueries.length > 0) {
    academicsVal();
  }

  let activitesValue;
  const activitesVal = () => {
    activitesValue = selectedActivitiesQueries.map((item) => item.name);
  };
  if (selectedActivitiesQueries.length > 0) {
    activitesVal();
  }

  let athleticsValue;
  const athleticsVal = () => {
    athleticsValue = selectedAthleticsQueries.map((item) => item.name);
  };
  if (selectedAthleticsQueries.length > 0) {
    athleticsVal();
  }

  let interestsValue;
  const interestsVal = () => {
    interestsValue = selectedInterestsQueries.map((item) => item.name);
  };
  if (selectedInterestsQueries.length > 0) {
    interestsVal();
  }

  let dasValue;
  const dasVal = () => {
    dasValue = selectedAreaofStudyQueries.map((item) => item.name);
  };
  if (selectedAreaofStudyQueries.length > 0) {
    dasVal();
  }
  let engValue, mathValue;
  let showName = selectedEnglishMathRequirementQueries.map((val) => val.name);
  const engVal = () => {
    if (showName.includes("Completed English Requirement")) {
      engValue = "true";
    } else if (showName.includes("Completed Math Requirement")) {
      mathValue = "true";
    }
  };
  const emReq = () => {
    if (
      showName.includes(
        "Completed English Requirement" && "Completed Math Requirement"
      )
    ) {
      engValue = "true";
      mathValue = "true";
    }
  };
  if (selectedEnglishMathRequirementQueries.length >= 2) {
    emReq();
  } else if (selectedEnglishMathRequirementQueries.length > 0) {
    engVal();
  }
  // //console.log("collegeVal", collegeVal);
  let query = {
    gender: genderValue && genderValue.name,
    ethnicities: ethnicityValue,
    gpa: gpaVal,
    english: engValue,
    maths: mathValue,
    academics: academicsValue,
    activities: activitesValue,
    athletics: athleticsValue,
    interest: interestsValue,
    das: dasValue,
  };

  collegeVal.length && (query["collage"] = collegeVal);

  const getQueryResultCCC = () => {
    setLoading(true);
    let obj = {
      college_id: user && user.college_id,
      query_id: "",
      recruiter_id: user && user.id,
      queries: query,
    };
    localStorage.setItem("query", JSON.stringify(obj));
    history.push("/recruiter/query-result-college-query");
  };

  const createConfigForGender = (conf, operator, value) => {
    if (
      configObject &&
      configObject.some(
        (element) => element && element.config.value === conf.value
      )
    ) {
      configObject.forEach(async (element) => {
        if (element.config.value === conf.value) {
          element.value =
            value && value.value === "male"
              ? "male"
              : value.value === "female"
              ? "female"
              : "unknown";
          await setConfigObject(configObject);
        }
      });
    }
  };

  const removeValue = async (value, config, selectedThirdParameter) => {
    if (value.hasOwnProperty("Selected") && value["Selected"] === true) {
      if (selectedQueries && selectedQueries.length > 0) {
        let removedValueFromFilter = selectedQueries.filter(
          (i) => i.query.name !== value.name
        );
        setSelectedQueries(removedValueFromFilter);
      }
    }
    value["Selected"] = false;
    if (selectedThirdParameter && selectedThirdParameter.length > 0) {
      selectedThirdParameter.forEach((element) => {
        if (element === value) {
          let index = selectedThirdParameter.indexOf(element);
          selectedThirdParameter.splice(index, 1);
          setSelectedThirdParameter(selectedThirdParameter);
        } else if (value.name === element.label && value.id === element.value) {
          let index = selectedThirdParameter.indexOf(element);
          selectedThirdParameter.splice(index, 1);
          setSelectedThirdParameter(selectedThirdParameter);
        }
      });
    }
    configObject &&
      configObject.map(async (element) => {
        if (element.config.value && config.value === "community college") {
          let index = selectedCommunityCollQueries.indexOf(value);
          selectedCommunityCollQueries.splice(index, 1);
          setSelectedCommunityCollQueries(selectedCommunityCollQueries);
        } else if (element.config.value && config.value === "ethnicity") {
          let index = selectedEthnicityQueries.indexOf(value);
          selectedEthnicityQueries.splice(index, 1);
          setSelectedEthnicityQueries(selectedEthnicityQueries);
        } else if (
          element.config.value &&
          config.value === "english/math requirement"
        ) {
          let index = selectedEnglishMathRequirementQueries.findIndex(
            (item) => item.value === value.value
          );
          selectedEnglishMathRequirementQueries.splice(index, 1);
          setSelectedEnglishMathRequirementQueries(
            selectedEnglishMathRequirementQueries
          );
        } else if (element.config.value && config.value === "athletics") {
          let index = selectedAthleticsQueries.findIndex(
            (item) => item.id === value.id
          );
          selectedAthleticsQueries.splice(index, 1);
          setSelectedAthleticsQueries(selectedAthleticsQueries);
        } else if (element.config.value && config.value === "academics") {
          let index = selectedAcademicsQueries.findIndex(
            (item) => item.id === value.id
          );
          selectedAcademicsQueries.splice(index, 1);
          setSelectedAcademicsQueries(selectedAcademicsQueries);
        } else if (element.config.value && config.value === "activities") {
          let index = selectedActivitiesQueries.findIndex(
            (item) => item.id === value.id
          );
          selectedActivitiesQueries.splice(index, 1);
          setSelectedActivitiesQueries(selectedActivitiesQueries);
        } else if (element.config.value && config.value === "interests") {
          let index = selectedInterestsQueries.findIndex(
            (item) => item.id === value.id
          );
          selectedInterestsQueries.splice(index, 1);
          setSelectedInterestsQueries(selectedInterestsQueries);
        } else if (
          element.config.value &&
          config.value === "declared area of study"
        ) {
          let index = selectedAreaofStudyQueries.findIndex(
            (item) => item.id === value.id
          );
          selectedAreaofStudyQueries.splice(index, 1);
          setSelectedInterestsQueries(selectedAreaofStudyQueries);
        }

        if (
          config.value !== "english/math requirement" &&
          config.value === element.config.value &&
          element.multi_value.length > 0
        ) {
          element.multi_value.splice(
            element.multi_value.findIndex(
              (subelement) => subelement.id === value.id
            ),
            1
          );
        }
        if (config.value === "english/math requirement") {
          if (
            config.value === element.config.value &&
            element.multi_value.length > 0
          ) {
            let index = element.multi_value.filter(
              (item) => item.value !== value.value
            );
            element.multi_value = index;
          }
        }
        if (config.value === "community college") {
          if (
            config.value === element.config.value &&
            element.multi_value.length > 0
          ) {
            let index = element.multi_value.filter(
              (item) => item.code !== value.code && item.Selected === true
            );
            element.multi_value = index;
          }
        }

        if (
          element.config.value !== "gender" &&
          element.multi_value.length === 0
        ) {
          let index = configObject.indexOf(element);
          configObject.splice(index, 1);
        }
      });
    await setConfigObject(configObject);
  };

  const handleCheckBox = async (value, operator = "contains", type) => {
    let selected = value;
    if (type === "english/math requirement" && value.hasOwnProperty("label")) {
      let modifiedObjForEnglishMathRequirement = {
        value: value?.value,
        name: value?.label,
      };
      value = modifiedObjForEnglishMathRequirement;
    }
    let originalElement = {};
    let finalValue = {};
    let objectValue = {};
    let multiValue = [];
    let selectedQueryFromView = [];
    if (
      !selected.hasOwnProperty("Selected") ||
      selected["Selected"] === false
    ) {
      objectValue = {
        query: selected,
        type: type,
      };
      selectedQueryFromView.push(objectValue);
      await setSelectedQueries((prevState) => [
        ...prevState,
        ...selectedQueryFromView,
      ]);
    }
    if (selected.hasOwnProperty("Selected") && selected["Selected"] === true) {
      if (selectedQueries && selectedQueries.length > 0) {
        let removedValueFromFilter = selectedQueries.filter(
          (i) => i.query.name !== selected.name
        );
        setSelectedQueries(removedValueFromFilter);
      }
    }
    if (
      type === "interests" ||
      type === "academics" ||
      type === "activities" ||
      type === "athletics"
    ) {
      selected.id = selected.id || selected.value || selected.name;
      selected.name = selected.label || selected.name;
      if (selected.Selected === true) {
        selected.Selected = true;
      }
      originalElement = selected;
      finalValue = { ...selected, originalElement };
    }
    if (type === "community college") {
      let conf =
        config && config.filter((item) => item.value === "community college");
      if (
        !selected.hasOwnProperty("Selected") ||
        selected["Selected"] === false
      ) {
        selected["Selected"] = true;
        multiValue.push(value);
        await setSelectedCommunityCollQueries([
          ...selectedCommunityCollQueries,
          ...multiValue,
        ]);
        createConfig(conf[0], operator, [
          ...selectedCommunityCollQueries,
          ...multiValue,
        ]);
      } else {
        removeValue(value, conf[0], selectedCommunityCollQueries);
      }
    } else if (type === "ethnicity") {
      let conf = config && config.filter((item) => item.value === "ethnicity");
      if (
        !selected.hasOwnProperty("Selected") ||
        selected["Selected"] === false
      ) {
        selected["Selected"] = true;
        multiValue.push(selected);
        setSelectedEthnicityQueries([
          ...selectedEthnicityQueries,
          ...multiValue,
        ]);
        createConfig(conf && conf[0], operator, [
          ...selectedEthnicityQueries,
          ...multiValue,
        ]);
      } else {
        removeValue(selected, conf[0], selectedEthnicityQueries);
      }
    } else if (type === "english/math requirement") {
      let conf =
        config &&
        config.filter((item) => item.value === "english/math requirement");
      if (
        !selected.hasOwnProperty("Selected") ||
        selected["Selected"] === false
      ) {
        selected["Selected"] = true;
        multiValue.push(selected);
        setSelectedEnglishMathRequirementQueries([
          ...selectedEnglishMathRequirementQueries,
          ...multiValue,
        ]);
        createConfig(conf[0], operator, [
          ...selectedEnglishMathRequirementQueries,
          ...multiValue,
        ]);
      } else {
        removeValue(selected, conf[0], selectedEnglishMathRequirementQueries);
      }
    } else if (type === "academics") {
      let conf = config && config.filter((item) => item.value === "academics");
      if (
        !selected.hasOwnProperty("Selected") ||
        selected["Selected"] === false
      ) {
        selected["Selected"] = true;
        multiValue.push(selected);
        setSelectedAcademicsQueries([
          ...selectedAcademicsQueries,
          ...multiValue,
        ]);
        createConfig(conf[0], operator, [
          ...selectedAcademicsQueries,
          ...multiValue,
        ]);
      } else {
        removeValue(selected, conf[0], selectedAcademicsQueries);
      }
    } else if (type === "activities") {
      let conf = config && config.filter((item) => item.value === "activities");
      if (
        !selected.hasOwnProperty("Selected") ||
        selected["Selected"] === false
      ) {
        selected["Selected"] = true;
        multiValue.push(selected);
        //console.log(multiValue);
        setSelectedActivitiesQueries([
          ...selectedActivitiesQueries,
          ...multiValue,
        ]);
        createConfig(conf[0], operator, [
          ...selectedActivitiesQueries,
          ...multiValue,
        ]);
      } else {
        removeValue(selected, conf[0], selectedActivitiesQueries);
      }
    } else if (type === "athletics") {
      let conf = config && config.filter((item) => item.value === "athletics");
      if (
        !selected.hasOwnProperty("Selected") ||
        selected["Selected"] === false
      ) {
        selected["Selected"] = true;
        multiValue.push(selected);
        setSelectedAthleticsQueries([
          ...selectedAthleticsQueries,
          ...multiValue,
        ]);
        createConfig(conf[0], operator, [
          ...selectedAthleticsQueries,
          ...multiValue,
        ]);
      } else {
        removeValue(selected, conf[0], selectedAthleticsQueries);
      }
    } else if (type === "interests") {
      let conf = config && config.filter((item) => item.value === "interests");
      if (
        !selected.hasOwnProperty("Selected") ||
        selected["Selected"] === false
      ) {
        selected["Selected"] = true;
        multiValue.push(selected);
        setSelectedInterestsQueries([
          ...selectedInterestsQueries,
          ...multiValue,
        ]);
        createConfig(conf[0], operator, [
          ...selectedInterestsQueries,
          ...multiValue,
        ]);
      } else {
        removeValue(selected, conf[0], selectedInterestsQueries);
      }
    } else if (type === "declared area of study") {
      let conf =
        config &&
        config.filter((item) => item.value === "declared area of study");
      if (
        !selected.hasOwnProperty("Selected") ||
        selected["Selected"] === false
      ) {
        selected["Selected"] = true;
        selected["name"] = selected.name;
        multiValue.push(selected);
        setSelectedAreaofStudyQueries([
          ...selectedAreaofStudyQueries,
          ...multiValue,
        ]);
        createConfig(conf[0], operator, [
          ...selectedAreaofStudyQueries,
          ...multiValue,
        ]);
      } else {
        removeValue(selected, conf[0], selectedAreaofStudyQueries);
      }
    }
  };

  const onShowMore = async (value) => {
    if (value === "ethnicity") {
      if (value === "ethnicity" && showEthData.length === 3) {
        await setShowEthData(ethnicity);
      } else {
        await setShowEthData(showEthData.slice(0, 3));
      }
    }
    // if (value === "community college") {
    //   if (value === "community college" && showCollageData.length === 3) {
    //     setCollageData(collageData);
    //   } else {
    //     setCollageData(collageData.slice(0, 4));
    //   }
    // }
    if (value === "english/math requirement") {
      if (value === "english/math requirement") {
        setEnglishMathRequirement(englishMathRequirement);
      }
    }
    if (value === "academics") {
      if (value === "academics") {
        setShowAcademicsData(academicsData);
      }
    }
    if (value === "activities") {
      if (value === "activities") {
        setShowActivitiesData(activitiesData);
      }
    }
    if (value === "athletics") {
      if (value === "athletics" && showAthleticsData.length === 3) {
        setShowAthleticsData(athleticsData);
      } else {
        setShowAthleticsData(athleticsData.slice(0, 3));
      }
    }
    if (value === "interests") {
      if (value === "interests" && showInterestData.length === 3) {
        setShowInterestData(interestData);
      } else {
        setShowInterestData(interestData.slice(0, 3));
      }
    }
    if (value === "declared area of study") {
      if (value === "declared area of study" && showDasData.length === 3) {
        setShowDasData(dasData);
      } else {
        setShowDasData(dasData.slice(0, 3));
      }
    }
  };

  const getcccData = () => {
    setLoading(true);
    QueryService.getCCC()
      .then((data) => {
        setLoading(false);

        let AllEthnicity = data?.data?.ccc_view?.ethnicity;
        let AllActivities = data?.data?.ccc_view?.activities;
        let AllAcademics = data?.data?.ccc_view?.academics;
        let AllInterest = data?.data?.ccc_view?.intrest;
        let AllAthletics = data?.data?.ccc_view?.athletics;
        let AllGoals = data?.data?.ccc_view?.goals;
        // let AllGender = data?.data?.ccc_view?.gender;
        // let AllColleges = data?.data?.ccc_view?.college;

        //console.log("Alldata", AllAcademics, AllActivities, AllGoals)

        setEthnicityData(AllEthnicity);
        setShowEthData(AllEthnicity?.slice(0, 3));
        setInterestData(AllInterest);
        setShowInterestData(AllInterest.slice(0, 3));
        setAthleticsData(AllAthletics);
        setShowAthleticsData(AllAthletics.slice(0, 3));
        setDasData(AllGoals);
        setShowDasData(AllGoals.slice(0, 3));
        setActivitiesData(AllActivities);
        setShowActivitiesData(AllActivities);
        setAcademicsData(AllAcademics);
        setShowAcademicsData(AllAcademics);
        // setCollageData(AllColleges);
      })
      .catch((error) => {
        setLoading(false);
        //console.log("error from the service", error);
      });
  };

  useEffect(() => {
    getcccData();
  }, []);


  useEffect(() => {
    async function getSelectedQueries() {
      if (genderValue) {
        let query = {};
        query["type"] = "gender";
        query["name"] = genderValue && genderValue.name;
        let obj = {};
        obj.type = "gender";
        obj.query = query;
        await setSelectedQueries([...selectedQueries, obj]);
      }
    }

    getSelectedQueries();
  }, [genderValue]);


  const removeValueForCheckBox = async (item) => {
    setGenderValue();
    let filteredValue = selectedQueries && selectedQueries.indexOf(item);
    selectedQueries.splice(filteredValue, 1);
    setSelectedQueries(selectedQueries);
    configObject &&
      configObject.forEach((element) => {
        if (
          element.value === "male" ||
          element.value === "female" ||
          element.value === "unknown"
        ) {
          let index = configObject.indexOf(element);
          configObject.splice(index, 1);
        }
      });
    await setConfigObject(configObject);
  };

  const handleGenderCheckbox = async (value, operator = "contains") => {
    let conf = config && config.filter((item) => item.value === "gender");
    let filteredValue =
      selectedQueries.length > 0
        ? selectedQueries.filter((item) => {
            if (item.query.name === "Male") {
              let i = selectedQueries.indexOf(item);
              selectedQueries.splice(i, 1);
            } else if (item.query.name === "Female") {
              let i = selectedQueries.indexOf(item);
              selectedQueries.splice(i, 1);
            } else if (item.query.name === "Non-Binary") {
              let i = selectedQueries.indexOf(item);
              selectedQueries.splice(i, 1);
            }
          })
        : [];
    setSelectedQueries([...selectedQueries, ...filteredValue]);
    if (value.value === "male") {
      setGenderValue(value);
      createConfigForGender(conf[0], operator, value);
    } else if (value.value === "female") {
      await setGenderValue(value);
      createConfigForGender(conf[0], operator, value);
    } else {
      await setGenderValue(value);
      createConfigForGender(conf[0], operator, value);
    }
  };

  const handleGPASlider = async (value) => {
    let min = value[0];
    await setMinGPA(min);
    let max = value[1];
    await setMaxGPA(max);
  };

  useEffect(() => {
    if (minGPA !== 0 || maxGPA !== 4) {
      createConfigForGPA();
    }
  }, [minGPA, maxGPA]);

  const createConfigForGPA = () => {
    let obj = {
      min: minGPA,
      max: maxGPA,
    };
  };

  const selectTab = (value) => {
    if (value === "customQuery") {
      setSelectedTab(value);
      history.push("/recruiter/search-query");
    } else if (value === "schoolQuery") {
      localStorage.removeItem("query");
      setSelectedTab(value);
      history.push("/recruiter/school-query");
    } else if (value === "savedQuery") {
      localStorage.removeItem("query");
      setSelectedTab(value);
      history.push("/recruiter/saved-query");
    } else if (value === "collegeQuery") {
      localStorage.removeItem("query");
      setSelectedTab(value);
      history.push("/recruiter/college-query");
    }
  };

  // const saveQuery = () => {
  //   setOpenSaveQueryModal(true);
  // };

  const clear = () => {
    setConfigObject([]);
    setSelectedQueries([]);
    setGenderValue();
  };
  return (
    <Fragment>
      <AppHeader />
      <div className="app-main">
        <AppSidebar />
        <div className="app-main__outer">
          {" "}
          <div>
            <div className="name_message_header">
              <p>
                Hello {user ? `${user?.first_name} ${user?.last_name}!!` : ""}
              </p>
            </div>
            <div className="query-component-container scrollBar_hidden">
              <div className="tab-grid">
                <div
                  onClick={() => selectTab("customQuery")}
                  style={{ width: "100%" }}
                  className={"tab-button cursor inactive-tab"}
                >
                  <p className="no-margin-bottom">H/S Student Query</p>
                </div>
                <div
                  onClick={() => selectTab("schoolQuery")}
                  style={{ width: "100%" }}
                  className={"tab-button cursor inactive-tab"}
                >
                  <p className="no-margin-bottom">Saved Queries</p>
                </div>
                <div
                  onClick={() => selectTab("collegeQuery")}
                  style={{ width: "100%" }}
                  className={"tab-button cursor active-tab"}
                >
                  <p className="no-margin-bottom">C/C Student Query</p>
                </div>
              </div>
              {loading ? (
                <CustomLoader />
              ) : (
                <div
                  className="scrollBar_hidden"
                  style={{
                    border: "2px solid var(--message-time-color)",
                    height: "100%",
                    background: "white",
                    marginLeft: "2px",
                    padding: "0px 20px",
                  }}
                >
                  <div className="top-border" />
                  <div className="display-selected-query">
                    <p className="no-margin-bottom bold">SET QUERY CRITERIA</p>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: "5px",
                      }}
                    >
                      <p className="no-margin-bottom">Choosen Filter:</p>
                      <div onClick={() => clear()} className="clear-button">
                        Clear All
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          margin: "0px 5px",
                        }}
                      >
                        {selectedQueries &&
                          selectedQueries.map((item) => {
                            return (
                              <div
                                className="flex"
                                style={{
                                  margin: "0 15px",
                                  position: "relative",
                                }}
                              >
                                {item &&
                                  item.query &&
                                  (Array.isArray(item.query)
                                    ? item.query.length > 0
                                    : item.query) && (
                                    <div
                                      className="cursor"
                                      onClick={
                                        item.query.type === "gender"
                                          ? () => removeValueForCheckBox(item)
                                          : () =>
                                              handleCheckBox(
                                                item.query,
                                                "",
                                                item.type
                                              )
                                      }
                                    >
                                      <CloseOutlined className="cancel-icon" />
                                    </div>
                                  )}
                                <p
                                  style={{
                                    padding: "0 5px 0 10px",
                                    background:
                                      "var(--selected-primary-button-color)",
                                    color: "var(--input-label-color)",
                                    borderTopRightRadius: "15px",
                                    borderBottomRightRadius: "15px",
                                  }}
                                  className="no-margin-bottom"
                                >
                                  {item.query.name}
                                </p>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                  <div className="bottom-border" />
                  <div style={{ margin: "15px 0px" }}>
                    <p
                      style={{
                        fontWeight: "bold",
                        fontSize: "14px",
                        color: "#333333",
                      }}
                    >
                      Set Criteria of Student Search
                    </p>
                    {/* <SingleQuery
                      loading={loading}
                      data={collageData}
                      name="community college"
                      valueWhichExpand={valueWhichExpand}
                      handleCheckBox={(value, type) =>
                        handleCheckBox(value, "contains", type)
                      }
                      selectedQueries={selectedQueries && selectedQueries}
                    /> */}

                    <DropDown onSelect={onSelect} />

                    <div
                      style={{ marginTop: "25px" }}
                      className="col-md-12 row"
                    >
                      <div className="col-md-6">
                        <div className="query-title-containers ">
                          <p style={{ marginBottom: "0px" }} className="bold">
                            Gender
                          </p>
                        </div>
                        <div className="gender-value-container">
                          {genderData &&
                            genderData.map((item, index) => {
                              return (
                                <div key={index} className="flex">
                                  <input
                                    type="checkbox"
                                    name={item.value}
                                    onChange={() => handleGenderCheckbox(item)}
                                    value={genderValue && genderValue.value}
                                    checked={
                                      genderValue &&
                                      genderValue.value === item.value
                                    }
                                  />
                                  <p
                                    style={{
                                      marginLeft: "10px",
                                      fontSize: "14px",
                                    }}
                                    className="no-margin-bottom"
                                  >
                                    {item.name}
                                  </p>
                                </div>
                              );
                            })}
                        </div>
                      </div>

                      <div className="col-md-6">
                        <SingleQuery
                          loading={loading}
                          data={showEthData}
                          name="ethnicity"
                          valueWhichExpand={valueWhichExpand}
                          onShowMore={() => onShowMore("ethnicity")}
                          handleCheckBox={(value, type) =>
                            handleCheckBox(value, "contains", type)
                          }
                          selectedQueries={selectedQueries && selectedQueries}
                        />
                      </div>
                    </div>
                    <div>
                      <GPASlider
                        handleGPASlider={handleGPASlider}
                        minGPA={minGPA}
                        maxGPA={maxGPA}
                      />
                    </div>
                    <div
                      style={{ marginTop: "25px" }}
                      className="col-md-12 row"
                    >
                      <div className="col-md-4">
                        <SingleQuery
                          data={englishMathRequirement}
                          name="english/math requirement"
                          valueWhichExpand={valueWhichExpand}
                          handleCheckBox={(value, type) =>
                            handleCheckBox(value, "contains", type)
                          }
                          selectedQueries={selectedQueries && selectedQueries}
                        />
                      </div>
                      <div className="col-md-4">
                        <SingleQuery
                          loading={loading}
                          data={showAcademicsData}
                          name="academics"
                          valueWhichExpand={valueWhichExpand}
                          onShowMore={() => onShowMore("academics")}
                          handleCheckBox={(value, type) =>
                            handleCheckBox(value, "contains", type)
                          }
                          selectedQueries={selectedQueries && selectedQueries}
                        />
                      </div>
                      <div className="col-md-4">
                        <SingleQuery
                          loading={loading}
                          data={showActivitiesData}
                          name="activities"
                          valueWhichExpand={valueWhichExpand}
                          onShowMore={() => onShowMore("activities")}
                          handleCheckBox={(value, type) =>
                            handleCheckBox(value, "contains", type)
                          }
                          selectedQueries={selectedQueries && selectedQueries}
                        />
                      </div>
                    </div>
                    <div
                      style={{ marginTop: "25px" }}
                      className="col-md-12 row"
                    >
                      <div className="col-md-4">
                        <SingleQuery
                          loading={loading}
                          data={showAthleticsData}
                          name="athletics"
                          valueWhichExpand={valueWhichExpand}
                          onShowMore={() => onShowMore("athletics")}
                          handleCheckBox={(value, type) =>
                            handleCheckBox(value, "contains", type)
                          }
                          selectedQueries={selectedQueries && selectedQueries}
                        />
                      </div>
                      <div className="col-md-4">
                        <SingleQuery
                          loading={loading}
                          data={showInterestData}
                          name="interests"
                          valueWhichExpand={valueWhichExpand}
                          onShowMore={() => onShowMore("interests")}
                          handleCheckBox={(value, type) =>
                            handleCheckBox(value, "contains", type)
                          }
                          selectedQueries={selectedQueries && selectedQueries}
                        />
                      </div>
                      <div className="col-md-4">
                        <SingleQuery
                          loading={loading}
                          data={showDasData}
                          name="declared area of study"
                          valueWhichExpand={valueWhichExpand}
                          handleCheckBox={(value, type) =>
                            handleCheckBox(value, "contains", type)
                          }
                          onShowMore={() =>
                            onShowMore("declared area of study")
                          }
                          selectedQueries={selectedQueries && selectedQueries}
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        borderBottom: "3px solid black",
                        marginTop: "15px",
                      }}
                    />
                    <div style={{ margin: "15px 0" }} className="col-md-12 row">
                      <div className="col-md-4">
                        <button
                          onClick={() => getQueryResultCCC()}
                          className="query-button font bold"
                        >
                          RUN QUERY
                        </button>
                      </div>
                      {/* <div className="col-md-4">
                    <button
                      onClick={() => saveQuery()}
                      className="save-query-button font "
                    >
                      SAVE QUERY
                    </button>
                  </div> */}
                      <div className="col-md-4">
                        <button
                          onClick={() => resetQueryCriteria()}
                          className="save-query-button font "
                        >
                          RESET QUERY CRITERIA
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          {/* {openSaveQueryModal && (
        <SaveModalCCC
          openSaveModal={openSaveQueryModal}
          setOpenSaveModal={setOpenSaveQueryModal}
          from="query-page"
          saveForCollegeQuery={query}
          saveForSiembra={saveForSiembra}
          inputValueForSave={inputValueForSave}
          setInputValueForSave={setInputValueForSave}
        />
      )} */}
          {openConfirmationForSiembra && (
            <ConfirmationDialog
              isOpen={openConfirmationForSiembra}
              setIsOpen={setOpenConfirmationForSiembra}
              query={configObject && configObject}
              inputValue={inputValueForSave}
              openErrorModal={openExistingErrorModal}
              setQuery={setConfigObject}
              setOpenErrorModal={setOpenExistingErrorModal}
              setInputValue={setInputValueForSave}
            />
          )}
          {openExistingErrorModal && (
            <ErrorModal
              openErrorModal={openExistingErrorModal}
              setOpenErrorModal={setOpenExistingErrorModal}
            />
          )}
        </div>
      </div>
    </Fragment>
  );
};
export default CollegeQuery;
