import React, { Fragment, useState, useEffect, useRef } from "react";
import { TrendingUpRounded } from "@mui/icons-material";
import Loader from "react-loader-spinner";
import CustomLoader from "../../customLoader/CustomLoader";
import GetAdminService from "../../../services/admin/GetAdminService";
import GetStudentService from "../../../services/SchoolIT/GetStudentService";
import AppHeader from "../../../Layout/AppHeader";
import AppSidebar from "../../../Layout/AppSidebar";
import StudentImportConfirmation from "../../counselor/studentImportConfirmationModal/StudentImportConfirmationModal";
import Storage from "../../../services/Storage";
const API_URL = process.env.REACT_APP_API_URL;

const Import = () => {
  const [loadingForCsvDownload, setLoadingForCsvDownload] = useState(false);
  const [dataForDownload, setDataForDownload] = useState([]);
  const [
    loadingForStudentCsvDownload,
    setLoadingForStudentCsvDownload,
  ] = useState(false);
  const [schoolName, setSchoolName] = useState();
  const [showSchoolNameContainer, setShowSchoolNameContainer] = useState(false);
  const [schoolNameList, setSchoolNameList] = useState();
  const schoolNameRef = useRef();
  const timeoutRef = useRef();
  const [showDownload, setShowDownload] = useState(false);
  const [schoolNameLoading, setSchoolNameLoading] = useState(false);
  const [apiCallOrNot, setApiCallOrNot] = useState();
  const [selectedFile, setSelectedFile] = useState();
  const selectedFileRef = useRef();
  const [showModal, setShowModal] = useState(false);
  const [uploadCSVData, setUploadCSVData] = useState();

  const getSchoolsList = (value) => {
    GetAdminService.getSchoolsList(value)
      .then((data) => {
        //console.log("data from the service", data);
        setSchoolNameList(data?.data?.school);
        setSchoolNameLoading(false);
      })
      .catch((error) => {
        //console.log("error from the service", error);
        setSchoolNameLoading(false);
      });
  };

  const downLoadCsvTemplate = () => {
    setLoadingForCsvDownload(TrendingUpRounded);
    let template = [];
    template.push([
      '"Student First Name"',
      '"Student Middle Name"',
      '"Student Last Name"',
      '"Student Grade"',
      '"Student ID"',
      '"Student Email"',
      '"Student Mobile"',
      '"Student DOB"',
      '"Counselor Emails"',
    ]);
    let arr = template;
    let ret = [];
    for (let i = 0, len = arr.length; i < len; i++) {
      let line = [];
      for (let key in arr[i]) {
        if (arr[i].hasOwnProperty(key)) {
          line.push(arr[i][key]);
        }
      }
      ret.push(line.join(","));
    }
    let result_csv = ret.join("\n");
    let saving = document.createElement("a");
    saving.href = "data:attachment/csv," + encodeURIComponent(result_csv);
    saving.download = "csv_admin_import_template.csv";
    document.body.appendChild(saving);
    saving.click();
    setLoadingForCsvDownload(false);
  };

  const downloadFile = (downloadURL, fileName) => {
    const downloadLink = document.createElement('a');
    document.body.appendChild(downloadLink);
    downloadLink.download = fileName;
    downloadLink.href = downloadURL;
    downloadLink.click();
}

  const getDataForCSVDownload = () => {
    setLoadingForStudentCsvDownload(true);
    GetStudentService.getStudentCountForCSVImport(
      schoolNameList[0].id
    )
      .then((data) => {
        // //console.log(data);
        downloadFile(data.data.download);
        setLoadingForStudentCsvDownload(false);
        // if (data.data.meta.next !== null) {
        //   data.data.objects.map((item) => {
        //     let obj = {
        //       ...item.student,
        //       ...{ counselor_email: item?.counselor?.email },
        //     };
        //     finalObj.push(obj);
        //   });
        //   setDataForDownload(finalObj);
        // } else if (data.data.meta.next === null) {
        //   data.data.objects.map((item) => {
        //     let obj = {
        //       ...item.student,
        //       ...{ counselor_email: item?.counselor?.email },
        //     };
        //     finalObj.push(obj);
        //   });
        //   let arr = [...dataForDownload, ...finalObj];
        //   setDataForDownload(arr);
        //   let result_csv = getHeaderRow();
        //   addStudentsToCsv(result_csv, arr);
        //   setLoadingForStudentCsvDownload(false);
        // }
      })
      .catch((error) => {
        setLoadingForStudentCsvDownload(false);
        //console.log("error from the service", error);
      });
  };

  useEffect(() => {
    if (dataForDownload.length > 0) {
      getDataForCSVDownload();
    }
  }, [dataForDownload && dataForDownload.length > 0]);

  // useEffect(() => {
  //   if (dataForDownload.length > 0) {
  //     let result_csv = getHeaderRow();
  //     addStudentsToCsv(result_csv, dataForDownload);
  //   }
  // }, [dataForDownload?.length > 0 && downloadCSVStudent === true]);

  function getHeaderRow() {
    var template = [];
    template.push([
      '"Student First Name"',
      '"Student Middle Name"',
      '"Student Last Name"',
      '"Student Grade"',
      '"Student ID"',
      '"Student Email"',
      '"Student Mobile"',
      '"Student DOB"',
      '"Counselor Emails"',
    ]);
    var arr = template;
    var ret = [];
    for (var i = 0, len = arr.length; i < len; i++) {
      var line = [];
      for (var key in arr[i]) {
        if (arr[i].hasOwnProperty(key)) {
          line.push(arr[i][key]);
        }
      }
      ret.push(line.join(","));
    }
    return ret;
  }

  function addStudentsToCsv(csv, students) {
    var studentRecord = [];
    for (var i = 0; i < students.length; i++) {
      studentRecord[0] = students[i].first_name;
      studentRecord[1] = students[i].middle_name;
      studentRecord[2] = students[i].last_name;
      studentRecord[3] = students[i].current_grade;
      studentRecord[4] = students[i].student_id;
      studentRecord[5] = students[i].email;
      studentRecord[6] = students[i].mobile;
      studentRecord[7] = students[i].student_dob;
      studentRecord[8] = students[i].counselor_email;
      var newRow = studentRecord.join(",");
      csv.push(newRow);
    }
    var csvDownload = csv.join("\n");
    var saving = document.createElement("a");
    saving.href = "data:attachment/csv," + encodeURIComponent(csvDownload);
    saving.download = "csv_student_download.csv";
    document.body.appendChild(saving);
    saving.click();
  }

  // const uploadFileHandler = () => {
  //   let file = selectedFileRef.current.files[0];
  //   //console.log("file from the upload file handler", file);
  //   setSelectedFile(file);
  // };

  // const uploadFile = () => {
  //   let formData = new FormData();
  //   formData.append("school", schoolNameList[0]["id"]);
  //   formData.append("mode", "validation");
  //   formData.append("csv_file", selectedFile);
  //   let token = Storage.getTokenInCookie() ? Storage.getTokenInCookie() : "";
  //   fetch(`${API_URL}/api/v1/admin/upload-csv`, {
  //     method: "POST",
  //     headers: {
  //       "x-authorization": `Bearer ${token}`,
  //     },
  //     body: formData,
  //   })
  //     .then((response) => response.json())
  //     .then((data) => {
  //       //console.log("data from the upload CSV File", data);
  //       setUploadCSVData(data);
  //       if (data?.error_csv?.length > 0) {
  //         setShowModal(true);
  //       }
  //     })
  //     .catch((error) => {
  //       //console.log("eror from the service", error);
  //     });
  // };

  // useEffect(() => {
  //   if (selectedFileRef && selectedFile) {
  //     uploadFile();
  //   }
  // }, [selectedFile]);

  const handleSchoolName = () => {
    clearTimeout(timeoutRef.current);
    setSchoolName(schoolNameRef.current.value);
    if (!schoolNameRef.current.value) {
      setShowSchoolNameContainer(false);
      setSchoolNameList([]);
    } else {
      timeoutRef.current = setTimeout(() => {
        getSchoolsList(schoolNameRef.current.value);
      }, 1000);
      setShowSchoolNameContainer(true);
      setSchoolNameLoading(true);
    }
  };

  const handleSelectedSchoolName = (item) => {
    setSchoolNameList([item]);
    setShowSchoolNameContainer(false);
    setSchoolName(item.name);
    setShowDownload(true);
  };
  return (
    <Fragment>
      <AppHeader />
      <div className="app-main">
        <AppSidebar />
        <div className="app-main__outer">
          <div className="csv-import-container">
            <div style={{ justifyContent: "space-between" }} className="flex">
              <p>
                <span style={{ fontSize: "36px", fontWeight: "400" }}>
                  Export
                </span>
                <span
                  style={{
                    color: "#777777",
                    fontSize: "24px",
                    marginLeft: "10px",
                  }}
                >
                  Students Data
                </span>
              </p>
              {/* <button
                onClick={downLoadCsvTemplate}
                className="import-csv-button"
              >
                Download CSV Template File
                {loadingForCsvDownload && (
                  <span>
                    <Loader
                      type="TailSpin"
                      color="white"
                      height={20}
                      width={20}
                    />
                  </span>
                )}
              </button> */}
            </div>
            {/* <div
              style={{
                border: "1px solid rgb(204,204,204)",
                borderLeft: "6px solid rgb(204,204,204)",
                padding: "0 15px",
              }}
            >
              <p
                style={{
                  color: "rgb(58,58,58)",
                  fontSize: "14px",
                  padding: "10px",
                  marginBottom: "0px",
                }}
              >
                To import students, first download the CSV template and add any
                student information available.The student last name and student
                first name are required fields. You also must include the email
                address, a student ID or both an email address and student ID to
                uniquely identify the student. Once the CSV file has been
                created, upload the file.
              </p>
            </div> */}
            <div
              style={{
                borderTop: "1px solid #d1d1d1",
                marginTop: "10px",
              }}
            >
              <div className="school-name-container">
                <p className="school-name-container-heading">Select School</p>
                <div>
                  <input
                    ref={schoolNameRef}
                    className="search-student-input"
                    type="text"
                    style={{
                      width: "500px",
                      height: "35px",
                      padding: "5px 10px",
                      marginBottom: "10px",
                    }}
                    placeholder="School Name"
                    value={schoolName}
                    onChange={handleSchoolName}
                    autoComplete="off"
                  />
                  {showSchoolNameContainer && (
                    <div>
                      <ul
                        className="maintain-group-student-dropdown-menu"
                        style={{
                          width: "700px",
                          maxHeight: "600px",
                          overflowY: "scroll",
                          borderRadius: "5px",
                          padding: "10px",
                        }}
                      >
                        {schoolNameLoading && <CustomLoader />}
                        {schoolNameList?.length > 0 &&
                          schoolNameList.map((item, index) => (
                            <li
                              onClick={() => handleSelectedSchoolName(item)}
                              key={index}
                              className="flex maintain-group-student-list cursor"
                            >
                              <span>{item.name}</span>
                            </li>
                          ))}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {showDownload && (
              <div>
                <div style={{ padding: "5px 15px 15px 15px" }}>
                  <p className="school-name-container-heading">
                    Download Students
                  </p>
                  <div>
                    <button
                      onClick={() => getDataForCSVDownload()}
                      style={{ display: "flex" }}
                      className="download-report-button btn-secondary"
                    >
                      Download Student
                      {loadingForStudentCsvDownload && (
                        <span>
                          <Loader
                            type="TailSpin"
                            color="white"
                            height={15}
                            width={15}
                          />
                        </span>
                      )}
                    </button>
                  </div>
                </div>
                {/* <div className="school-name-container">
                  <p className="school-name-container-heading">Select File</p>
                  <div>
                    <input
                      type="file"
                      accept="text/csv"
                      onChange={uploadFileHandler}
                      ref={selectedFileRef}
                    /> 
                  </div>
                </div>*/}
              </div>
            )}
          </div>
          {showModal && (
            <StudentImportConfirmation
              student={uploadCSVData}
              closeHandler={() => {
                setShowModal(false);
                selectedFileRef.current.value = null;
              }}
              isOpen={showModal}
              school_it_ok={true}
            />
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default Import;
